
import React from 'react';
import { msalAuth } from '../msal/MsalAuthProvider';
import { msalConfig } from '../msal/MsalConfig';
import { systemConfig } from './SystemConfig';
import 'regenerator-runtime/runtime';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { useParams, useNavigate } from 'react-router-dom';
import * as moment from 'moment';
import SessionHelper from '../msal/Session'
import InactivitySession from '../msal/InactivitySession';
import { flushSync } from 'react-dom'; // Note: react-dom, not react

export function formatComponentPageName(pageName) {
    if (pageName) {
        document.title = (systemConfig.systemName + ' - ' + pageName);
    }
    else if (!document.title.includes(systemConfig.systemName + ' ')) {
        document.title = (systemConfig.systemName + ' ');
    }
}

export function isAccessReadOnly() {
    console.log('got here');
    return msalAuth.getActiveAccount().idTokenClaims.roles.some(role => role.includes('ReadOnly'));
    //For Testing
    //var roles = ['Payroll', 'TransportCoordinator'];
    //return roles.some(role => role.includes('ReadOnly'));
}

export function onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');
    exportDataGrid({
        component: e.component,
        worksheet: worksheet
    }).then(function () {
        workbook.xlsx.writeBuffer()
            .then(function (buffer) {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), (e.fileName + '.xlsx'));
            });
    });
}

export function onExportingFileName(e, fileName) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');
    exportDataGrid({
        component: e.component,
        worksheet: worksheet
    }).then(function () {
        workbook.xlsx.writeBuffer()
            .then(function (buffer) {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), (fileName + '.xlsx'));
            });
    });
}

export function withParams(Component) {
    return props => <Component {...props} params={useParams()} navigate={useNavigate()} />;
}

export var FetchAPIPromise = (apiName, parameters) => {
    var requestScope = msalConfig.auth.clientId + '/.default';

    const accessTokenRequest = {
        scopes: [requestScope]
    }

    return msalAuth.acquireTokenSilent(accessTokenRequest)
        .then((token) => {
            {
                if (SessionHelper.isTokenExpiredOrNull) {
                    SessionHelperLogout();
                    return;
                }
                else {
                    return fetch('API/' + apiName + (parameters ? '?' + parameters : ''), {
                        headers: !token ? {} : { 'Authorization': `Bearer ${token.accessToken}` },
                        redirect: 'error'
                    })
                        .then(response => response.json())
                        // For Debug Only
                        //.then(json => { console.log(json); return json; })
                        .catch(() => { throw 'Network error' });
                }
            }
        });
}

function SessionHelperLogout() {
    //console.log('expired or null');
    // clear the session helper
    SessionHelper.removeExpiry();
    SessionHelper.stopExpiryTimeout();

    InactivitySession.removeExpiry();
    InactivitySession.stopExpiryTimeout();

    const accounts = msalAuth.getAllAccounts();
    if (accounts.length > 0) {
        //console.log('Accounts present');
        //console.log(accounts[0]);
        //console.log(accounts[0].idTokenClaims.login_hint);
        //console.log(accounts[0].idTokenClaims.preferred_username);
        // log out user
        // TODO: Use v2 logout redirect
        //msalAuth.logoutRedirect(
        //    { extraQueryParameters: { logout_hint: accounts[0].idTokenClaims.login_hint } }
        //);
        msalAuth.logoutRedirect({
            logoutHint: accounts[0].idTokenClaims.login_hint,
            postLogoutRedirectUri: msalConfig.auth.redirectUri
        });
        return;
    }
    else {
        // TODO: Use v2 logout redirect
        msalAuth.logoutRedirect();
        return;
    }
}

export var PostAPIPromise = (apiName, objectToPost) => {
    var requestScope = msalConfig.auth.clientId + '/.default';

    const accessTokenRequest = {
        scopes: [requestScope]
    }

    Date.prototype.toISOString = function () {
        var pad = function (num) {
            var norm = Math.floor(Math.abs(num));
            return (norm < 10 ? '0' : '') + norm;
        };

        return this.getFullYear() +
            '-' + pad(this.getMonth() + 1) +
            '-' + pad(this.getDate()) +
            'T' + pad(this.getHours()) +
            ':' + pad(this.getMinutes());
    };

    return msalAuth.acquireTokenSilent(accessTokenRequest)
        .then((token) => {
            {
                if (SessionHelper.isTokenExpiredOrNull) {
                    SessionHelperLogout();
                    return;
                }
                else {
                    return fetch('API/' + apiName, {
                        method: 'POST',
                        credentials: 'include',
                        body: JSON.stringify(objectToPost),
                        headers: {
                            'Content-Type': 'application/json; charset=utf-8',
                            'Authorization': `Bearer ${token.accessToken}`
                        },
                        redirect: 'error'
                    })
                        .then(response => response.json())
                        .then((result) => {
                            // console.log(result);
                            if (result.status == -1) {
                                // ABG I don't know if we always want this
                                //alert('Failed. Please try again later.');
                            }

                            return result;
                        })
                        .catch(() => { throw 'Network error' });
                }
            }
        });
}

export var PutAPIPromise = (apiName, objectToPost) => {
    var requestScope = msalConfig.auth.clientId + '/.default';

    const accessTokenRequest = {
        scopes: [requestScope]
    }

    return msalAuth.acquireTokenSilent(accessTokenRequest)
        .then((token) => {
            {
                if (SessionHelper.isTokenExpiredOrNull) {
                    SessionHelperLogout();
                    return;
                }
                else {
                    return fetch('API/' + apiName, {
                        method: 'PUT',
                        credentials: 'include',
                        body: JSON.stringify(objectToPost),
                        headers: {
                            'Content-Type': 'application/json; charset=utf-8',
                            'Authorization': `Bearer ${token.accessToken}`
                        },
                        redirect: 'error'
                    })
                        .then(response => response.json())
                        .then((result) => {
                            //if (result.status == -1) {
                            //    alert('Failed. Please try again later.');
                            //}

                            return result;
                        })
                        .catch(() => { throw 'Network error' });
                }
            }
        });
}

export var DeleteAPIPromise = (apiName, parameters) => {
    var requestScope = msalConfig.auth.clientId + '/.default';

    const accessTokenRequest = {
        scopes: [requestScope]
    }

    return msalAuth.acquireTokenSilent(accessTokenRequest)
        .then((token) => {
            {
                if (SessionHelper.isTokenExpiredOrNull) {
                    SessionHelperLogout();
                    return;
                }
                else {
                    return fetch('API/' + apiName + (parameters ? '?' + parameters : ''), {
                        headers: !token ? {} : { 'Authorization': `Bearer ${token.accessToken}` },
                        redirect: 'error',
                        method: 'DELETE'
                    })
                        .then(response => response.json())
                        .then((result) => {
                            //if (result.status == -1) {
                            //    alert('Failed. Please try again later.');
                            //}

                            // console.log('Delete API result', result);

                            return result;
                        })
                        .catch(() => { throw 'Network error' });
                }
            }
        });
}

export default class BaseComponent extends React.Component {
    constructor(props) {
        super(props);
    }

    componentWillUnmount() {
        //console.log('unmounting');
        window.removeEventListener("click", this.handleMouseClick);
    }

    customMoment = (date) => {
        return moment(date, 'MM/DD/YYYY HH:mm');
    }

    handleMouseClick(event) {
        //console.log('Mouse event');
        //   if (...guard expression...) {
        // ... do something
        //}
        // reset the expiry
        SessionHelper.setExpiry();

        // and reset the timeout function
        SessionHelper.resetExpiryTimeout();

        InactivitySession.setExpiry();

        InactivitySession.resetExpiryTimeout();
    }

    componentDidMount() {
        //console.log('mounting');
        window.addEventListener("click", this.handleMouseClick);

        this.fetchData();
    }

    fetchData = async () => {
        //console.log('Fetching data');

        this.setState({
            loading: true
        });

        //console.log('Getting drop down data');
        await this.GetDropDownData();

        await this.GetData();

        this.setState({
            loading: false
        });

        //console.log(this.GetComponentPageName());

        if (this.GetComponentPageName()) {
            document.title = (this.systemConfig.systemName + ' - ' + this.GetComponentPageName());
        }
        else if (!document.title.includes(this.systemConfig.systemName + ' ')) {
            document.title = (this.systemConfig.systemName + ' ');
        }
    }

    GetComponentPageName = () => {
        return "";
    }

    GetDropDownData = async () => {
    }

    GetData = async () => {
    }

    IsReadOnly() {
        return msalAuth.getActiveAccount().idTokenClaims.roles.some(role => role.includes('ReadOnly'));
        //For Testing
        //var roles = ['Payroll', 'TransportCoordinator'];
        //return roles.some(role => role.includes('ReadOnly'));
    }

    FetchAPI = async (apiName) => {
        var requestScope = msalConfig.auth.clientId + '/.default';

        const accessTokenRequest = {
            scopes: [requestScope]
        }

        var token = await msalAuth.acquireTokenSilent(accessTokenRequest);

        //console.log("Token is: ");
        //console.log(token.accessToken);

        const response = await fetch('API/' + apiName, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token.accessToken}` },
            redirect: 'error'
        });

        //console.log(response);

        return await response.json();
    }


    PostAPI = async (apiName, objectToPost) => {

        //console.log(JSON.stringify(objectToPost));

        var requestScope = msalConfig.auth.clientId + '/.default';

        Date.prototype.toISOString = function () {
            var pad = function (num) {
                var norm = Math.floor(Math.abs(num));
                return (norm < 10 ? '0' : '') + norm;
            };

            return this.getFullYear() +
                '-' + pad(this.getMonth() + 1) +
                '-' + pad(this.getDate()) +
                'T' + pad(this.getHours()) +
                ':' + pad(this.getMinutes());
        };

        const accessTokenRequest = {
            scopes: [requestScope]
        }

        var token = await msalAuth.acquireTokenSilent(accessTokenRequest);

        var response = await fetch('API/' + apiName, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(objectToPost),
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': `Bearer ${token.accessToken}`
            },
            redirect: 'error'
        });

        //console.log(response);

        return await response.json();
    }

    PutAPI = async (apiName, objectToPost) => {

        //console.log(JSON.stringify(objectToPost));

        var requestScope = msalConfig.auth.clientId + '/.default';

        Date.prototype.toISOString = function () {
            var pad = function (num) {
                var norm = Math.floor(Math.abs(num));
                return (norm < 10 ? '0' : '') + norm;
            };

            return this.getFullYear() +
                '-' + pad(this.getMonth() + 1) +
                '-' + pad(this.getDate()) +
                'T' + pad(this.getHours()) +
                ':' + pad(this.getMinutes());
        };

        const accessTokenRequest = {
            scopes: [requestScope]
        }

        var token = await msalAuth.acquireTokenSilent(accessTokenRequest);

        var response = await fetch('API/' + apiName, {
            method: 'PUT',
            credentials: 'include',
            body: JSON.stringify(objectToPost),
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': `Bearer ${token.accessToken}`
            },
            redirect: 'error'
        });

        //console.log(response);

        return await response.json();
    }

    RawPostAPI = async (apiName, objectToPost) => {

        //console.log(JSON.stringify(objectToPost));

        var requestScope = msalConfig.auth.clientId + '/.default';

        Date.prototype.toISOString = function () {
            var pad = function (num) {
                var norm = Math.floor(Math.abs(num));
                return (norm < 10 ? '0' : '') + norm;
            };

            return this.getFullYear() +
                '-' + pad(this.getMonth() + 1) +
                '-' + pad(this.getDate()) +
                'T' + pad(this.getHours()) +
                ':' + pad(this.getMinutes());
        };

        const accessTokenRequest = {
            scopes: [requestScope]
        }

        var token = await msalAuth.acquireTokenSilent(accessTokenRequest);

        var response = await fetch('API/' + apiName, {
            method: 'GET',
            credentials: 'include',
            body: JSON.stringify(objectToPost),
            responseType: "blob",
            headers: {
                'Authorization': `Bearer ${token.accessToken}`,
                'Accept': 'application/pdf',
                'Content-Type': 'application/pdf'
            },
            redirect: 'error'
        });

        //console.log(response);

        return response;
    }

    DeleteAPI = async (apiName, objectToPost) => {

        //console.log(JSON.stringify(objectToPost));

        var requestScope = msalConfig.auth.clientId + '/.default';

        const accessTokenRequest = {
            scopes: [requestScope]
        }

        var token = await msalAuth.acquireTokenSilent(accessTokenRequest);

        var response = await fetch('API/' + apiName, {
            method: 'DELETE',
            credentials: 'include',
            body: JSON.stringify(objectToPost),
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': `Bearer ${token.accessToken}`
            },
            redirect: 'error'
        });

        //console.log(response);

        return await response.json();
    }

    formatPhoneNumber = (str) => {
        //Filter only numbers from the input
        let cleaned = ('' + str).replace(/\D/g, '');

        //Check if the input is of correct
        let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

        if (match) {
            //Remove the matched extension code
            //Change this to format for any country code.
            let intlCode = (match[1] ? '+1 ' : '')
            return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
        }

        return str;
    }

    getQueryVariable(variable) {
        var query = window.location.search.substring(1);
        var vars = query.split("&");
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            if (pair[0] == variable) { return pair[1]; }
        }

        return (null);
    }

    updateProperty = (obj, prop, value) => {
        let newState = Object.assign({}, this.state);
        newState[obj][prop] = value;

        this.setState(newState);
    }

    updateIndexProperty = (obj, prop, index, value) => {
        let newState = Object.assign({}, this.state);
        newState[obj][index][prop] = value;
        this.setState(newState);
    }

    CreateDropDownOptions(array, valueProp, textProp, filter) {

        filter = filter || "item => 1 == 1";

        // console.log("DropDown filter: " + filter);

        return array.filter(eval(filter)).map((option) =>
            <option value={(valueProp ? option[valueProp] : option)}>{(textProp ? option[textProp] : option)}</option>
        );
    }

    // TODO ABG: Potentially encapsulate this in a more base-classed way
    FilterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        if (row[id] !== null && typeof row[id] === 'string') {
            return (
                row[id] !== undefined ?
                    String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true
            )
        } else if (row[id] !== null) {
            let re = new RegExp("^" + filter.value, "i");
            let match = (String(row[id]).match(re) === null) ? false : true;
            return match;
        } else {
            return false;
        }
    }

    onExporting(e) {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
        exportDataGrid({
            component: e.component,
            worksheet: worksheet
        }).then(function () {
            workbook.xlsx.writeBuffer()
                .then(function (buffer) {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), (e.fileName + '.xlsx'));
                });
        });
        //e.cancel = true;

        //alert('Export has been disabled until further notice due to security concerns.');
    }

    systemConfig = systemConfig;
}