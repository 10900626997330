
import React from 'react';
import BaseComponent, { FetchAPIPromise } from '../BaseComponent';
import DataGrid, { FilterRow, Column, Pager, Paging, MasterDetail, Button as GridButton, Export } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import TextBox from 'devextreme-react/text-box';
import Form, { EmptyItem, Item, Label, RequiredRule } from 'devextreme-react/form';
import { Popup } from 'devextreme-react/popup';
import { ScrollView } from 'devextreme-react/scroll-view';
import MilestoneDetail from '../WorkOrder/MilestoneDetail';
import DateBox from 'devextreme-react/date-box';
import Button from 'devextreme-react/button';
import { LoadPanel } from 'devextreme-react/load-panel';

import QRCode from 'qrcode.react';

export class CustomerHistoryContainer extends BaseComponent {
    constructor(props) {
        super(props);
        this.formElement = React.createRef();

        this.state = {
            loading: false,

            isViewingDetails: false,
            placementDetails: {},

            isViewingMilestones: false,
            allMilestones: {},
            workOrderSelected: null,
            milestonesHeader: null,

            isViewingCustomer: false,
            customerHistory: {},
            HistoryHeader: null,
            currentANum: null,

            filters: {
                searchText: ''
            }
        };
    }

    componentDidMount = async () => {
        super.componentDidMount();
        this.fetchData();
    }

    GetComponentPageName = () => {
        return ("Customer History");
    }

    placementList = new CustomStore({
        key: 'customerID',
        onLoading: (loadOptions) => {
            //console.log('Loading: ', loadOptions);

            //loadOptions.userData["notDispatched"] = true;
            //loadOptions.userData["dispatched"] = true;
            //loadOptions.userData["addedOnSupport"] = true;
            //loadOptions.userData["cancelled"] = true;
            loadOptions.userData["text"] = this.state.filters.searchText;
        },
        load: (loadOptions) => {
            //console.log('Load: ' + loadOptions);

            var queryString = ' ';
            var queryOptions = Object.keys(loadOptions.userData);

            //console.log(queryOptions);

            //if (loadOptions.userData[queryOptions[4]]) {
                for (var i = 0; i < queryOptions.length; i++) {

                    if (queryString) {
                        queryString += '&';
                    }

                    queryString += queryOptions[i] + '=' + loadOptions.userData[queryOptions[i]];
                }
                //console.log(queryString);

                return FetchAPIPromise('Placement/SearchCustomerPlacementHistory', queryString);
            //}
        }
    });

    SearchCases = (e) => {
        var result = this.editFormControl.instance.validate();

        if (result.isValid) {
            this.dataGrid.instance.refresh();
        }
    }

    hideMilestonesPopUp = (e) => {
        this.setState({
            isViewingMilestones: false,
            allMilestones: {},
            workOrderSelected: null,
            milestonesHeader: null
        });
    }

    showMilestonesPopUp = async (e) => {

        await this.setState({
            loading: true
        });

        const allMilestones = await this.FetchAPI('WorkOrderMilestone/GetAllMilestonesByCustomer?workOrderId=' + this.state.workOrderSelected.workOrderId + '&customerId=' + this.state.placementDetails.customerDetails.customerID);
        const milestonesHeader = ("All milestones for customer #" + (this.state.placementDetails.customerDetails.aNum ? this.state.placementDetails.customerDetails.aNum : this.state.placementDetails.customerDetails.subjectID) + " on work order #" + this.state.workOrderSelected.workOrderNumber + " ");
        const currentANum = this.state.placementDetails.customerDetails.aNum;

        this.setState({
            isViewingMilestones: true,
            allMilestones: allMilestones,
            milestonesHeader: milestonesHeader,
            currentANum: currentANum,
            loading: false
        });
    }

    hideCustomerPopUp = (e) => {
        this.setState({
            isViewingCustomer: false,
            customerHistory: {},
        });
    }

    showCustomerPopUp = async (e) => {

        await this.setState({
            loading: true
        });

        const changeHistory = await this.FetchAPI('Placement/GetCustomerHistory?customerId=' + this.state.placementDetails.customerDetails.customerID);
        const HistoryHeader = ("Record history for customer #" + (this.state.placementDetails.customerDetails.aNum ? this.state.placementDetails.customerDetails.aNum : this.state.placementDetails.customerDetails.subjectID));

        this.setState({
            isViewingCustomer: true,
            customerHistory: changeHistory,
            HistoryHeader: HistoryHeader,
            loading: false
        });
    }

    hidePlacementDetails = (e) => {
        this.setState({
            isViewingDetails: false,
            placementDetails: {}
        });

    }

    showPlacementDetails = async (e) => {
        await this.setState({
            loading: true
        });

        const placementDetails = await this.FetchAPI('Placement/GetCustomerPlacementHistoryDetails?customerId=' + e.row.data.customerID + '&placementId=' + e.row.data.orrPlacementId);

        this.setState({
            isViewingDetails: true,
            placementDetails: placementDetails,
            loading: false
        });
    }

    CreateSingleWristband = async (e) => {
        //console.log('got here');
        //console.log(this.props.workOrder);

        var cheatSheet = await this.RawPostAPI("Reports/GetSingleWristband?customerId=" + this.state.placementDetails.customerDetails.customerID + '&placementId=' + this.state.placementDetails.placementDetails.id);
        //console.log(cheatSheet);

        var blob = await cheatSheet.blob();
        //console.log(blob);

        // Build a URL from the file
        const fileURL = URL.createObjectURL(blob);

        console.log(fileURL);

        // Open the URL on new Window
        const pdfWindow = window.open();
        pdfWindow.location.href = fileURL;
    }

    render() {

        const rootUrl = window.location.origin;
        const subDomain = process.env.PUBLIC_URL;
        //console.log(rootUrl);
        //console.log(window.location.host);
        //console.log(window.location.origin);
        //console.log(process.env.PUBLIC_URL);

        return (
            <div className="container">
                <h1>Customer History</h1>

                <br />
                <br />

                <Form
                    ref={ref => this.editFormControl = ref}
                    formData={this.state.filters} colCount={3}>
                    <Item colSpan={3} dataField="searchText" editorType="dxTextBox" editorOptions={{ placeholder: 'Search for A Number, Name, or DOB...', onEnterKey: this.SearchCases, showClearButton: true }}>
                        <Label visible={false} />
                        <RequiredRule />
                    </Item>
                </Form>
                <br />

                <DataGrid dataSource={this.placementList} ref={ref => this.dataGrid = ref}
                    showBorders={true} allowColumnResizing={true} onExporting={this.onExporting}>
                    <Export enabled={true} fileName={"customerHistory"} />
                    <FilterRow visible={true} />
                    <Paging defaultPageSize={10} />
                    <Pager showPageSizeSelector={true}
                        allowedPageSizes={[5, 10, 20]}
                        showInfo={true} />

                    <Column dataField="aNum" caption="A Number" />
                    <Column dataField="firstName" />
                    <Column dataField="middleName" />
                    <Column dataField="lastName" />
                    <Column dataField="gender" />
                    <Column dataField="dob" caption="DOB" format="M/d/yyyy" dataType="date" />
                    <Column dataField="nationality" />
                    <Column dataField="orrPlacementDate" caption="Placement Date" format="MM/d/yyyy HH:mm '(Local)'" dataType="datetime" />
                    <Column dataField="pickupLocationName" caption="Origin" />
                    <Column dataField="facilityName" caption="Destination" />
                    <Column dataField="historyStatus" caption="Dispatch Status" />
                    <Column type="buttons">
                        <GridButton text="View Details" onClick={this.showPlacementDetails} />
                    </Column>
                </DataGrid>

                <Popup visible={this.state.isViewingDetails} onHiding={this.hidePlacementDetails} dragEnabled={false}
                    closeOnOutsideClick={false} showTitle={true} title="Placement Details">
                    <ScrollView>
                        <Form formData={this.state.placementDetails}>
                            <Item itemType="group" caption="Customer Info" colCount={3}>
                                <Item dataField="customerDetails.aNum" editorOptions={{ readOnly: true }}>
                                    <Label text="A#" />
                                </Item>
                                <Item dataField="customerDetails.subjectID" editorOptions={{ readOnly: true }}>
                                    <Label text="Subject ID" />
                                </Item>
                                <EmptyItem />

                                <Item dataField="customerDetails.firstName" editorOptions={{ readOnly: true }}>
                                    <Label text="First Name" />
                                </Item>
                                <Item dataField="customerDetails.middleName" editorOptions={{ readOnly: true }}>
                                    <Label text="Middle Name" />
                                </Item>
                                <Item dataField="customerDetails.lastName" editorOptions={{ readOnly: true }}>
                                    <Label text="Last Name" />
                                </Item>

                                <Item dataField="customerDetails.gender" editorOptions={{ readOnly: true }}>
                                    <Label text="Gender" />
                                </Item>
                                <Item dataField="customerDetails.dob" editorType="dxDateBox" editorType="dxDateBox" editorOptions={{ displayFormat: 'MM/dd/yyyy', type: 'date', readOnly: true }}>
                                    <Label text="DOB" />
                                </Item>
                                <Item dataField="customerDetails.nationality" editorOptions={{ readOnly: true }}>
                                    <Label text="Nationality" />
                                </Item>

                                <Item colSpan={3}>
                                    <Button text="Show Customer Record Change History" type="normal" onClick={this.showCustomerPopUp} />
                                </Item>
                            </Item>

                            <Item itemType="group" caption="Placement Details" colCount={3}>
                                <Item dataField="placementDetails.orrPlacementDate" editorType="dxDateBox" editorOptions={{ displayFormat: "MM/dd/yyyy HH:mm '(Local)'", type: 'datetime', readOnly: true }}>
                                    <Label text="Placement Date" />
                                </Item>
                                <Item dataField="placementDetails.dhsCustodyDate" editorType="dxDateBox" editorOptions={{ displayFormat: 'MM/dd/yyyy HH:mm', type: 'datetime', readOnly: true }}>
                                    <Label text="DHS Custody Date" />
                                </Item>
                                <Item dataField="placementDetails.statusName" editorOptions={{ readOnly: true }}>
                                    <Label text="Status" />
                                </Item>

                                <Item dataField="placementDetails.departureCity" editorOptions={{ readOnly: true }}>
                                    <Label text="Departure City" />
                                </Item>
                                <Item dataField="placementDetails.pickupLocationName" editorOptions={{ readOnly: true }}>
                                    <Label text="Pickup Location" />
                                </Item>
                                <Item dataField="placementDetails.destinationName" editorOptions={{ readOnly: true }}>
                                    <Label text="Destination" />
                                </Item>

                                <Item dataField="placementDetails.authorityCode" editorOptions={{ readOnly: true }}>
                                    <Label text="Authority" />
                                </Item>
                                <Item>
                                    {this.state.placementDetails.placementDetails &&
                                        <QRCode value={`${rootUrl}${subDomain}/customer-placement/${this.state.placementDetails.placementDetails.id}/${this.state.placementDetails.customerDetails.customerID}`} />
                                    }
                                </Item>
                                <Item>
                                    <Button onClick={this.CreateSingleWristband} text="Generate Wristband" />
                                </Item>
                            </Item>

                            {this.state.placementDetails.placementDetails && this.state.placementDetails.placementDetails.alternatePOCName &&
                                <Item itemType="group" caption="Sponsor Info" colCount={3}>
                                    <Item dataField="placementDetails.alternatePOCName" editorOptions={{ readOnly: true }}>
                                        <Label text="POC Name" />
                                    </Item>
                                    <Item dataField="placementDetails.alternatePOCPhoneNumber" editorOptions={{ readOnly: true }}>
                                        <Label text="POC Phone" />
                                    </Item>
                                    <Item dataField="placementDetails.alternatePOCAddress" editorOptions={{ readOnly: true }}>
                                        <Label text="POC Address" />
                                    </Item>

                                    <Item dataField="placementDetails.alternatePOCCity" editorOptions={{ readOnly: true }}>
                                        <Label text="POC City" />
                                    </Item>
                                    <Item dataField="placementDetails.alternatePOCState" editorOptions={{ readOnly: true }}>
                                        <Label text="POC State" />
                                    </Item>
                                    <Item dataField="placementDetails.alternatePOCZIPCode" editorOptions={{ readOnly: true }}>
                                        <Label text="POC ZIP" />
                                    </Item>
                                </Item>
                            }

                            {(!this.state.placementDetails.placementDetails || !this.state.placementDetails.placementDetails.alternatePOCName) &&
                                <Item>
                                    <Label text="No Alternate POC/Sponsor Information provided" showColon={false} />
                                </Item>
                            }

                            {this.state.placementDetails.workOrderHistory && this.state.placementDetails.workOrderHistory.length > 0 &&
                                <Item itemType="group" caption="Trip History" colCount={3}>
                                    {this.state.placementDetails.workOrderHistory.map((workOrder) => {
                                        var items = [];

                                        items.push(<Item>
                                            <Label text="Work Order Name" />
                                            <TextBox value={workOrder.workOrderName} readOnly={true} />
                                        </Item>);
                                        items.push(<Item>
                                            <Label text="Work Order Number" />
                                            <TextBox value={workOrder.workOrderNumber} readOnly={true} />
                                        </Item>);
                                        items.push(<Item>
                                            <Label text="Status" />
                                            <TextBox value={workOrder.statusName} readOnly={true} />
                                        </Item>);

                                        items.push(<Item>
                                            <Label text="Plan Start Date" />
                                            <DateBox displayFormat="MM/d/yyyy HH:mm" value={workOrder.planStartDate} readOnly={true} />
                                        </Item>);
                                        if (workOrder.pickupStatusCode == 'ACTIVE') {
                                            items.push(<Item>
                                                <Label text="Plan Pickup Date" />
                                                <DateBox displayFormat="MM/d/yyyy HH:mm" value={workOrder.planPickupDate} readOnly={true} />
                                            </Item>);
                                        }
                                        else {
                                            items.push(<Item>
                                                <Label text="Plan Pickup Date" />
                                                <TextBox value='N/A' readOnly={true} />
                                            </Item>);
                                        }
                                        items.push(<Item>
                                            <Label text="Departure City" />
                                            <TextBox value={workOrder.departureCityFullName} readOnly={true} />
                                        </Item>);

                                        if (workOrder.dropoffStatusCode == 'ACTIVE') {
                                            items.push(<Item>
                                                <Label text="Plan Dropoff/Removal Date" />
                                                <DateBox displayFormat="MM/d/yyyy HH:mm" value={workOrder.planRemovalDate} readOnly={true} />
                                            </Item>);
                                        }
                                        else {
                                            items.push(<Item>
                                                <Label text="Plan Dropoff/Removal Date" />
                                                <TextBox value='N/A' readOnly={true} />
                                            </Item>);
                                        }

                                        items.push(<Item>
                                            <Label text="Plan End Date" />
                                            <DateBox displayFormat="MM/d/yyyy HH:mm" value={workOrder.planEndDate} readOnly={true} />
                                        </Item>);
                                        items.push(<Item>
                                            <Label text="Destination City" />
                                            <TextBox value={workOrder.destinationCityFullName} readOnly={true} />
                                        </Item>);

                                        items.push(<Item>
                                            <Label text="Traffic Type" />
                                            <TextBox value={workOrder.trafficTypeCode} readOnly={true} />
                                        </Item>);
                                        items.push(<Item>
                                            <Label text="Transport Method" />
                                            <TextBox value={workOrder.transportMethodCode} readOnly={true} />
                                        </Item>);

                                        items.push(<EmptyItem />);
                                        items.push(<Item>
                                            <Label text="Transport Unit" />
                                            <TextBox value={workOrder.transportUnit} readOnly={true} />
                                        </Item>);
                                        items.push(<Item>
                                            <Label text="Work Order Type" />
                                            <TextBox value={workOrder.workOrderType} readOnly={true} />
                                        </Item>);
                                        items.push(<EmptyItem />);

                                        items.push(<Item colSpan={3}>
                                            <h7>{"Employees assigned to work order #" + workOrder.workOrderNumber + " :"}</h7>
                                        </Item>);

                                        items.push(<Item colSpan={3}><DataGrid dataSource={workOrder.employeeList}
                                            showBorders={true} allowColumnResizing={true} >
                                            <Column dataField="firstName" />
                                            <Column dataField="middleName" />
                                            <Column dataField="lastName" />
                                            <Column dataField="employeeNo" />
                                            <Column dataField="homePhone" caption="Personal Phone" />
                                            <Column dataField="workOrderRole" />
                                        </DataGrid></Item>);

                                        items.push(<Item colSpan={3}>
                                            <h7>{"Most recent milestones for customer #" + (this.state.placementDetails.customerDetails.aNum ? this.state.placementDetails.customerDetails.aNum : this.state.placementDetails.customerDetails.subjectID) + " on work order #" + workOrder.workOrderNumber + " :"}</h7>
                                        </Item>);

                                        items.push(<Item colSpan={3}><DataGrid dataSource={workOrder.milestoneList}
                                            showBorders={true} allowColumnResizing={true}>

                                            <Column dataField="eventDateTime" caption="Event Date/Time" dataType="datetime" format="MM/dd/yy, HH:mm" />
                                            <Column caption="Stage" dataField="stageName" />
                                            <Column dataField="milestoneName" caption="Milestone" />
                                            <Column dataField="employeeFullNameAnum" caption="TS Name" />
                                            <Column dataField="comment" caption="Comment" />
                                            <Column dataField="updatedByFullNameAnum" caption="Last Updated By" />
                                            <Column dataField="updatedAt" caption="Last Updated At" dataType="datetime" format="MM/dd/yy, HH:mm" />

                                            <MasterDetail enabled={true} component={MilestoneDetail} />
                                            
                                        </DataGrid></Item>);

                                        items.push(<Item colSpan={3}>
                                            <Button text="Show all milestones" type="normal" onClick={this.state.workOrderSelected = workOrder, this.showMilestonesPopUp} />
                                        </Item>);

                                            return (items);
                                            })}
                                </Item>
                            }
                        </Form>
                    </ScrollView>
                </Popup>

                <Popup visible={this.state.isViewingCustomer} onHiding={this.hideCustomerPopUp} dragEnabled={false}
                    closeOnOutsideClick={false} showTitle={true} title={this.state.HistoryHeader}>
                    <ScrollView>
                        <DataGrid dataSource={this.state.customerHistory}
                            showBorders={true} allowColumnResizing={true} onExporting={this.onExporting}>
                            <Export enabled={true} fileName={"recordChangeHistory" + this.state.currentANum} />
                            <FilterRow visible={true} />

                            <Column dataField="createDate" caption="Change Date/Time" dataType="datetime" format="MM/dd/yy, HH:mm" />
                            <Column dataField="createUser" caption="Employee No." />
                            <Column dataField="updateField" caption="Field Updated" />
                            <Column dataField="updateType" caption="Change Type" />
                            <Column dataField="oldValue" caption="Old Value" />
                            <Column dataField="newValue" caption="New Value" />

                        </DataGrid>
                    </ScrollView>
                </Popup>

                <Popup visible={this.state.isViewingMilestones} onHiding={this.hideMilestonesPopUp} dragEnabled={false}
                    closeOnOutsideClick={false} showTitle={true} title={this.state.milestonesHeader}>
                    <ScrollView>
                        <DataGrid dataSource={this.state.allMilestones}
                            showBorders={true} allowColumnResizing={true} onExporting={this.onExporting}>
                            <Export enabled={true} fileName={"allMilestonesA" + this.state.currentANum} />
                            <FilterRow visible={true} />

                            <Column dataField="eventDateTime" caption="Event Date/Time" dataType="datetime" format="MM/dd/yy, HH:mm" />
                            <Column caption="Stage" dataField="stageName" />
                            <Column dataField="milestoneName" caption="Milestone" />
                            <Column dataField="employeeFullNameAnum" caption="TS Name" />
                            <Column dataField="comment" caption="Comment" />
                            <Column dataField="updatedByFullNameAnum" caption="Last Updated By" />
                            <Column dataField="updatedAt" caption="Last Updated At" dataType="datetime" format="MM/dd/yy, HH:mm" />

                            <MasterDetail enabled={true} component={MilestoneDetail} />
                        </DataGrid>
                    </ScrollView>
                </Popup>

                <LoadPanel visible={this.state.loading} />
            </div>
        );
    }
}