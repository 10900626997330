import React from 'react';
import CustomStore from 'devextreme/data/custom_store';
import BaseComponent, { FetchAPIPromise, PostAPIPromise, DeleteAPIPromise, withParams } from "../BaseComponent";
import DataGrid, { Column, FilterRow, Pager, Paging, Editing, Lookup, FormItem, Button as GridButton, Summary, TotalItem, GroupItem, Export, Selection } from 'devextreme-react/data-grid';
import Form, { Item, Label, ButtonItem, RequiredRule, StringLengthRule, EmptyItem } from 'devextreme-react/form';
import { Link } from 'react-router-dom';
import { LoadPanel } from 'devextreme-react/load-panel';
import { Popup } from 'devextreme-react/popup';
import { ScrollView } from 'devextreme-react/scroll-view';
import * as $ from 'jquery'
import DataSource from 'devextreme/data/data_source';
import Button from 'devextreme-react/button';
import { alert, confirm } from 'devextreme/ui/dialog';
import { v4 as uuidv4 } from 'uuid';
import ArrayStore from 'devextreme/data/array_store';
import * as moment from 'moment';

// Below is needed by the Form control to "import" the appropriate control
import TextArea from 'devextreme-react/text-area';
import { flushSync } from 'react-dom';

// ONGOING ISSUES
// 1) When we clear the pickup location, for some reason it doesn't require the value anymore...does save with a null value, though.

class PlacementEditContainer extends BaseComponent {
    static displayName = PlacementEditContainer.name;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            placementId: '00000000-0000-0000-0000-000000000000',
            placementToCopyId: null,

            hasAlternatePOC: false,

            workOrderID: '',
            placement: {},
            transportUnits: [],
            authorityCodes: [],
            filteredAuthorityCodes: [],
            cities: [],
            facilities: [],
            groupedFacilities: [],
            pickupLocations: [],
            filterPickupLocations: [],
            subclassifications: [],
            filteredSubclassifications: [],
            statuses: [],
            placementHistory: [],

            genders: [],
            nationalities: [],
            relationCodes: [],
            placementTags: [],
            healthTags: [],
            movementTypes: [],
            filteredMovementTypes: [],
            cancellationReasons: [],

            facilityPopupVisible: false,
            facility: {},
            states: [],

            isAddingCustomer: false,
            customerData: {},

            isViewingContactLog: false,

            isViewingCustomer: false,
            customerHistory: {},
            HistoryHeader: null,

            selectionMode: 'none',
            customerTotalCount: 0
        };
    }

    componentDidMount = async () => {
        super.componentDidMount();
        //console.log('ComponentDidMount');
        //console.log(this.props.params);

        if (this.props.params.id != undefined) {
            await this.setState({ placementId: this.props.params.id });
        }

        if (this.props.params.workOrderId != undefined) {
            await this.setState({ workOrderID: this.props.params.workOrderId });
        }

        if (this.props.params.placementToCopyId != undefined) {
            await this.setState({ placementToCopyId: this.props.params.placementToCopyId });
        }

        await this.fetchData();
    }

    GetComponentPageName = () => {
        return (this.state.placement.placementShortName ? (this.state.placement.placementShortName + " Placement Edit") : 'Create New Placement');
    }

    GetData = async () => {

        //console.log('Placement Id', this.state.placementId);
        //console.log('WorkOrder Id', this.state.workOrderID);

        if (this.state.placementId != '00000000-0000-0000-0000-000000000000') {

            var placementData = await this.FetchAPI('Placement/' + this.state.placementId);

            //console.log('placement', placementData);
            //console.log('latitude', placementData.alternatePOCLatitude);

            var placementHistory = await this.FetchAPI('PlacementHistory/GetWorkOrdersByPlacementId/' + this.state.placementId);

            // TODO: if Work Order Id is there, lock the transport unit to match
            const filterPickupLocations = this.state.pickupLocations.filter(m => m.departureCityCode === placementData.departureCityCode);

            // Filter statuses
            var filteredStatuses = this.state.statuses.filter(m => m.id == placementData.statusID || m.id == 3);

            const filterFacilities = this.state.facilities.filter(f => f.isActive || f.id == placementData.facilityId);

            const groupedFacilities = new DataSource({
                store: filterFacilities,
                key: 'id',
                group: 'state'
            });

            //console.log(this.state.movementTypes);

            const filterMovements = this.state.movementTypes.filter(m => m.transportUnitCode === placementData.transportUnitCode && (m.active || m.code === placementData.movementTypeCode));

            const filterAuthorityCodes = this.state.authorityCodes.filter(m => m.transportUnitCode === placementData.transportUnitCode && (m.isActive || m.code === placementData.authorityCode));

            const filterSubclassifications = this.state.subclassifications.filter(m => (m.isActive || m.code === placementData.subclassificationCode));

            var hasAlternatePOC = placementData.alternatePOCName;

            await this.setState({
                placement: placementData,
                filterPickupLocations: filterPickupLocations,
                filteredAuthorityCodes: filterAuthorityCodes,
                groupedFacilities: groupedFacilities,
                statuses: filteredStatuses,
                placementHistory: placementHistory,
                hasAlternatePOC: hasAlternatePOC,
                filteredMovementTypes: filterMovements,
                filteredSubclassifications: filterSubclassifications
            });
        }
        else {
            var placementHistory = [];
            var placement = {};

            if (this.state.placementToCopyId) {
                var placementData = await this.FetchAPI('Placement/' + this.state.placementToCopyId);

                placement.orrPlacementDate = placementData.orrPlacementDate;
                placement.transportUnitCode = placementData.transportUnitCode;
                placement.departureCityCode = placementData.departureCityCode;
                placement.pickupLocationId = placementData.pickupLocationId;
                placement.facilityId = placementData.facilityId;
                placement.dhsCustodyDate = placementData.dhsCustodyDate;
                placement.subclassificationCode = placementData.subclassificationCode;
                placement.statusID = 0;
                placement.workOrderID = this.state.workOrderID;
                placement.tagList = placementData.tagList;
                placement.movementTypeCode = placementData.movementTypeCode;
            }
            else {
                placement = {
                    statusID: 0,
                    workOrderID: this.state.workOrderID,
                    tagList: []
                };
            }

            if (this.state.workOrderID) {
                placementHistory = await this.FetchAPI('PlacementHistory/GetWorkOrderForNewCustomer/' + this.state.workOrderID);

                placement.transportUnitCode = placementHistory[0].transportUnitCode;
            }

            const filterPickupLocations = this.state.pickupLocations.filter(m => m.departureCityCode === placement.departureCityCode);

            var filterFacilities = this.state.facilities.filter(f => f.isActive);

            const groupedFacilities = new DataSource({
                store: filterFacilities,
                key: 'id',
                group: 'state'
            });

            const filterAuthorityCodes = this.state.authorityCodes.filter(m => m.transportUnitCode === placement.transportUnitCode && (m.isActive || m.code === placement.authorityCode));

            const filterMovements = this.state.movementTypes.filter(m => m.transportUnitCode === placement.transportUnitCode && (m.active || m.code === placement.movementTypeCode));

            const filterSubclassifications = this.state.subclassifications.filter(m => (m.isActive || m.code === placement.subclassificationCode));

            // console.log(placement);

            await this.setState({
                placement: placement,
                filterPickupLocations: filterPickupLocations,
                groupedFacilities: groupedFacilities,
                placementHistory: placementHistory,
                filteredMovementTypes: filterMovements,
                filteredAuthorityCodes: filterAuthorityCodes,
                filteredSubclassifications: filterSubclassifications
            });
        }
    }

    customDataSource = new CustomStore({
        key: ['customerID'],
        load: (loadOptions) => {
            //console.log('Load', loadOptions);
            //console.log(this.props.workOrder.id);

            if (this.state.placementId) {

                var placementData = this.FetchAPI('Placement/' + this.state.placementId);
                var customerList = this.FetchAPI("Placement/GetCustomersByPlacement/" + this.state.placementId);

                console.log(customerList);

                customerList.then((a) => {
                    placementData.then((pd) => {
                        this.customerTotalCount = a.length;
                        if (pd.transportUnitCode == 'UC' && a.length > 1 && !this.IsReadOnly())
                            this.selectionMode = 'multiple';
                    })
                })

                return customerList;
                //return FetchAPIPromise("Placement/GetCustomersByPlacement/" + this.state.placementId);
            }
        },
        remove: (key) => {
            console.log(key);

            var queryString = 'placementId=' + this.state.placementId + '&customerId=' + key.customerID

            return DeleteAPIPromise('Placement/DeletePlacementCustomer', queryString)
                .then((result) => {
                    if (result.status == -2) {
                        // TODO: Check this
                        alert('Customer is referenced in the system and cannot be deleted.')
                    }
                    else if (result.status == 0) {
                        alert("One or more Customers have logged milestones for this Work Order and cannot be removed.")
                    }
                });
        }
    });

    GetDropDownData = async () => {
        const transportUnitData = await this.FetchAPI('DictionaryTransportUnit');
        const cityData = await this.FetchAPI('DictionaryCity');

        const groupedCities = new DataSource({
            store: cityData,
            key: 'cityCode',
            group: 'stateName'
        });

        var facilityData = await this.FetchAPI('Facility/GetForSelection');

        const authorityCodeData = await this.FetchAPI('DictionaryAuthority');

        var pickupAgencies = await this.FetchAPI('PickupLocation/GetPickupAgencies');

        var subclassificationData = await this.FetchAPI('DictionarySubclassification');

        var statusesData = await this.FetchAPI('DictionaryPlacementStatus');
        const movementTypeData = await this.FetchAPI('DictionaryMovementType');
        const cancellationReasonData = await this.FetchAPI('DictionaryCancellationReason');

        const genderData = await this.FetchAPI('DictionaryGender');
        const nationalityData = await this.FetchAPI('DictionaryNationality');
        const relationCodeData = await this.FetchAPI('DictionaryFamilyGroupRelation');

        const placementTagsData = await this.FetchAPI('DictionaryPlacementTags');

        const healthTagsData = await this.FetchAPI('DictionaryHealthTags');

        const stateData = await this.FetchAPI('DictionaryState');

        //console.log(pickupAgencies);

        flushSync(() => {
            this.setState({
                transportUnits: transportUnitData,
                cities: groupedCities,
                facilities: facilityData,
                pickupLocations: pickupAgencies,
                genders: genderData,
                nationalities: nationalityData,
                relationCodes: relationCodeData,
                subclassifications: subclassificationData,
                statuses: statusesData,
                authorityCodes: authorityCodeData,
                placementTags: placementTagsData,
                healthTags: healthTagsData,
                movementTypes: movementTypeData,
                cancellationReasons: cancellationReasonData,
                states: stateData
            });
        });
    }

    validateForm = () => {
        var msg = "";

        var initialValidate = this.editFormControl.instance.validate();

        if (!initialValidate.isValid) {
            return false;
        }

        if (this.state.placement.transportUnitCode == 'UC') {

            var relationCodeCount = 0;
            var nonRelationCount = 0;

            // TODO: Check on the server side?
            ////console.log('handling initial set');
            //this.state.placement.customerList.map((customer, customerIndex) => {
            //    // console.log(customer);

            //    var time = new Date(customer.dob);
            //    var year = time.getFullYear();
            //    var month = time.getMonth();
            //    var day = time.getDate();

            //    var time2 = new Date(this.state.placement.orrPlacementDate);

            //    var year2 = time2.getFullYear();
            //    var month2 = time2.getMonth();
            //    var day2 = time2.getDate();
            //    //console.log(day2);

            //    var ageYear = year2 - year;
            //    var ageMonth = month2 - month;
            //    var ageDay = day2 - day;

            //    //console.log(ageYear);

            //    if (ageYear >= 18) {

            //        //console.log(ageMonth);

            //        if (ageMonth >= 0) {
            //            //console.log(ageDay);

            //            if (ageDay >= 0) {
            //                msg += 'Please check the DOB data you are entering. UNC must be less than 18!';
            //                return false;
            //            }
            //        }
            //    }

            //    if (customer.relationCode) {
            //        relationCodeCount++;
            //    }
            //    else {
            //        nonRelationCount++;
            //    }
            //});

            // TODO:
            //if (relationCodeCount == 1) {
            //    alert('Use of Relation Code is only applicable when used for 2 or more Customers.');
            //    return false;
            //}

            //if (relationCodeCount > 0 && nonRelationCount > 0) {
            //    alert('Related Family Group members must be on their own unique Placement.');
            //    return false;
            //}
        }

        if (msg != "") {
            alert(msg);
            return false;
        } else {
            return true;
        }
    }

    SavePlacement = async (e) => {
        //console.log('Saving...');
        //e.preventDefault();

        if (this.validateForm()) {
            //console.log('Valid');
            await this.setState({
                loading: true
            });

            console.log(this.state.placement);
            const result = await this.PostAPI('Placement', this.state.placement);

            console.log(result);

            if (result.status == 1) {
                var placementId = this.state.placementId;

                if (this.state.placementId == '00000000-0000-0000-0000-000000000000') {
                    placementId = result.message;
                }

                var path = '/edit-placement/' + placementId;

                if (this.state.workOrderID) {
                    path = path + '/' + this.state.workOrderID;
                }

                this.props.navigate(path);
                this.props.navigate(0);
            }
            else {
                console.log(result);
                alert('Failed. Please try again later.');
            }

            await this.setState({
                loading: false
            });
        }
    }

    SavePlacementAndCopy = async (e) => {
        //console.log('Saving...');
        //e.preventDefault();

        if (this.validateForm()) {
            await this.setState({
                loading: true
            });

            console.log(this.state.placement);
            const result = await this.PostAPI('Placement', this.state.placement);

            console.log(result);

            if (result.status == 1) {
                var placementId = this.state.placementId;

                if (this.state.placementId == '00000000-0000-0000-0000-000000000000') {
                    placementId = result.message;
                }

                var path = '/copy-placement/' + placementId;

                if (this.state.workOrderID) {
                    path = path + '/' + this.state.workOrderID;
                }

                this.props.navigate(path);
                this.props.navigate(0);
            }
            else {
                console.log(result);
                alert('Failed. Please try again later.');
            }

            await this.setState({
                loading: false
            });
        }
    }

    hideFacility = async () => {
        await this.setState({
            facility: null,
            facilityPopupVisible: false
        })
    }

    hideCustomerEdit = async () => {
        await this.setState({
            isAddingCustomer: false,
            customerData: {}
        })
    }

    facilityClick = async (e, facilityId) => {
        e.preventDefault();

        const facility = await this.FetchAPI('Facility/' + facilityId);
        await this.setState({
            facility: facility,
            facilityPopupVisible: true
        });
    }

    canDelete = (e) => {
        return this.state.placement && this.state.placement.statusID == 0 && !this.IsReadOnly();
    }

    fieldDataChanged = async (e) => {

        //console.log('latitude', this.state.placement.alternatePOCLatitude);

        switch (e.dataField) {
            case "departureCityCode":
                {
                    //console.log('Field data changed...', e);
                    //console.log('Departure City new value', e.value);
                    //console.log('Current values', this.state.placement);

                    const filterPickupLocations = this.state.pickupLocations.filter(m => m.departureCityCode === e.value);

                    console.log(filterPickupLocations);

                    await this.setState({
                        filterPickupLocations: filterPickupLocations
                    });

                    var pickupLocationInList = this.state.filterPickupLocations.find(o => o.id === this.state.placement.pickupLocationId);

                    if (!pickupLocationInList) {
                        await this.updateProperty('placement', 'pickupLocationId', null);
                    }

                    break;
                }

            case "statusID": {
                this.updateProperty('placement', 'statusID', e.value);
                if (this.state.placement.statusID != 3) {
                    this.updateProperty('placement', 'cancellationNotes', null);
                }
                break;
            }

            case "transportUnitCode":
                {
                    this.updateProperty('placement', 'transportUnitCode', e.value);

                    const filterMovementTypes = this.state.movementTypes.filter(mt => mt.transportUnitCode === e.value && (mt.active || mt.code === this.state.placement.movementTypeCode));

                    await this.setState({
                        filteredMovementTypes: filterMovementTypes
                    });

                    var movementTypeInList = this.state.filteredMovementTypes.find(o => o.code === this.state.placement.movementTypeCode);

                    if (!movementTypeInList) {
                        console.log('setting code to null');
                        await this.updateProperty('placement', 'movementTypeCode', null);
                        this.editFormControl.instance.updateData('movementTypeCode', null);
                    }

                    //console.log('Field data changed...', e);
                    //console.log('Departure City new value', e.value);
                    //console.log('Current values', this.state.placement);

                    const filterAuthorities = this.state.authorityCodes.filter(m => m.transportUnitCode === e.value && m.isActive);

                    //console.log(filterAuthorities);

                    await this.setState({
                        filteredAuthorityCodes: filterAuthorities
                    });

                    var authorityCodeInList = this.state.filteredAuthorityCodes.find(o => o.code === this.state.placement.authorityCode);

                    // TODO: Test not in list
                    // TODO: Test in list
                    if (!authorityCodeInList) {
                        await this.updateProperty('placement', 'authorityCode', null);
                    }
                    
                    break;
                }
        }
    }

    potentialDuplicatesArrayStore = new ArrayStore({
        key: 'customerID',
    });

    customerFieldDataChanged = async (e) => {
        console.log('Field data changed...', e);

        switch (e.dataField) {
            case "firstName":
            case "lastName":
            case "gender": {
                this.potentialDuplicatesArrayStore.clear();

                var aNumCheck = await this.PostAPI('Placement/ANumCheck', this.state.customerData);

                // TODO: This seems to break things
                // e.component.itemOption("potentialDuplicates", "visible", false);

                console.log(aNumCheck);

                if (aNumCheck.potentialDuplicates && aNumCheck.potentialDuplicates.length > 0) {
                    // alert('A# or Subject ID has been used.');

                    aNumCheck.potentialDuplicates.map((item, index) => this.potentialDuplicatesArrayStore.insert(item));
                }

                this.potentialDuplicatesDataGrid.instance.refresh();

                break;
            }
            case "dob":
                {
                    var newAge = null;

                    if (e.value) {
                        var time = new Date(e.value);
                        var year = time.getFullYear();
                        var month = time.getMonth();

                        var time2;
                        if (!this.state.customerData.orrPlacementDate) {
                            time2 = new Date();
                        }
                        else {
                            time2 = new Date(this.state.customerData.orrPlacementDate);
                        }

                        var year2 = time2.getFullYear();
                        var month2 = time2.getMonth();

                        var ageYear = year2 - year;
                        var ageMonth = (ageYear * 12 + month2 - month) % 12;

                        if ((month2 - month) < 0) {
                            ageYear = ageYear - 1;
                        }

                        newAge = ageYear + " year(s) " + ageMonth + " month(s)";
                    }
                    else {
                        newAge = null;
                    }

                    console.log('New Age value', newAge);

                    // await this.updateProperty('customerData', 'age', newAge);
                    e.component.updateData('age', newAge);

                    this.potentialDuplicatesArrayStore.clear();

                    var aNumCheck = await this.PostAPI('Placement/ANumCheck', this.state.customerData);

                    console.log(aNumCheck);

                    if (aNumCheck.potentialDuplicates && aNumCheck.potentialDuplicates.length > 0) {
                        aNumCheck.potentialDuplicates.map((item, index) => this.potentialDuplicatesArrayStore.insert(item));
                    }

                    this.potentialDuplicatesDataGrid.instance.refresh();

                    break;
                }

            case "aNum":
                {
                    this.potentialDuplicatesArrayStore.clear();

                    var aNumCheck = await this.PostAPI('Placement/ANumCheck', this.state.customerData);

                    // TODO: This seems to break things
                    // e.component.itemOption("potentialDuplicates", "visible", false);

                    console.log(aNumCheck);

                    if (aNumCheck.potentialDuplicates && aNumCheck.potentialDuplicates.length > 0) {
                        // alert('A# or Subject ID has been used.');

                        aNumCheck.potentialDuplicates.map((item, index) => this.potentialDuplicatesArrayStore.insert(item));
                    }

                    this.potentialDuplicatesDataGrid.instance.refresh();

                    break;
                }

            case "subjectID":
                {
                    this.potentialDuplicatesArrayStore.clear();

                    var aNumCheck = await this.PostAPI('Placement/ANumCheck', this.state.customerData);

                    // TODO: This seems to break things
                    // e.component.itemOption("potentialDuplicates", "visible", false);

                    console.log(aNumCheck);

                    if (aNumCheck.potentialDuplicates && aNumCheck.potentialDuplicates.length > 0) {
                        // alert('A# or Subject ID has been used.');

                        aNumCheck.potentialDuplicates.map((item, index) => this.potentialDuplicatesArrayStore.insert(item));
                    }

                    this.potentialDuplicatesDataGrid.instance.refresh();

                    break;
                }
        }
    }

    POCInfoChanged = (e) => {
        console.log('got here');

        this.editFormControl.instance.updateData('alternatePOCLongitude', null);
        this.editFormControl.instance.updateData('alternatePOCLatitude', null);
    }

    searchAddCustomer = async (e) => {
        console.log(this.state.customerData);

        await this.setState({
            isAddingCustomer: true,

            customerData: {
                customerID: uuidv4(),
                isPregnant: false,
                isOther: false,
                isIllness: false,
                isInjury: false,
                orrPlacementDate: this.state.placement.orrPlacementDate
            }
        });
    }

    deleteCustomer = async (e) => {
        //console.log(e);
        var result = await confirm('<center>Are you sure you want to delete this Record? </br> Note: Customer will lose applied Health Tags for this Placement.</center>');

        if (result) {
            await this.customDataSource.remove(e.row.key);
        }

        this.customerListDataGrid.instance.refresh();
    }

    AddAlternatePOC = async (e) => {
        await this.setState({
            hasAlternatePOC: true
        });
    }

    ViewContactLog = async (e) => {
        await this.setState({
            isViewingContactLog: true
        });

        this.contactLogDataGrid.instance.refresh();
    }

    HideContactLog = async () => {
        await this.setState({
            isViewingContactLog: false
        })
    }

    RemoveAlternatePOC = async (e) => {
        //console.log(this.state.customerData);

        this.editFormControl.instance.updateData('alternatePOCAddress', null);
        this.editFormControl.instance.updateData('alternatePOCCity', null);
        this.editFormControl.instance.updateData('alternatePOCName', null);
        this.editFormControl.instance.updateData('alternatePOCPhoneNumber', null);
        this.editFormControl.instance.updateData('alternatePOCAlternatePhoneNumber', null);
        this.editFormControl.instance.updateData('alternatePOCState', null);
        this.editFormControl.instance.updateData('alternatePOCZIPCode', null);
        this.editFormControl.instance.updateData('alternatePOCLatitude', null);
        this.editFormControl.instance.updateData('alternatePOCLongitude', null);

        this.editFormControl.instance.repaint();

        await this.setState({
            hasAlternatePOC: false
        });
    }

    editCustomer = async (e) => {
        console.log(e);

        await this.setState({
            isAddingCustomer: true,

            customerData: { ...e.row.data }
        });
    }

    addExistingCustomer = async (e) => {
        try {

            await this.setState({
                loading: true
            });

            console.log(e);
            // Add customer to the list
            e.row.data.orrPlacementId = this.state.placementId;

            var placements = await this.FetchAPI('Placement/ConflictingPlacements?ANum=' + e.row.data.aNum + '&PickupLocationId=' + this.state.placement.pickupLocationId + '&FacilityId=' + this.state.placement.facilityId);

            if (placements) {
                var confirmAnswer = await confirm(e.row.data.aNum + " is already on a placement with the same pickup location and destination facility. Are you sure you want to add them to this placement?");
                if (!confirmAnswer) {
                    return;
                }
            }

            const result = await this.PostAPI('Placement/SavePlacementCustomer', e.row.data);

            console.log(result);

            if (result.status == 1) {
                this.setState({
                    isAddingCustomer: false,
                    customerData: {}
                });

                this.potentialDuplicatesArrayStore.clear();

                this.customerListDataGrid.instance.refresh();
            }
            else if (result.status == 0) {
                alert("This Customer is already used in this Placement's Work Orders and cannot be added.");
            }
            else if (result.status == -2) {
                alert("This Customer is already assigned to another Placement for an Active Work Order and cannot be added.");
            }
            else {
                console.log(result);
                alert('Failed. Please try again later.');
            }
        }
        finally {
            await this.setState({
                loading: false
            });
        }
    }

    SaveCustomer = async (e) => {
        try {
            await this.setState({
                loading: true
            });

            var result = this.editCustomerControl.instance.validate();
            if (result.isValid) {
                console.log('Valid');

                //console.log('validating', e);

                if (!this.state.customerData.aNum && !this.state.customerData.subjectID) {
                    alert('Please input either A# or Subject ID.');
                    return;
                }

                if (this.state.customerData.aNum) {
                    if ((this.state.customerData.aNum.length != 9 || !$.isNumeric(this.state.customerData.aNum)) && this.state.customerData.nationality != 5189) {
                        alert("Please input A# with 9 digits, only numbers without 'A', '-' or spaces.");
                        return;
                    }
                }


                var aNumCheck = await this.PostAPI('Placement/ANumCheck', this.state.customerData);

                console.log('anumcheck', aNumCheck);

                if (aNumCheck.potentialDuplicates && aNumCheck.potentialDuplicates.length > 0 && aNumCheck.newAnum && aNumCheck.anumSubjectIDUsed) {
                    alert('A# or Subject ID has been used. Please correct the #s or choose an existing Customer to add.');
                    return;
                }

                var customerData = this.state.customerData;
                customerData.orrPlacementId = this.state.placementId;
                const result = await this.PostAPI('Placement/SavePlacementCustomer', customerData);

                console.log(result);

                if (result.status == 1) {
                    this.setState({
                        isAddingCustomer: false,
                        customerData: {}
                    });

                    this.potentialDuplicatesArrayStore.clear();

                    this.customerListDataGrid.instance.refresh();
                }
                else if (result.status == 0) {
                    alert("This Customer is already used in this Placement's Work Orders and cannot be added.")
                }
                else {
                    console.log(result);
                    alert('Failed. Please try again later.');
                }
            }
        }
        finally {
            await this.setState({
                loading: false
            });
        }
    }

    onRowUpdating(options) {
        options.newData = $.extend({}, options.oldData, options.newData);
    }

    contactLogDataSource = new CustomStore({
        key: 'id',
        load: (loadOptions) => {
            //console.log('Load', loadOptions);

            if (this.state.placementId) {
                return FetchAPIPromise('ContactLog?placementId=' + this.state.placement.id);
            }
        },
        insert: (values) => {
            values.placementId = this.state.placement.id;

            return PostAPIPromise('ContactLog', values);
        },
        update: (key, values) => {
            return PostAPIPromise('ContactLog', values);
        },
        remove: (key) => {
            return DeleteAPIPromise('ContactLog/' + key);
        }
    });

    allowContactDeleting = (options) => {
        //console.log(options);
        //console.log('Can Delete', options.row.data && options.row.data.createUserId == this.state.employeeId && options.row.data.statusCode == 'SUBMITTED');
        //console.log('Can Delete', options.row.data && options.row.data.createUserId != options.row.data.employeeId);

        //return options.row.data && options.row.data.createUserId == this.state.employeeId && options.row.data.statusCode == 'SUBMITTED';
        return options.row.data && options.row.data.isSystemGenerated == false && !this.IsReadOnly();
    }

    calculateGeolocation = async (e) => {

        console.log('Calculating geolocation', e);

        // Check for full address information
        if (!this.state.placement.alternatePOCAddress || !this.state.placement.alternatePOCCity || !this.state.placement.alternatePOCState || !this.state.placement.alternatePOCZIPCode) {
            alert('Please provide POC Address, City, State, and Zip Code');
        }
        else {
            var fullAddress = this.state.placement.alternatePOCAddress + ' ' + this.state.placement.alternatePOCCity + ' ' + this.state.placement.alternatePOCState + ' ' + this.state.placement.alternatePOCZIPCode;

            var response = await this.FetchAPI('Placement/GetGeolocationCoordinates?address=' + fullAddress);
            if (response.status = 1) {
                // Populate results
                //cell.setValue(response.latitude + ',' + response.longitude);
                this.editFormControl.instance.updateData('alternatePOCLatitude', response.latitude);
                this.editFormControl.instance.updateData('alternatePOCLongitude', response.longitude);
            }
            else {
                alert(response.message);
            }
        }
    }

    hideCustomerPopUp = (e) => {
        this.setState({
            isViewingCustomer: false,
            customerHistory: {},
        });
    }

    showCustomerPopUp = async (e) => {
        await this.setState({
            loading: true
        });

        const changeHistory = await this.FetchAPI('Placement/GetCustomerHistory?customerId=' + e.row.data.customerID);
        const HistoryHeader = ("Record history for customer #" + (e.row.data.aNum ? e.row.data.aNum : e.row.data.subjectID));

        this.setState({
            isViewingCustomer: true,
            HistoryHeader: HistoryHeader,
            customerHistory: changeHistory,
            loading: false
        });
    }

    splitPlacements = async (e) => {

        var placementSplitObject = {
            //workOrderId: this.workOrderId,
            placementId: this.state.placementId,
            customerIds: {}
        };

        var itemsSelected = await this.customerListDataGrid.instance.getSelectedRowsData().then(async (rowData) => {
            if (rowData.length == 0) {
                await alert("Please select one or more UNCs.");
                return false;
            }
            else if (rowData.length == this.customerTotalCount) {
                await alert("All UNCs cannot be split off the placement");
                return false;
            }
            else {
                placementSplitObject.customerIds = rowData.map(i => i.id);
                return true;
            }

        })

        if (itemsSelected) {

            var result = await confirm('Are you sure you want to split the selected UNC(s) into a separate Placement?');

            console.log(placementSplitObject);

            if (result) {

                const result = await this.PostAPI('Placement/SplitPlacement', placementSplitObject);

                if (result.status == 1) {
                    alert('Split Placement Successful. Reload the page to view the updated data.');
                }
                else {
                    //console.log(result);
                    alert('Failed. Please try again later.');
                }
            }
        }
    }

    render() {
        //console.log('Customer list', this.state.placement);

        var clientTimeZone = moment.tz.guess();
        var clientTZAbbreviation = moment.tz(clientTimeZone).format('z');

        //console.log(clientTimeZone);
        //console.log(clientTZAbbreviation);

        return (
            <div className="container">
                <h1>Initial Placement Order Form</h1>
                <hr />

                <React.Fragment>
                    <Form
                        readOnly={this.IsReadOnly()}
                        id="form"
                        ref={ref => this.editFormControl = ref}
                        formData={this.state.placement}
                        onFieldDataChanged={this.fieldDataChanged}>
                        <Item itemType="group" colCount={3}>
                            <Item dataField="placementShortName" editorOptions={{ readOnly: true }}>
                                <Label text="Placement Short Name" />
                            </Item>
                            <Item dataField="orrPlacementDate" editorType="dxDateBox" editorOptions={{ displayFormat: `MM/dd/yyyy HH:mm '${clientTZAbbreviation}'`, type: 'datetime', max: new Date() }}>
                                <RequiredRule />
                                <Label text="Placement Date" />
                            </Item>
                            <Item dataField="transportUnitCode" editorType="dxSelectBox" editorOptions={{
                                readOnly: this.state.placement.statusID != 0 || (this.state.workOrderID && this.state.workOrderID != '00000000-0000-0000-0000-000000000000') || this.IsReadOnly(), searchEnabled: true, dataSource: this.state.transportUnits, displayExpr: 'name', valueExpr: 'code', wrapItemText: true
                            }}>
                                <RequiredRule />
                                <Label text="Transport Unit" />
                            </Item>

                            <Item dataField="departureCityCode" editorType="dxSelectBox" editorOptions={{ showClearButton: true, grouped: true, searchEnabled: true, dataSource: this.state.cities, valueExpr: 'cityCode', displayExpr: 'cityName', wrapItemText: true }}>
                                <RequiredRule />
                                <Label text="Departure City" />
                            </Item>
                            <Item dataField="pickupLocationId" editorType="dxSelectBox" editorOptions={{ showClearButton: true, searchEnabled: true, dataSource: this.state.filterPickupLocations, valueExpr: 'id', displayExpr: 'name', wrapItemText: true }}>
                                <RequiredRule />
                                <Label text="Pickup Location" />
                            </Item>
                            <Item dataField="facilityId" editorType="dxSelectBox" editorOptions={{ showClearButton: true, searchEnabled: true, grouped: true, dataSource: this.state.groupedFacilities, displayExpr: 'name', valueExpr: 'id', wrapItemText: true }}>
                                <RequiredRule />
                                <Label text="Destination/Facility" />
                            </Item>

                            <EmptyItem />
                            <EmptyItem />
                            <Item>
                                <a href="#" onClick={(e) => this.facilityClick(e, this.state.placement.facilityId)}>Facility Info</a>
                            </Item>

                            <Item dataField="dhsCustodyDate" editorType="dxDateBox" editorOptions={{ displayFormat: 'MM/dd/yyyy HH:mm', type: 'datetime', max: new Date() }}>
                                <Label text="DHS Custody Date" />
                            </Item>
                            <Item dataField="subclassificationCode" editorType="dxSelectBox" editorOptions={{
                                showClearButton: true, searchEnabled: true, dataSource: this.state.filteredSubclassifications, displayExpr: 'name', valueExpr: 'code', wrapItemText: true
                            }}>
                                <Label text="Subclassification" />
                            </Item>
                            <Item dataField="movementTypeCode" editorType="dxSelectBox" editorOptions={{
                                searchEnabled: true, dataSource: this.state.filteredMovementTypes, displayExpr: 'name', valueExpr: 'code', wrapItemText: true
                            }}>
                                <RequiredRule />
                                <Label text="Movement Type" />
                            </Item>

                            <Item colSpan={2} dataField="tagList" editorType="dxTagBox" editorOptions={{ applyValueMode: 'useButtons', showSelectionControls: true, dataSource: this.state.placementTags, displayExpr: 'tagName', valueExpr: 'tagCode' }}>
                                <Label text="Tags" />
                            </Item>
                            <Item dataField="authorityCode" editorType="dxSelectBox" editorOptions={{ searchEnabled: true, dataSource: this.state.filteredAuthorityCodes, displayExpr: 'name', valueExpr: 'code', readOnly: this.state.placement.statusID == 3 || this.IsReadOnly() }}>
                                <Label text="Authority Code" />
                                <RequiredRule />
                            </Item>

                            <Item dataField="cancellationReasonCode" editorType="dxSelectBox" editorOptions={{
                                showClearButton: true, searchEnabled: true, dataSource: this.state.cancellationReasons, displayExpr: 'name', valueExpr: 'code'
                            }}/>

                            {this.state.placement.statusID == 3 &&
                                <Item dataField="cancellationNotes" editorType="dxTextArea" colSpan={2}>
                                    <Label text="Cancellation Notes" />
                                    <RequiredRule />
                                    <StringLengthRule max={500} />
                                </Item>
                            }

                            <EmptyItem colSpan={this.state.placement.statusID == 3 ? 2 : 1} />

                            <Item dataField="statusID" editorType="dxSelectBox" editorOptions={{
                                searchEnabled: true, dataSource: this.state.statuses, displayExpr: 'name', valueExpr: 'id',
                                readOnly: this.IsReadOnly() || this.state.placementId == '00000000-0000-0000-0000-000000000000'
                            }}>
                                <RequiredRule />
                                <Label text="Status" />
                            </Item>
                        </Item>

                        {this.state.placementId != '00000000-0000-0000-0000-000000000000' && this.state.hasAlternatePOC &&
                            <Item itemType='group' caption="Alternate POC/Sponsor" colCount={3}>
                                <Item dataField="alternatePOCName">
                                    <Label text="Name" />
                                    <RequiredRule />
                                    <StringLengthRule max={100} />
                                </Item>
                                <Item dataField="alternatePOCPhoneNumber">
                                    <Label text="Phone Number" />
                                    <RequiredRule />
                                    <StringLengthRule max={25} />
                            </Item>
                            <Item dataField="alternatePOCAlternatePhoneNumber">
                                <Label text="Alt. Phone Number" />
                                <StringLengthRule max={25} />
                            </Item>

                            <Item dataField="alternatePOCAddress" editorOptions={{ onValueChanged: this.POCInfoChanged }}>
                                    <Label text="Address" />
                                    <RequiredRule />
                                    <StringLengthRule max={200} />
                                </Item>
                            <Item dataField="alternatePOCCity" editorOptions={{ onValueChanged: this.POCInfoChanged }}>
                                    <Label text="City" />
                                    <RequiredRule />
                                    <StringLengthRule max={50} />
                                </Item>
                                <Item dataField="alternatePOCState" editorType="dxSelectBox" editorOptions={{
                                searchEnabled: true, dataSource: this.state.states, displayExpr: 'name', valueExpr: 'name', onValueChanged: this.POCInfoChanged
                                }}>
                                    <Label text="State" />
                                    <RequiredRule />
                            </Item>

                            <Item dataField="alternatePOCZIPCode" editorOptions={{ onValueChanged: this.POCInfoChanged }}>
                                    <Label text="ZIP Code" />
                                    <RequiredRule />
                                    <StringLengthRule max={10} />
                                </Item>
                                <Item dataField="alternatePOCLatitude" editorType="dxNumberBox">
                                    <Label text="Latitude" />
                                </Item>

                                <Item dataField="alternatePOCLongitude" editorType="dxNumberBox">
                                    <Label text="Longitude" />
                            </Item>

                            <EmptyItem />
                            <EmptyItem />
                            <ButtonItem buttonOptions={{ text: 'Calculate Latitude/Longitude', onClick: this.calculateGeolocation }} />
                            </Item>
                        }
                    </Form>
                    <br />

                    <div className="d-flex justify-content-between">
                        <div className="ms-3 me-auto">
                            {this.state.placementId != '00000000-0000-0000-0000-000000000000' &&
                                <Button text='View Contact Log' onClick={this.ViewContactLog} />
                            }
                        </div>
                        <div className="ms-auto me-3">
                            {!this.state.hasAlternatePOC && this.state.placementId != '00000000-0000-0000-0000-000000000000' &&
                                <Button disabled={this.IsReadOnly()} text='Add Alternate POC/Sponsor' onClick={this.AddAlternatePOC} />
                            }
                            {this.state.hasAlternatePOC &&
                                <Button disabled={this.IsReadOnly()} text='Remove Alternate POC/Sponsor' onClick={this.RemoveAlternatePOC} />
                            }
                        </div>
                    </div>
                    <br />
                    <div className="d-flex justify-content-between">
                        {(this.state.placementId != '00000000-0000-0000-0000-000000000000' && this.state.workOrderID) &&
                            <div className="ms-3 me-auto">
                                <Link to={`/workorder-resources/${this.state.workOrderID}`}><Button text='Return to Work Order' /></Link>
                            </div>
                        }
                        <div className="ms-auto me-3">
                            <Button disabled={this.IsReadOnly()} text='Save' onClick={this.SavePlacement} />&nbsp;
                            <Button text='Save and Add Another' onClick={this.SavePlacementAndCopy} visible={this.state.placementId != '00000000-0000-0000-0000-000000000000' && !this.IsReadOnly()} />
                        </div>
                    </div>
                </React.Fragment>
                <br />

                {this.state.placementId != '00000000-0000-0000-0000-000000000000' &&
                    <fieldset>
                        <h3>Customer Information</h3>
                        <hr />
                        <Button disabled={this.IsReadOnly()} icon="plus" text="Search/Add Customer" onClick={this.searchAddCustomer} />
                        <br />
                        <br />

                    <DataGrid dataSource={this.customDataSource} showBorders={true}
                            keyExpr="customerID" allowColumnResizing={true} ref={ref => this.customerListDataGrid = ref} onExporting={this.onExporting}>
                            <Export enabled={true} fileName={"customerInformation"} />
                            <Selection mode={this.selectionMode} selectAllMode="allPages" showCheckBoxesMode="always" deferred={true} />
                            <Editing allowDeleting={!this.IsReadOnly()} />

                            <Column dataField="aNum">
                                <Label text="A#" />
                            </Column>

                            <Column dataField="subjectID">
                                <Label text="Subject ID" />
                            </Column>

                            <Column dataField="admissionDate" format="MM/d/yyyy" dataType="date" caption="Admission Date">
                            </Column>

                            <Column dataField="approvalDate" format="MM/d/yyyy" dataType="date" caption="FFS Approval Date">
                            </Column>

                            <Column dataField="lastName" caption="Last Name">
                                <RequiredRule />
                                <StringLengthRule max={200} />
                            </Column>

                            <Column dataField="firstName">
                                <RequiredRule />
                                <StringLengthRule max={200} />
                                <Label text="First Name" />
                            </Column>

                            <Column dataField="middleName">
                                <StringLengthRule max={200} />
                                <Label text="Middle Name" />
                            </Column>

                            <Column dataField="dob" editorType="dxDateBox" format="MM/d/yyyy" dataType="date">
                                <RequiredRule />
                                <Label text="DOB" />
                            </Column>

                            <Column dataField="age" editorOptions={{ readOnly: true }}>
                                <Label text="Age" />
                            </Column>

                            <Column dataField="gender" editorType="dxSelectBox" editorOptions={{ searchEnabled: true }}>
                                <RequiredRule />
                                <Label text="Gender" />
                                <Lookup allowClearing={true} dataSource={this.state.genders} displayExpr="name" valueExpr="code" />
                            </Column>

                            <Column dataField="nationality" editorType="dxSelectBox" editorOptions={{ searchEnabled: true }}>
                                <RequiredRule />
                                <Label text="Nationality" />
                                <Lookup allowClearing={true} dataSource={this.state.nationalities} displayExpr="name" valueExpr="code" />
                            </Column>

                            <Column dataField="relationCode" editorType="dxSelectBox" editorOptions={{ searchEnabled: true }} visible={this.state.placement.transportUnitCode == 'UC'}>
                                <FormItem visible={this.state.placement.transportUnitCode == 'UC'} />
                                <Label text="Family Group Relation" />
                                <Lookup allowClearing={true} dataSource={this.state.relationCodes} displayExpr="name" valueExpr="code" />
                            </Column>

                            <Column dataField="title8ConversionDate" editorType="dxDateBox" format="MM/d/yyyy HH:mm" dataType="datetime">
                                <Label text="Title 8 Conversion" />
                            </Column>

                            <Column dataField="referralNotes" editorType="dxTextArea" visible={false}>
                                <FormItem colSpan={2} />
                                <StringLengthRule max={1000} />
                                <Label text="Referral Notes" />
                            </Column>

                            <Column dataField="isPregnant" itemType="dxCheckbox" visible={false}>
                                <Label text="Pregnancy" />
                            </Column>

                            <Column dataField="isInjury" itemType="dxCheckbox" visible={false}>
                                <Label text="Injury" />
                            </Column>

                            <Column dataField="isIllness" itemType="dxCheckbox" visible={false}>
                                <Label text="Illness" />
                            </Column>

                            <Column dataField="isOther" itemType="dxCheckbox" visible={false}>
                                <Label text="Other" />
                            </Column>

                            <Column dataField="summary" caption="Other Health Concerns" itemType="dxTextArea" visible={false}>
                                <Label text="Other Health Concerns" />
                                <StringLengthRule max={5000} />
                            </Column>
                       
                            <Column visible={!this.IsReadOnly()} type="buttons">
                                <GridButton
                                text="Edit"
                                    onClick={this.editCustomer}
                                />
                                <GridButton name="delete" visible={this.canDelete} onClick={this.deleteCustomer} />

                                <GridButton text="Changes" onClick={this.showCustomerPopUp} />

                            </Column>

                            <Summary>
                                <TotalItem
                                    cssClass="summaryRow"
                                    showInColumn="aNum"
                                    summaryType="count"
                                    alignment="left"
                                    displayFormat="Total Customers: {0}" />
                            </Summary>
                        </DataGrid>
                        <div>
                            <br/>
                            <Button text="Split Placement" type="normal" disabled={this.IsReadOnly()} onClick={this.splitPlacements} visible={this.selectionMode == 'multiple'} />
                        </div>
                    </fieldset>
                }
                <br />

                {this.state.placementId != '00000000-0000-0000-0000-000000000000' &&
                    <>
                        <h3>Transfer/Placement History</h3>
                        <hr />
                    <DataGrid dataSource={this.state.placementHistory} showBorders={true} allowColumnResizing={true} ref={ref => this.dataGrid = ref}
                            onExporting={this.onExporting}>
                            <Export enabled={true} fileName={"transferPlacementHistory"} />
                            <FilterRow visible={true} />
                            <Paging defaultPageSize={5} />
                            <Pager showPageSizeSelector={true}
                                allowedPageSizes={[5, 10, 20]}
                                showInfo={true} visible={true} />

                            <Column dataField="workOrderNumber" allowEditing={false} />
                            <Column dataField="workOrderName" allowEditing={false} cellRender={function (options) { return (<Link to={`/workorder-detail/${options.row.data.workOrderID}`}>{options.value}</Link>); }} />
                            <Column dataField="departureCityName" allowEditing={false} />
                            <Column dataField="destination" allowEditing={false} />
                            <Column dataField="status" caption="Work Order Status" />
                            <Column dataField="rosterStatus" caption="Roster Status" />
                            <Column dataField="reason" caption="Reason" />
                            <Column dataField="comments" caption="Comments" />
                        </DataGrid>
                        <br />
                    </>
                }
                <Popup visible={this.state.isAddingCustomer} onHiding={this.hideCustomerEdit} dragEnabled={false}
                    closeOnOutsideClick={false} showTitle={true} title="Customer Information">
                    <ScrollView>
                    <Form formData={this.state.customerData} colCount={2} ref={ref => this.editCustomerControl = ref}
                        onFieldDataChanged={this.customerFieldDataChanged}>
                        <Item dataField="aNum">
                            <Label text="A#" />
                        </Item>

                        <Item dataField="subjectID">
                            <Label text="Subject ID" />
                        </Item>

                        <Item dataField="lastName" caption="Last Name">
                            <RequiredRule />
                            <StringLengthRule max={200} />
                        </Item>

                        <Item dataField="firstName">
                            <RequiredRule />
                            <StringLengthRule max={200} />
                            <Label text="First Name" />
                        </Item>

                        <Item dataField="middleName">
                            <StringLengthRule max={200} />
                            <Label text="Middle Name" />
                        </Item>

                        <Item dataField="dob" editorType="dxDateBox" format="MM/d/yyyy" dataType="date">
                            <RequiredRule />
                            <Label text="DOB" />
                        </Item>

                        <Item dataField="age" editorOptions={{ readOnly: true }}>
                            <Label text="Age" />
                        </Item>

                        <Item dataField="gender" editorType="dxSelectBox" editorOptions={{ searchEnabled: true, allowClearing: true, dataSource: this.state.genders, displayExpr: 'name', valueExpr: 'code' }}>
                            <RequiredRule />
                            <Label text="Gender" />
                        </Item>

                        <Item dataField="nationality" editorType="dxSelectBox" editorOptions={{ searchEnabled: true, allowClearing: true, dataSource: this.state.nationalities, displayExpr: 'name', valueExpr: 'code' }}>
                            <RequiredRule />
                            <Label text="Nationality" />
                        </Item>

                        <Item dataField="relationCode" editorType="dxSelectBox" editorOptions={{ searchEnabled: true, allowClearing: true, dataSource: this.state.relationCodes, displayExpr: 'name', valueExpr: 'code' }}
                            visible={this.state.placement.transportUnitCode == 'UC'}>
                            <FormItem visible={this.state.placement.transportUnitCode == 'UC'} />
                            <Label text="Family Group Relation" />
                        </Item>

                        <Item dataField="title8ConversionDate" editorType="dxDateBox" format="MM/d/yyyy HH:mm" dataType="datetime">
                            <Label text="Title 8 Conversion" />
                        </Item>

                        <Item colSpan={2} dataField="referralNotes" editorType="dxTextArea">
                            <StringLengthRule max={1000} />
                            <Label text="Referral Notes" />
                        </Item>

                        <Item dataField="isPregnant" editorType="dxCheckBox">
                            <Label text="Pregnancy" />
                        </Item>

                        <Item dataField="isInjury" editorType="dxCheckBox">
                            <Label text="Injury" />
                        </Item>

                        <Item dataField="isIllness" editorType="dxCheckBox">
                            <Label text="Illness" />
                        </Item>

                        <Item dataField="isOther" editorType="dxCheckBox">
                            <Label text="Other" />
                        </Item>

                        <Item dataField="summary" caption="Other Health Concerns" itemType="dxTextArea">
                            <Label text="Other Health Concerns" />
                            <StringLengthRule max={5000} />
                        </Item>

                            <Item dataField="healthTagList" editorType="dxTagBox" editorOptions={{ applyValueMode: 'useButtons', showSelectionControls: true, dataSource: this.state.healthTags, displayExpr: 'tagName', valueExpr: 'tagCode' }}>
                            <Label text="Health Tags" />
                        </Item>

                        <Item dataField="admissionDate" editorType="dxDateBox" format="MM/d/yyyy" dataType="date">
                            <Label text="Admission Date" />
                        </Item>

                        <Item dataField="approvalDate" editorType="dxDateBox" format="MM/d/yyyy" dataType="date">
                            <Label text="FFS Approval Date" />
                        </Item>

                        <Item colSpan={2} dataField="potentialDuplicates">
                            <DataGrid dataSource={this.potentialDuplicatesArrayStore} showBorders={true}
                                keyExpr="customerID" allowColumnResizing={true} ref={ref => this.potentialDuplicatesDataGrid = ref}>

                                <Column dataField="aNum" caption="A#" />

                                <Column dataField="subjectID" caption="Subject ID" />

                                <Column dataField="lastName" caption="Last Name" />

                                <Column dataField="firstName" caption="First Name" />

                                <Column dataField="middleName" caption="Middle Name" />

                                <Column dataField="dob" format="MM/d/yyyy" dataType="date" caption="DOB" />

                                <Column dataField="age" caption="Age" />

                                <Column dataField="gender" caption="Gender" />

                                <Column dataField="nationality" caption="Nationality">
                                    <Lookup dataSource={this.state.nationalities} displayExpr="name" valueExpr="code" />
                                </Column>

                                <Column type="buttons">
                                    <GridButton
                                        text="Add this Customer"
                                        onClick={this.addExistingCustomer}
                                    />
                                </Column>
                            </DataGrid>
                        </Item>
                        </Form>
                        <br />
                        <div className="d-flex justify-content-end">
                            <Button text="Save" onClick={this.SaveCustomer} />
                            &nbsp;
                            <Button text="Cancel" onClick={this.hideCustomerEdit} />
                        </div>
                        <br />
                    </ScrollView>
                </Popup>

                <Popup visible={this.state.facilityPopupVisible} onHiding={this.hideFacility} dragEnabled={false}
                    closeOnOutsideClick={true} showTitle={true} title="Facility Information">
                    <ScrollView>
                        <Form formData={this.state.facility} readOnly={true}>
                            <Item itemType="group" colCount={3} colSpan={2} caption="Final Destination">
                                <Item dataField="name" >
                                    <Label text="Name" />
                                </Item>
                                <Item dataField="address1" >
                                    <Label text="Address" />
                                </Item>
                                <Item dataField="address2" >
                                    <Label text="Address 2" />
                                </Item>

                                <Item dataField="city" >
                                    <Label text="City" />
                                </Item>
                                <Item dataField="state" >
                                    <Label text="State" />
                                </Item>
                                <Item dataField="zipCode" >
                                    <Label text="Zip Code" />
                                </Item>

                                <Item dataField="shelter" >
                                    <Label text="Shelter" />
                                </Item>
                                <Item dataField="aorCode" >
                                    <Label text="AOR" />
                                </Item>
                                <Item dataField="note" >
                                    <Label text="Note" />
                                </Item>
                            </Item>
                            <Item itemType="group" caption="Destination POC 1" colCount={3} colSpan={2}>
                                <Item dataField="pocLastName" >
                                    <Label text="Last Name" />
                                </Item>
                                <Item dataField="pocFirstName" >
                                    <Label text="First Name" />
                                </Item>
                                <Item dataField="pocEmail" >
                                    <Label text="Email" />
                                </Item>

                                <Item dataField="pocPhone1" >
                                    <Label text="Primary Phone" />
                                </Item>
                                <Item dataField="pocPhone2" >
                                    <Label text="Phone 2" />
                                </Item>
                                <Item dataField="pocPhone3" >
                                    <Label text="Phone 3" />
                                </Item>
                            </Item>
                            <Item itemType="group" caption="Destination POC 2" colCount={3} colSpan={2}>
                                <Item dataField="poC1LastName" >
                                    <Label text="Last Name" />
                                </Item>
                                <Item dataField="poC1FirstName" >
                                    <Label text="First Name" />
                                </Item>
                                <Item dataField="poC1Email" >
                                    <Label text="Email" />
                                </Item>

                                <Item dataField="poC1Phone1" >
                                    <Label text="Primary Phone" />
                                </Item>
                                <Item dataField="poC1Phone2" >
                                    <Label text="Phone 2" />
                                </Item>
                                <Item dataField="poC1Phone3" >
                                    <Label text="Phone 3" />
                                </Item>
                            </Item>
                            <Item itemType="group" caption="Destination POC 3" colCount={3} colSpan={2}>
                                <Item dataField="poC2LastName" >
                                    <Label text="Last Name" />
                                </Item>
                                <Item dataField="poC2FirstName" >
                                    <Label text="First Name" />
                                </Item>
                                <Item dataField="poC2Email" >
                                    <Label text="Email" />
                                </Item>

                                <Item dataField="poC2Phone1" >
                                    <Label text="Primary Phone" />
                                </Item>
                                <Item dataField="poC2Phone2" >
                                    <Label text="Phone 2" />
                                </Item>
                                <Item dataField="poC2Phone3" >
                                    <Label text="Phone 3" />
                                </Item>
                            </Item>
                        </Form>
                    </ScrollView>
                </Popup>

                <Popup visible={this.state.isViewingContactLog} onHiding={this.HideContactLog} dragEnabled={false}
                    closeOnOutsideClick={true} showTitle={true} title="Contact Log" width={700} height={500}>

                    <DataGrid dataSource={this.contactLogDataSource} showBorders={true} allowColumnResizing={true} ref={ref => this.contactLogDataGrid = ref} onRowUpdating={this.onRowUpdating}>
                        <Editing mode="popup" allowAdding={!this.IsReadOnly()} allowUpdating={!this.IsReadOnly()} allowDeleting={this.allowContactDeleting} />
                        <FilterRow visible={true} />
                        <Paging defaultPageSize={5} />
                        <Pager showPageSizeSelector={true}
                            allowedPageSizes={[5, 10, 20]}
                            showInfo={true} visible={true} />

                        <Column dataField="contactDateTime" caption="Contact Time" dataType="datetime" format="MM/dd/yy, HH:mm">
                            <RequiredRule />
                        </Column>
                        <Column dataField="notes" />
                        <Column dataField="contactUser">
                            <FormItem visible={false} />
                        </Column>
                    </DataGrid>
                </Popup>

                <Popup visible={this.state.isViewingCustomer} onHiding={this.hideCustomerPopUp} dragEnabled={false}
                    closeOnOutsideClick={false} showTitle={true} title={this.state.HistoryHeader}>
                    <ScrollView>
                        <DataGrid dataSource={this.state.customerHistory}
                            showBorders={true} allowColumnResizing={true}>

                            <FilterRow visible={true} />

                            <Column dataField="createDate" caption="Change Date/Time" dataType="datetime" format="MM/dd/yy, HH:mm" />
                            <Column dataField="createUser" caption="Employee No." />
                            <Column dataField="updateField" caption="Field Updated" />
                            <Column dataField="updateType" caption="Change Type" />
                            <Column dataField="oldValue" caption="Old Value" />
                            <Column dataField="newValue" caption="New Value" />

                        </DataGrid>
                    </ScrollView>
                </Popup>

                <LoadPanel visible={this.state.loading} />
            </div>
        );
    }
}

export default withParams(PlacementEditContainer);