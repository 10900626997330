
import React from 'react';
import CustomStore from 'devextreme/data/custom_store';
import DataGrid, { FilterRow, Column, Pager, Paging, Export } from 'devextreme-react/data-grid';
import { Link } from 'react-router-dom';
import Button from 'devextreme-react/button';
import BaseComponent, { FetchAPIPromise } from '../BaseComponent';
import { CheckBox } from 'devextreme-react/check-box';
import TextBox from 'devextreme-react/text-box';

export class WorkOrderListContainer extends BaseComponent {
    constructor(props) {
        super(props);

        this.myRef = React.createRef();

        this.state = {
            filter: {
                open: true,
                active: true,
                complete: false,
                cancelled: false,
                text: ""
            }
        };

        this.refreshPageInterval = 60;
    }

    componentDidMount = async () => {
        super.componentDidMount();
        this.interval = setInterval(() => {
            this.dataGrid.instance.refresh();
        }, this.refreshPageInterval * 1000);

        this.fetchData();
    }

    componentWillUnmount = async () => {
        clearInterval(this.interval);
    }

    GetComponentPageName = () => {
        return "Work Order List";
    }

    customDataSource = new CustomStore({
        key: 'id',
        onLoading: (loadOptions) => {
            console.log('Loading', loadOptions);

            loadOptions.userData["open"] = this.state.filter.open;
            loadOptions.userData["active"] = this.state.filter.active;
            loadOptions.userData["complete"] = this.state.filter.complete;
            loadOptions.userData["cancelled"] = this.state.filter.cancelled;
            loadOptions.userData["text"] = this.state.filter.text;
        },
        load: (loadOptions) => {
            console.log('Load', loadOptions);

            var queryString = '';
            var queryOptions = Object.keys(loadOptions.userData);

            console.log(queryOptions);

            for (var i = 0; i < queryOptions.length; i++) {

                if (queryString) {
                    queryString += '&'
                }

                queryString += queryOptions[i] + '=' + loadOptions.userData[queryOptions[i]];
            }

            console.log(queryString);

            return FetchAPIPromise('WorkOrder', queryString);
        }
    });

    handleChange = (event) => {
        console.log(event);
        this.setState(
            (state) => ({
                filter: {
                    ...state.filter,
                    [event.element.id]: !state.filter[event.element.id]
                }
            })
        );
    };

    handleTextChange = async (event) => {
        await this.setState(
            (prevState) => ({
                filter: {
                    ...prevState.filter,
                    text: event.value
                }
            })
        );
    }

    search = () => {
        this.dataGrid.instance.refresh();
    }

    render() {
        return (

            <div className="container-fluid">
                <h1>Work Order List</h1>

                <div className="d-flex justify-content-between">
                    <div className="d-flex me-auto align-items-center">
                        Work Order Status:{' '}&nbsp;
                        <CheckBox value={this.state.filter.open} id="open" text="Open" onValueChanged={this.handleChange} /> &nbsp;
                        <CheckBox value={this.state.filter.active} id="active" text="Active" onValueChanged={this.handleChange} /> &nbsp;
                        <CheckBox value={this.state.filter.complete} id="complete" text="Completed" onValueChanged={this.handleChange} />&nbsp;
                        <CheckBox value={this.state.filter.cancelled} id="cancelled" text="Cancelled" onValueChanged={this.handleChange} />&nbsp;&nbsp;&nbsp;
                        <TextBox value={this.state.filter.text} id="text" width={200} placeholder="Search by Work Order #" onValueChanged={this.handleTextChange} />&nbsp;&nbsp;&nbsp;
                        <Button text="Search" icon="search" onClick={this.search} />
                    </div>

                    <div className="ms-auto">
                        {(this.IsReadOnly())
                            ? <Button disabled={true} icon="plus" text="Add Work Order" />
                            : <Link to="/create-workorder/Regular"><Button icon="plus" text="Add Work Order" /></Link>
                        }
                        &nbsp;
                        {(this.IsReadOnly())
                            ? <Button disabled={true} icon="plus" text="Add Support Work Order" />
                            : <Link to="/create-workorder/Support"><Button icon="plus" text="Add Support Work Order" /></Link>
                        }
                    </div>
                </div>

                <br />
                <DataGrid dataSource={this.customDataSource}
                    showBorders={true} allowColumnResizing={true}
                    ref={ref => this.dataGrid = ref} onExporting={this.onExporting}>
                    <Export enabled={true} fileName={"workOrderList"} />
                    <FilterRow visible={true} />
                    <Paging defaultPageSize={20} />
                    <Pager showPageSizeSelector={true}
                        allowedPageSizes={[5, 10, 20]}
                        showInfo={true} />
                    <Column caption="Number" dataField="number" dataType="string" />
                    <Column caption="Work Order Name" dataField="name" cellRender={function (options) { return (<Link to={`/workorder-detail/${options.row.data.id}`}>{options.value}</Link>); }} />
                    <Column caption="Start Date" dataField="planStartDate" dataType="datetime" format="MM/dd/yy, HH:mm" />
                    <Column caption="End Date" dataField="planEndDate" dataType="datetime" format="MM/dd/yy, HH:mm" />
                    <Column caption="Departure City" dataField="departureCity" />
                    <Column caption="Destination City" dataField="destinationCity" />
                    <Column caption="Status" dataField="status" />
                    <Column caption="Transport Type" dataField="transportMethod" />
                    <Column caption="Transport Method" dataField="transportType" />
                    <Column caption="Unit" dataField="transportUnit" />
                    <Column caption="Type" dataField="workOrderType" />
                    <Column caption="LUU" dataField="lastUpdateUser" />
                    <Column caption="" allowFiltering={false} allowSorting={false} allowResizing={false} dataField="id"
                        width={320}
                        cellRender={
                            function (options) {
                                return (<><Link to={`/workorder-milestones/${options.row.data.id}`}>
                                    <Button text="Milestones" /></Link>&nbsp;
                                    <Link to={`/workorder-resources/${options.row.data.id}`}><Button text="Resources" /></Link>&nbsp;
                                    <Link to={`/trip-status/${options.row.data.id}`}><Button text="Trip Overview" /></Link>
                                </>);
                            }
                        } />
                </DataGrid>

                <br />
            </div>
        );
    }
}
