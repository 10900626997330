import * as $ from 'jquery';
import React from 'react';
import BaseComponent from '../BaseComponent.js'
import CustomStore from 'devextreme/data/custom_store';
import DataGrid, { Column, Lookup, MasterDetail, Editing, Selection, Summary, TotalItem, Paging, Export } from 'devextreme-react/data-grid';
import Button from 'devextreme-react/button';
import { LoadPanel } from 'devextreme-react/load-panel';
import { alert, confirm } from 'devextreme/ui/dialog';
import DataGridNormal from "devextreme/ui/data_grid";
import { Popup } from 'devextreme-react/popup';
import { ScrollView } from 'devextreme-react/scroll-view';
import Form, { Item, Label, EmptyItem } from 'devextreme-react/form';

var trafficTypes = [];
var transportMethods = [];
var authorityCodes = [];

export function getAuthorityCodes (options) {
    console.log(options);

    if (options.data) {
        return authorityCodes.filter(m => m.transportUnitCode === options.data.transportUnit && m.isActive);
    }
    else {
        return authorityCodes.filter(m => m.isActive);
    }
}

export function getFilteredMethods (options) {
    if (options.data) {
        return transportMethods.filter(m => m.trafficTypeCode === options.data.trafficTypeCode && m.isActive);
    }
    else {
        return transportMethods.filter(m => m.isActive);
    }
}

// TODO: Default(s) for traffic type, transport method, authority code?
export class PlacementStagingContainer extends BaseComponent {
    constructor(props) {
        super(props);

        this.myRef = React.createRef();

        this.acceptAndCreateRender = this.acceptAndCreateRender.bind(this);
        this.acceptAndCreateStagingRender = this.acceptAndCreateStagingRender.bind(this);

        this.state = {
            loading: false,
            hasResults: false,
            workOrderOptions: null,
            twoStagedWorkOrders: null,

            isConfirmingCreation: false,

            facilities: [],
            pickupLocations: [],
            movementTypes: [],

            creationConfirmationOptions: {
                type: null,
                stagedWorkOrder: {},
                stagedCustomers: []
            },

            filters: {
                pickupLocationId: null,
                destinationLocationId: null,
                movementTypeCode: null,

                includeStagingMovements: true,
                includeSimpleMovements: true
            }
        };
    }

    componentDidMount = async () => {
        super.componentDidMount();
        this.fetchData();
    }

    GetComponentPageName = () => {
        return ("Stage Placements");
    }

    GetDropDownData = async () => {
        const trafficTypeData = await this.FetchAPI('DictionaryTrafficType');

        const transportMethodData = await this.FetchAPI('DictionaryTransportMethod');

        const movementTypeData = await this.FetchAPI('DictionaryMovementType');

        const authorityCodeData = await this.FetchAPI('DictionaryAuthority');

        var facilityData = await this.FetchAPI('Facility/GetForSelection');

        var pickupAgencies = await this.FetchAPI('PickupLocation/GetPickupAgencies');

        trafficTypes = trafficTypeData;
        transportMethods = transportMethodData;
        authorityCodes = authorityCodeData;

        this.setState({
            facilities: facilityData,
            pickupLocations: pickupAgencies,
            movementTypes: movementTypeData
        });
    }

    stagePlacements = async (e) => {
        this.setState({
            loading: true
        });

        try {
            const proposedNewWorkOrders = await this.PostAPI('Placement/StagePlacements', this.state.filters);

            console.log(proposedNewWorkOrders);

            var sortedWorkOrders = proposedNewWorkOrders.simpleStagedWorkOrders;

            if (proposedNewWorkOrders.simpleStagedWorkOrders) {
                sortedWorkOrders = proposedNewWorkOrders.simpleStagedWorkOrders.sort(function (workOrder1, workOrder2) {

                    // Calculate count of customers and placements
                    const workOrder1CustomerCount = workOrder1.placementList.reduce((a, b) => a + (b.customerList ? b.customerList.length : 0), 0);
                    const workOrder2CustomerCount = workOrder2.placementList.reduce((a, b) => a + (b.customerList ? b.customerList.length : 0), 0);

                    //console.log('WorkOrder1 Customer Count', workOrder1CustomerCount)
                    //console.log('WorkOrder2 Customer Count', workOrder2CustomerCount)

                    // If the first item has a higher number, move it down
                    // If the first item has a lower number, move it up
                    if (workOrder1CustomerCount > workOrder2CustomerCount) return -1;
                    if (workOrder1CustomerCount < workOrder2CustomerCount) return 1;

                    return 0;
                });
            }

            //console.log(sortedWorkOrders);

            this.setState({
                hasResults: true,
                workOrderOptions: sortedWorkOrders,
                twoStagedWorkOrders: proposedNewWorkOrders.twoStagedWorkOrders
            });

            //this.dataGrid.instance.refresh();
        }
        finally {
            this.setState({
                loading: false
            });
        }
    }

    onSelectBoxValueChanged(options) {
        console.log(options);
        console.log(options.value);

        //data.keyFieldName is a key field specified using dxDataGrid.keyExpr or YourDataStore.key option  
        //options.component.getDataSource().store().update(options.data.keyFieldName, { productTitle: e.value });
        options.component.getDataSource().store().update(options.data.placementId, { selectedAirportId: options.value });
        options.component.refresh();
    }

    acceptAndCreateStaging = async (e) => {
        //console.log(e);

        // TODO: Verify regular work order fields
        if (!e.row.data.planEndDate || !e.row.data.planStartDate || !e.row.data.trafficTypeCode || !e.row.data.transportMethodCode) {
            alert('Plan Start Date, Plan End Date, Traffic Type, and Transport Method are required.');
        }
        else {
            //alert('Verifying customer selections');

            //console.log(e.row);

            var selectedRowKeys = [];

            for (var i = 0; i < e.row.data.regularWorkOrderList.length; i++) {
                var regularWorkOrder = e.row.data.regularWorkOrderList[i];

                for (var j = 0; j < regularWorkOrder.placementList.length; j++) {

                    var placement = regularWorkOrder.placementList[j];
                    var id = placement.workOrderId + '\|' + placement.placementId;

                    // Find the grid reference
                    let element = document.getElementById(id);

                    //console.log(element);

                    let instance = DataGridNormal.getInstance(element);

                    //console.log(instance);

                    var s = await instance.getSelectedRowsData();

                    //console.log(s);

                    selectedRowKeys = selectedRowKeys.concat(s);

                    // If we are taking some of these customers, check the validation
                    if (s && s.length > 0 && (!regularWorkOrder.planEndDate || !regularWorkOrder.planStartDate || !regularWorkOrder.trafficTypeCode || !regularWorkOrder.transportMethodCode)) {
                        alert('Plan Start Date, Plan End Date, Traffic Type, and Transport Method are required.');
                        return;
                    }
                }
            }

            //console.log(selectedRowKeys);

            // Verify at least one customer is selected to include
            if (selectedRowKeys.length == 0) {
                alert('Must select at least one Customer from the below Placements.');
            }
            else {

                var filteredStagedWorkOrder = e.row.data;
                filteredStagedWorkOrder.regularWorkOrderList = e.row.data.regularWorkOrderList.filter(x => x.placementList.some(y => y.customerList.some(z => selectedRowKeys.some(f => f.customerID == z.customerID))));

                // TODO: Confirm creation
                await this.setState({
                    isConfirmingCreation: true,

                    creationConfirmationOptions: {
                        type: 'STAGING',
                        stagedWorkOrder: filteredStagedWorkOrder,
                        stagedCustomers: selectedRowKeys
                    }
                });
            }
        }
    }

    acceptAndCreate = async (e) => {
        //console.log(e);

        if (!e.row.data.planEndDate || !e.row.data.planStartDate || !e.row.data.trafficTypeCode || !e.row.data.transportMethodCode) {
            alert('Plan Start Date, Plan End Date, Traffic Type, and Transport Method are required.');
        }
        else
        {
            var selectedRowKeys = [];

            for (var j = 0; j < e.row.data.placementList.length; j++) {

                var placement = e.row.data.placementList[j];
                var id = placement.workOrderId + '\|' + placement.placementId;

                // Find the grid reference
                let element = document.getElementById(id);

                //console.log(element);

                let instance = DataGridNormal.getInstance(element);

                //console.log(instance);

                var s = await instance.getSelectedRowsData();

                console.log(s);

                selectedRowKeys = selectedRowKeys.concat(s);
            }

            //console.log(selectedRowKeys);

            // Verify at least one customer is selected to include
            if (selectedRowKeys.length == 0) {
                alert('Must select at least one Customer from the below Placements.');
            }
            else {

                // Confirm creation
                await this.setState({
                    isConfirmingCreation: true,

                    creationConfirmationOptions: {
                        type: 'SIMPLE',
                        stagedWorkOrder: e.row.data,
                        stagedCustomers: selectedRowKeys
                    }
                });
            }
        }
    }

    setStateValue(rowData, value, currentRowData) {
        //console.log(value);

        rowData.transportMethodCode = null;

        // Set conditional required field state
        this.defaultSetCellValue(rowData, value);
    }

    acceptAndCreateStagingRender(cell) {
        let acceptAndCreateStaging = this.acceptAndCreateStaging.bind(this, cell);

        return <Button text="Accept & Create" type="success" onClick={acceptAndCreateStaging} />
    }

    acceptAndCreateRender(cell) {
        let acceptAndCreate = this.acceptAndCreate.bind(this, cell);

        return <Button text="Accept & Create" type="success" onClick={acceptAndCreate} />
    }

    HideConfirmationDialog = async () => {
        await this.setState({
            isConfirmingCreation: false,
            creationConfirmationOptions: {
                type: null,
                stagedWorkOrder: {},
                stagedCustomers: []
            }
        });
    }

    StageAndCreateWorkOrder = async () => {

        if (this.state.creationConfirmationOptions.type == 'SIMPLE') {
            //console.log(this.state.creationConfirmationOptions.stagedWorkOrder);
            const result = await this.PostAPI('WorkOrder/CreateSimpleWorkOrder', { simpleWorkOrder: this.state.creationConfirmationOptions.stagedWorkOrder, stagedCustomers: this.state.creationConfirmationOptions.stagedCustomers });

            console.log(result);

            if (result.status == 1) {
                await alert('Work Order #' + result.workOrderNumber + ' created.');

                this.setState({
                    isConfirmingCreation: false,
                    creationConfirmationOptions: {
                        type: null,
                        stagedWorkOrder: {},
                        stagedCustomers: []
                    }
                });

                this.stagePlacements();
            }
            //else if (result.status == 0) {
            //    alert("This Customer is already used in this Placement's Work Orders and cannot be added.")
            //}
            else {
                console.log(result);
                alert('Failed. Please try again later.');
            }
        }
        else {
            //console.log(this.state.creationConfirmationOptions.stagedWorkOrder);
            const result = await this.PostAPI('WorkOrder/CreateStagingWorkOrder', { stagedWorkOrder: this.state.creationConfirmationOptions.stagedWorkOrder, stagedCustomers: this.state.creationConfirmationOptions.stagedCustomers });

            console.log(result);

            if (result.status == 1) {
                await alert('Work Order #' + result.workOrderNumber + ' created.');

                this.setState({
                    isConfirmingCreation: false,
                    creationConfirmationOptions: {
                        type: null,
                        stagedWorkOrder: {},
                        stagedCustomers: []
                    }
                });

                this.stagePlacements();
            }
            //else if (result.status == 0) {
            //    alert("This Customer is already used in this Placement's Work Orders and cannot be added.")
            //}
            else {
                console.log(result);
                alert('Failed. Please try again later.');
            }
        }
    }

    render() {
        return (
            <div className="container">
                <h1>Stage Placements</h1>

                <Form ref={ref => this.filterForm = ref}
                    formData={this.state.filters} colCount={2} onFieldDataChanged={this.fieldDataChanged}>
                    <Item editorType="dxSelectBox" dataField="pickupLocationId" editorOptions={{ dataSource: this.state.pickupLocations, displayExpr: 'name', valueExpr: 'id', searchEnabled: true, showClearButton: true }}>
                        <Label text="Pickup Location" />
                    </Item>

                    <Item editorType="dxSelectBox" dataField="destinationLocationId" editorOptions={{ dataSource: this.state.facilities, displayExpr: 'name', valueExpr: 'id', searchEnabled: true, showClearButton: true }}>
                        <Label text="Destination Location" />
                    </Item>

                    <Item dataField="movementTypeCode" editorType="dxSelectBox" editorOptions={{ dataSource: this.state.movementTypes, displayExpr: 'name', valueExpr: 'code', searchEnabled: true, showClearButton: true }}>
                        <Label text="Movement Type" />
                    </Item>

                    <EmptyItem />

                    <Item dataField="includeStagingMovements" />

                    <Item dataField="includeSimpleMovements" />
                </Form>

                <div className="row">
                    <div className="d-flex justify-content-end">
                        <Button text="Search for Work Orders to Stage" onClick={this.stagePlacements} />
                    </div>
                </div>

                <br />

                <div>
                    {this.state.hasResults &&
                        <>
                        {this.state.twoStagedWorkOrders &&
                            <>
                                <h4>Staging Movements</h4>

                                {this.state.twoStagedWorkOrders.length > 0 ? this.state.twoStagedWorkOrders.map((workorder) => (
                                    <>
                                        <DataGrid dataSource={[workorder]} className="w-auto p-3"
                                            allowColumnResizing={true} showBorders={true} onExporting={this.onExporting}>
                                            <Export enabled={true} fileName={"stagingMovements"} />
                                            <Editing mode="cell" allowAdding={false} allowDeleting={false} allowUpdating={!this.IsReadOnly()} />

                                            <Column visible={!this.IsReadOnly()} caption="" allowFiltering={false} allowSorting={false} allowResizing={false} width="175"
                                                cellRender={this.acceptAndCreateStagingRender} />
                                            <Column caption="New Work Order Name" dataField="workOrderName" width="*" allowEditing={false} />
                                            <Column caption="Transport Unit" dataField="transportUnitName" allowEditing={false} />
                                            <Column dataField="planStartDate" dataType="datetime" format="MM/dd/yy HH:mm">
                                            </Column>
                                            <Column dataField="planEndDate" dataType="datetime" format="MM/dd/yy HH:mm">
                                            </Column>
                                            <Column caption="Departure City" dataField="departureCityName" allowEditing={false} />
                                            <Column caption="Destination City" dataField="destinationCityName" allowEditing={false} />
                                            <Column caption="Traffic Type" dataField="trafficTypeCode" setCellValue={this.setStateValue}>
                                                <Lookup dataSource={trafficTypes} displayExpr='name' valueExpr='code' />
                                            </Column>
                                            <Column caption="Transport Method" dataField="transportMethodCode">
                                                <Lookup dataSource={getFilteredMethods} displayExpr='name' valueExpr='code' />
                                            </Column>

                                            <MasterDetail enabled={true} autoExpandAll={true} component={SubWorkOrders} />
                                        </DataGrid>
                                        <br />
                                    </>
                                ))
                                :
                                <p>No Staging Movements Found to Create</p>}
                            </>
                        }

                        {this.state.workOrderOptions &&
                            <>
                                <h4>Simple Movements</h4>

                                {this.state.workOrderOptions.length > 0 ? this.state.workOrderOptions.map((workorder) => (
                                    <>
                                        <DataGrid dataSource={[workorder]} className="w-auto p-3"
                                            allowColumnResizing={true} showBorders={true} onExporting={this.onExporting}>
                                            <Export enabled={false} fileName={"simpleMovements"} />
                                            <Editing mode="cell" allowAdding={false} allowDeleting={false} allowUpdating={true} />

                                            <Column visible={!this.IsReadOnly()} caption="" allowFiltering={false} allowSorting={false} allowResizing={false} width="175"
                                                cellRender={this.acceptAndCreateRender} />
                                            <Column caption="New Work Order Name" dataField="workOrderName" width="*" allowEditing={false} />
                                            <Column caption="Transport Unit" dataField="transportUnitName" allowEditing={false} />
                                            <Column dataField="planStartDate" dataType="datetime" format="MM/dd/yy HH:mm">
                                            </Column>
                                            <Column dataField="planEndDate" dataType="datetime" format="MM/dd/yy HH:mm">
                                            </Column>
                                            <Column caption="Departure City" dataField="departureCityName" allowEditing={false} />
                                            <Column caption="Destination City" dataField="destinationCityName" allowEditing={false} />
                                            <Column caption="Traffic Type" dataField="trafficTypeCode" setCellValue={this.setStateValue}>
                                                <Lookup dataSource={trafficTypes} displayExpr='name' valueExpr='code' />
                                            </Column>
                                            <Column caption="Transport Method" dataField="transportMethodCode">
                                                <Lookup dataSource={getFilteredMethods} displayExpr='name' valueExpr='code' />
                                            </Column>

                                            <MasterDetail enabled={true} autoExpandAll={true} component={Placements} />
                                        </DataGrid>
                                        <br />
                                    </>
                                )) :
                                <p>No Simple Movements Found to Create</p>}
                            </>
                        }
                        </>
                    }
                </div>

                <Popup visible={this.state.isConfirmingCreation} onHiding={this.HideConfirmationDialog} dragEnabled={false}
                    closeOnOutsideClick={true} showTitle={true} title="Confirm Work Order" width={700} height={500}>
                    <ScrollView>

                        <p>Are you sure you want to create the following Work Order?</p>
                        <br />

                        <Form
                            id="form"
                            formData={this.state.creationConfirmationOptions.stagedWorkOrder}
                            colCount={2}>
                            <Item dataField="departureCityName" editorOptions={{ readOnly: true }}>
                                <Label text="Departure City" />
                            </Item>
                            <Item dataField="destinationCityName" editorOptions={{ readOnly: true }}>
                                <Label text="Destination City" />
                            </Item>

                            <Item dataField="trafficTypeCode" editorType="dxSelectBox" editorOptions={{ readOnly: true, dataSource: trafficTypes, valueExpr: 'code', displayExpr: 'name' }}>
                                <Label text="Traffic Type" />
                            </Item>
                            <Item dataField="transportMethodCode" editorType="dxSelectBox" editorOptions={{ readOnly: true, dataSource: transportMethods, valueExpr: 'code', displayExpr: 'name' }}>
                                <Label text="Transport Method" />
                            </Item>
                        </Form>
                        <br />

                        <DataGrid dataSource={this.state.creationConfirmationOptions.stagedCustomers} showBorders={true} allowColumnResizing={true}
                            ref={ref => this.contactLogDataGrid = ref}>

                            <Column caption="A#/Subject ID" dataField="comboIDDisplay" />
                            <Column caption="First Name" dataField="firstName" />
                            <Column caption="Middle Name" dataField="middleName" />
                            <Column caption="Last Name" dataField="lastName" />
                            <Column caption="Gender" dataField="gender" />
                            <Column caption="DOB" dataField="dob" dataType="datetime" format="MM/dd/yyyy" />

                            <Summary>
                                <TotalItem
                                    cssClass="summaryRow"
                                    showInColumn="comboIDDisplay"
                                    summaryType="count"
                                    alignment="left"
                                    displayFormat="Total Customers: {0}" />
                            </Summary>
                        </DataGrid>
                        <br />

                        <div className="d-flex justify-content-end">
                            <Button text="Save" onClick={this.StageAndCreateWorkOrder} />
                            &nbsp;
                            <Button text="Cancel" onClick={this.HideConfirmationDialog} />
                        </div>
                    </ScrollView>
                </Popup>

                <LoadPanel visible={this.state.loading} />
                <br />
                <br />
            </div>
        );

        function renderCustomItem(data) {
            return <div>{data}</div>;
        }
    }
}

class SubWorkOrders extends BaseComponent {
    constructor(props) {
         //console.log('Detail Props', props);
        super(props);
        this.dataSource = props.data.row.data.regularWorkOrderList;
        this.placementShortName = props.data.row.data.placementShortName;
    }

    setStateValue(rowData, value, currentRowData) {
        rowData.transportMethodCode = null;
        this.defaultSetCellValue(rowData, value);
    }

    render() {
        return (
            <DataGrid dataSource={this.dataSource} showBorders={true} allowColumnResizing={true} onExporting={this.onExporting}>
                <Export enabled={true} fileName={"subWorkOrder" + this.placementShortName} />
                <Editing mode="cell" allowAdding={false} allowDeleting={false} allowUpdating={!this.IsReadOnly()} />

                <Column caption="New Work Order Name" dataField="workOrderName" width="*" allowEditing={false} />
                <Column caption="Transport Unit" dataField="transportUnitName" allowEditing={false} />
                <Column dataField="planStartDate" dataType="datetime" format="MM/dd/yy HH:mm">
                </Column>
                <Column dataField="planEndDate" dataType="datetime" format="MM/dd/yy HH:mm">
                </Column>
                <Column caption="Departure City" dataField="departureCityName" allowEditing={false} />
                <Column caption="Destination City" dataField="destinationCityName" allowEditing={false} />
                <Column caption="Traffic Type" dataField="trafficTypeCode" setCellValue={this.setStateValue}>
                    <Lookup dataSource={trafficTypes} displayExpr='name' valueExpr='code' />
                </Column>
                <Column caption="Transport Method" dataField="transportMethodCode">
                    <Lookup dataSource={getFilteredMethods} displayExpr='name' valueExpr='code' />
                </Column>

                <MasterDetail enabled={true} autoExpandAll={true} component={Placements} />
            </DataGrid>
        );
    }
}

class Placements extends BaseComponent {
    constructor(props) {
        //console.log('Detail Props', props);
        super(props);
        this.dataSource = props.data.row.data.placementList;
        this.workOrderName = props.data.row.data.workOrderName;
    }

    render() {
        return (
            <DataGrid dataSource={this.dataSource} showBorders={true} allowColumnResizing={true} onExporting={this.onExporting}>
                <Export enabled={true} fileName={"placements" + this.workOrderName} />
                <Editing mode="cell" allowAdding={false} allowDeleting={false} allowUpdating={!this.IsReadOnly()} />
                <Paging enabled={false} />

                <Column caption="Name" dataField="placementName" />
                <Column caption="Movement Type" dataField="movementTypeCode" />
                <Column caption="Pickup Location" dataField="pickupLocation" />
                <Column caption="Dropoff Location" dataField="dropoffLocation" />
                <Column caption="Authority Code" dataField="authorityCode">
                    <Lookup dataSource={getAuthorityCodes} displayExpr='name' valueExpr='code' />
                </Column>

                <MasterDetail enabled={true} autoExpandAll={true} component={Customers} />
            </DataGrid>
        );
    }
}

class Customers extends React.Component {
    constructor(props) {
        //console.log('Detail Props', props);
        super(props);
        this.dataSource = props.data.row.data.customerList;
        this.id = props.data.row.data.workOrderId + '\|' + props.data.row.data.placementId;
        this.placementName = props.data.row.data.placementName;
        //console.log(this.id);
    }

    render() {
        return (
            <DataGrid dataSource={this.dataSource} showBorders={true} allowColumnResizing={true}
                id={this.id} onExporting={this.onExporting}>
                <Export enabled={true} fileName={"customers" + this.placementName} /> <Paging enabled={false} />
                <Selection mode="multiple" allowSelectAll={true} showCheckBoxesMode="always" />

                <Column caption="A#/Subject ID" dataField="comboIDDisplay" />
                <Column caption="First Name" dataField="firstName" />
                <Column caption="Middle Name" dataField="middleName" />
                <Column caption="Last Name" dataField="lastName" />
                <Column caption="Gender" dataField="gender" />
                <Column caption="DOB" dataField="dob" dataType="datetime" format="MM/dd/yyyy" />
            </DataGrid>
        );
    }
}