import React, { useRef } from 'react';
import CustomStore from 'devextreme/data/custom_store';
import BaseComponent, { FetchAPIPromise, PostAPIPromise, DeleteAPIPromise, withParams } from '../BaseComponent';
import Accordion from 'devextreme-react/accordion';
import DataGrid, { FilterRow, Column, Pager, Paging, MasterDetail, Editing, Form, Label, RequiredRule, Export } from 'devextreme-react/data-grid';
import { Item } from 'devextreme-react/form';
import { Form as NormalForm, Item as FormItem, Label as FormLabel } from 'devextreme-react/form';
import { CheckBox } from 'devextreme-react/check-box';
import * as moment from 'moment';
import MilestoneDetail from './MilestoneDetail';
import * as $ from 'jquery'
import Button from 'devextreme-react/button';
import { Popup } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import { LoadPanel } from 'devextreme-react/load-panel';
import DataGridNormal from "devextreme/ui/data_grid";

// Below is needed by the Form control to "import" the appropriate control
import TextArea from 'devextreme-react/text-area';

const MilestoneStageTitle = (data) => {
    return (
        <h6>{data.name}</h6>
    );
}

export class WorkOrderMilestonesContainer extends BaseComponent {
    static displayName = WorkOrderMilestonesContainer.name;

    constructor(props) {
        super(props);
        this.MilestoneStageItemRender = this.MilestoneStageItemRender.bind(this);
        this.MilestoneItemRender = this.MilestoneItemRender.bind(this);

        this.state = {
            workOrderId: '',
            workOrder: {},
            employeeIds: [],
            customerIds: [],
            controlList: [],
            controlOptionList: [],
            controlDependenciesList: [],
            customerMappingInfo: [],

            milestoneStages: [],
            popupVisible: false,
            IncludeAllStages: false,
            loading: false
        };
    }

    MilestoneItemRender = (data, workOrder) => {
        //console.log('Milestone Render', data);
        //console.log('Work Order', workOrder);

        var store = new CustomStore({
            key: 'id',
            id: "CustomStore" + data.milestoneId,
            load: (loadOptions) => {
                //console.log('Load', loadOptions);

                if (this.state.workOrderId) {
                    return FetchAPIPromise('WorkOrderMilestone/GetMilestoneItems?workOrderId=' + this.state.workOrderId + '&milestoneId=' + data.milestoneId + '&stageCode=' + data.stageCode + '&stageId=' + (data.stageId ? data.stageId : ''));
                }
            },
            insert: (values) => {

                values.values = [];

                this.state.controlList.filter(item => item.milestoneId == data.milestoneId && item.controlType != 'signature' && item.controlType != 'photo').sort((a, b) => a.order - b.order).map((control, index) => {
                    // Only adds values if the value is provided (basically should only be null if the control is dependent)
                    if (values[control.controlId]) {
                        values.values.push({ controlId: control.controlId, workOrderMilestoneId: values.id, value: values[control.controlId].toString(), file: null });
                    }
                });

                //console.log(values);
                values.eventDateTime = new Date(values.eventDateTime);
                values.eventDateTime.toJSON = function () { return moment(this).format(); }

                var bulkSave = PostAPIPromise('WorkOrderMilestone/BulkSave', values);

                bulkSave.then(function (response) {

                    switch (response.status) {
                        case -2:
                            alert('An error was encountered during milestone creation.');
                            break;
                        case -1:
                            {
                                alert(response.message);
                                break;
                            }
                        default:

                    }
                });

                return bulkSave;
            },
            update: (key, values) => {

                values.values = [];

                this.state.controlList.filter(item => item.milestoneId == data.milestoneId && item.controlType != 'signature' && item.controlType != 'photo').sort((a, b) => a.order - b.order).map((control, index) => {
                    // Only adds values if the value is provided (basically should only be null if the control is dependent)
                    if (values[control.controlId]) {
                        values.values.push({ controlId: control.controlId, workOrderMilestoneId: values.id, value: values[control.controlId].toString(), file: null });
                    }
                });

                if (values.customerId) {
                    values.customerId = [values.customerId];
                }

                if (values.employeeId) {
                    values.employeeId = [values.employeeId];
                }

                //console.log(values);
                values.eventDateTime = new Date(values.eventDateTime);
                values.eventDateTime.toJSON = function () { return moment(this).format(); }

                return PostAPIPromise('WorkOrderMilestone/BulkSave', values).then(function (response) {

                    switch (response.status) {
                        case -2:
                            alert('An error was encountered during milestone creation.');
                            break;
                        case -1:
                            {
                                alert(response.message);
                                break;
                            }
                        default:

                    }
                });
            },
            remove: (key) => {
                return DeleteAPIPromise('WorkOrderMilestone/' + key);
            }
        })

        const onInitNewRow = (e) => {
            //console.log('Init New Row', e);

            // Set active value default to true
            e.data.workOrderId = this.state.workOrderId;
            e.data.milestoneId = data.milestoneId;
            e.data.stageCode = data.stageCode;
            e.data.stageId = data.stageId ? data.stageId.toString() : null;
            e.data.appliesTo = data.appliesTo;

            var canAddCheck = {
                workOrderId: this.state.workOrderId,
                milestoneId: data.milestoneId,
                appliesTo: data.appliesTo,
                stageCode: data.stageCode,
                stageId: data.stageId ? data.stageId.toString() : null
            };

            e.promise = this.PostAPI('WorkOrderMilestone/CanAddMilestone', canAddCheck).then(data => {
                //console.log(data);
                if (data.status != 1) {
                    window.setTimeout(function () { e.component.cancelEditData(); }, 0);
                    alert(data.message);
                }
            });
        }

        const onEditingStart = (e) => {
            //console.log('Editing Started', e);

            if (e.data.values) {
                e.data.values.map((item, index) => {
                    e.data[item.controlId] = item.value;
                });
            }

            this.rowKey = e.key;
        }

        const customizeItem = (item) => {
            //console.log('Customizing form item', item);

            var controlDependency = this.state.controlDependenciesList.find((dependency) => dependency.dependentControlId == item.dataField);

            if (controlDependency) {
                //console.log('HELLO!', item);
                // Find DataGrid instance
                // Find the grid reference
                let element = document.getElementById(`DataGrid_` + data.milestoneId);

                //console.log(element);

                let dataGridInstance = DataGridNormal.getInstance(element);

                const editing = dataGridInstance.option('editing');
                //console.log(editing);
                const rowIndex = dataGridInstance.getRowIndexByKey(editing.editRowKey);
                var curValue = dataGridInstance.cellValue(rowIndex, controlDependency.controlId);

                // If Editing an existing row, have to pull the current form value from changes
                if (editing.changes.length > 0 && editing.changes[0].data.hasOwnProperty(controlDependency.controlId)) {
                    curValue = editing.changes[0].data[controlDependency.controlId];
                }

                //console.log('Current value', curValue);

                if (controlDependency.controlOptionCode == curValue) {
                    item.isRequired = true;
                }
                else {
                    item.isRequired = false;
                }
            }
        }

        const onRowUpdating = (options) => {
            options.newData = $.extend({}, options.oldData, options.newData);
        }

        const onEditorPreparing = (e) => {
            //console.log('Editor Preparing', e);

            // TODO: Maybe do the customer list here
            if (e.dataField == 'employeeId' && !e.row.isNewRow) {
                e.cancel = true;
                e.visible = false;
                e.isOnForm = false;
                e.allowEditing = false;
                e.isInEditForm = true;
            }

            if (e.dataField == 'customerId' && !e.row.isNewRow) {
                e.cancel = true;
                e.visible = false;
                e.isOnForm = false;
                e.allowEditing = false;
                e.isInEditForm = true;
            }

            var controlDependency = this.state.controlDependenciesList.find((item) => item.dependentControlId == e.name);

            if (controlDependency && e.row.data) {
                //console.log('Control dependency found', e);

                // Check parent value to see if this control should be applicable or not
                if (e.row.data[controlDependency.controlId] && e.row.data[controlDependency.controlId] == controlDependency.controlOptionCode) {
                    // There is a value here at all and the value matches
                    //console.log('Editing okay');
                    e.allowEditing = true;
                    e.disabled = false;
                    e.editorOptions.readOnly = false;
                    e.editorOptions.placeholder = null;
                }
                else {
                    //console.log('DisallowEditing');
                    e.allowEditing = false;
                    e.disabled = true;
                    e.editorOptions.readOnly = true;
                    e.editorOptions.placeholder = 'N/A';
                }
            }
        }

        function setCellValue(
            /*this: EditingColumn,*/
            newData/*: Employee*/,
            value/*: number*/,
            currentRowData/*: Employee*/
        ) {
            //console.log('new data', newData);
            //console.log('value', value);
            //console.log('currentRow', currentRowData);

            var controlDependency = currentControlDependencies.find((item) => item.controlId == this.name);

            if (controlDependency) {
                //console.log('Dependency found', controlDependency)
                newData[controlDependency.dependentControlId] = null;
            }

            this.defaultSetCellValue(newData, value, currentRowData);
        }

        const isComponentReadOnly = () => {

            return this.IsReadOnly() || workOrder.statusID > 1;
        }

        var limitedCustomerIds;

        //console.log(this.state.customerIds);
        //console.log(this.state.customerMappingInfo);

        //console.log(data.stageCode);
        //console.log(data.stageId);

        if (data.stageCode == 'PICKUP') {
            limitedCustomerIds = this.state.customerIds.filter((item) => this.state.customerMappingInfo.find((mapping) => mapping.pickupLocationId == data.stageId && mapping.placementId == item.orrPlacementId));
        }
        else if (data.stageCode == 'DROPOFF') {
            limitedCustomerIds = this.state.customerIds.filter((item) => this.state.customerMappingInfo.find((mapping) => mapping.facilityId == data.stageId && mapping.placementId == item.orrPlacementId));
        }
        else {
            //console.log(this.state.customerMappingInfo);

            limitedCustomerIds = this.state.customerIds;
        }

        //console.log(limitedCustomerIds);
        //console.log('Control dependencies list', this.state.controlDependenciesList);

        var controlList = [];

        this.state.controlList.filter(item => item.milestoneId == data.milestoneId && item.controlType != 'signature' && item.controlType != 'photo').sort((a, b) => a.order - b.order).map((control, index) => {
            controlList.push(<Item key={control.controlId} isRequired={true} editorType={(control.controlType == 'yesno' || control.controlType == 'passfail' || control.controlType == 'picker') ? 'dxSelectBox' : control.dataType != 'text' ? 'dxNumberBox' : 'dxTextBox'}
                editorOptions={{
                    searchEnabled: (control.controlType == 'yesno' || control.controlType == 'passfail' || control.controlTpe == 'picker') ? true : false,
                    dataSource: (control.controlType == 'yesno' ? this.state.yesNoList : (control.controlType == 'passfail' ? this.state.passFailList : this.state.controlOptionList.filter(item => item.controlId == control.controlId))), valueExpr: 'code', displayExpr: 'name', format: (control.dataType == 'int' ? '#,##0' : control.dataType == 'decimal' ? '#,##0.##' : '')
                }} dataField={control.controlId}>
                <Label text={control.label} />
            </Item>);
        });

        var itemColumns = [];
        const currentControlDependencies = this.state.controlDependenciesList;

        this.state.controlList.filter(item => item.milestoneId == data.milestoneId && item.controlType != 'signature' && item.controlType != 'photo').sort((a, b) => a.order - b.order).map((control, index) => {

            var controlDependency = currentControlDependencies.find((item) => item.controlId == control.controlId);

            if (controlDependency) {
                // console.log('Dependency found, pushing special column');
                itemColumns.push(<Column key={control.controlId} dataField={control.controlId} caption={control.label} visible={false} setCellValue={setCellValue} />);
            }
            else {
                // console.log('Pushing normal column');
                itemColumns.push(<Column key={control.controlId} dataField={control.controlId} caption={control.label} visible={false} />);
            }
        });

        return (
            <DataGrid dataSource={store} id={`DataGrid_` + data.milestoneId}
                showBorders={true} allowColumnResizing={true}
                onInitNewRow={onInitNewRow} onEditingStart={onEditingStart} onRowUpdating={onRowUpdating} onEditorPreparing={onEditorPreparing} onExporting={this.onExporting}>
                <Export enabled={true} fileName={"workOrderMilestones" + this.state.workOrder.workOrderName} />
                <Editing mode="popup" allowAdding={!isComponentReadOnly()} allowUpdating={!isComponentReadOnly()} allowDeleting={!isComponentReadOnly()}>
                    <Form colCount={1} customizeItem={customizeItem}>
                        <Item dataField="eventDateTime" dataType="datetime" />

                        {controlList}

                        <Item isRequired={data.appliesTo == 'ts'} dataField="employeeId" editorType="dxTagBox" editorOptions={{ dataSource: this.state.employeeIds, displayExpr: 'display', valueExpr: 'employeeId', showSelectionControls: true, applyValueMode: 'useButtons', searchEnabled: true }} visible={data.appliesTo == 'ts'}>
                            <Label text="Employee Id(s)" />
                        </Item>

                        <Item isRequired={data.appliesTo == 'uac'} dataField="customerId" editorType="dxTagBox" editorOptions={{ dataSource: limitedCustomerIds, displayExpr: 'display', valueExpr: 'customerID', showSelectionControls: true, applyValueMode: 'useButtons', searchEnabled: true }} visible={data.appliesTo == 'uac'}>
                            <Label text="Customer Id(s)" />
                        </Item>

                        <Item
                            dataField="comment"
                            visible={!data.hideComments}
                            editorType="dxTextArea"
                            editorOptions={{ height: 100 }} />
                    </Form>
                </Editing>
                <FilterRow visible={true} />
                <Paging defaultPageSize={20} />
                <Pager showPageSizeSelector={true}
                    allowedPageSizes={[5, 10, 20]}
                    showInfo={true} />

                <Column caption="Event Date/Time" dataField="eventDateTime" dataType="datetime" format="MM/dd/yy, HH:mm '(Local)'" editorOptions={{ max: new Date() }}>
                    <RequiredRule />
                </Column>
                <Column caption="TS Name" dataField="employeeFullName" />
                <Column caption="Customer Name" dataField="customerFullName" visible={data.appliesTo == 'uac'} />
                <Column caption="Comment" dataField="comment" visible={!data.hideComments} />
                <Column caption="Update By" dataField="updatedByFullName" />
                <Column caption="Update Date" dataField="updatedAt" dataType="datetime" format="MM/dd/yy, HH:mm" />

                {/* TODO: I don't like having to do this but, for some reason, for non-dynamic values, the form dataField does not work otherwise */}
                <Column dataField="customerId" visible={false} />
                <Column dataField="employeeId" visible={false} />

                {itemColumns}

                {this.state.controlList.filter(item => item.milestoneId == data.milestoneId).length > 0 &&
                    <MasterDetail enabled={true} component={MilestoneDetail} />
                }
            </DataGrid>
        );
    }

    componentDidMount = async () => {
        super.componentDidMount();
        //console.log('ComponentDidMount');
        //console.log(this.props.params);

        const { id } = this.props.params

        await this.setState({ workOrderId: id });

        await this.fetchData();
    }

    GetComponentPageName = () => {
        return (this.state.workOrder.workOrderNumber ? ("#" + this.state.workOrder.workOrderNumber + " WO Milestones") : 'WO Milestones');
    }

    GetData = async () => {

        await this.setState({
            loading: true
        });

        var employeeData = await this.FetchAPI('WorkOrderResourcesEmployeeList/GetByWorkOrderIdSimple/' + this.state.workOrderId);

        var customerData = await this.FetchAPI('WorkOrderResourcesCustomers/GetFlattenedCustomerList/' + this.state.workOrderId);

        var milestoneControlData = await this.FetchAPI('WorkOrderMilestone/GetActions');

        if (milestoneControlData.status != 1) {
            alert(milestoneControlData.message);
            return;
        }

        milestoneControlData.controlOptionList = milestoneControlData.controlOptionList.filter(c => c.isActive == true);

        var includeAllStages = this.state.IncludeAllStages;

        var itineraryData = await this.FetchAPI('Itinerary/GetItineraryByWorkOrderId?workOrderId=' + this.state.workOrderId);

        //console.log('Itinerary Data', itineraryData);

        var workOrderMilestoneSteps = [];

        // First section, plan start date
        var beginDate = new Date(itineraryData.workOrderInfo.planStartDate);

        //console.log(beginDate);

        // Add trip begin
        this.addProperStepPosition(workOrderMilestoneSteps,
            {
                time: beginDate,
                code: "PREPARATION",
                name: "Prepare for Trip"
            });

        // Need to parse through the itinerary info for unique pickups
        // Check that the itinerary status != 'INACTIVE' (ie placement/customer is in the active list)
        // AND that the pickupstatuscode != 'CANCELLED'

        var pickupDate = beginDate;

        var uniquePickupLocations = [...new Map(itineraryData.customerPlacementMappingInfo.filter((item) => item.itineraryStatus != 'INACTIVE' && item.pickupStatusCode != 'CANCELLED').map(item =>
            [item['pickupLocationId'], item])).values()];

        if (includeAllStages == true) {
            uniquePickupLocations = [...new Map(itineraryData.customerPlacementMappingInfo.map(item =>
                [item['pickupLocationId'], item])).values()];
        }

        uniquePickupLocations.sort((a, b) => moment(a.planPickupDate).valueOf() - moment(b.planPickupDate).valueOf()).map((pickup) => {
            if (new Date(pickup.planPickupDate) > pickupDate) {
                pickupDate = new Date(pickup.planPickupDate);
            }
            var prefix = "";
            if (pickup.itineraryStatus == "INACTIVE") {
                prefix = "Inactive"
            }
            if (pickup.pickupStatusCode == "CANCELLED") {
                prefix = "Cancelled"
            }

            this.addProperStepPosition(workOrderMilestoneSteps,
                {
                    time: new Date(pickup.planPickupDate),
                    code: "PICKUP",
                    name: "Pickup Location",
                    prefix: prefix,
                    itineraryItem: {
                        id: pickup.pickupLocationId,
                        placementId: pickup.placementId,
                        locationName: pickup.pickupLocationName
                    }
                });
        });

        // console.log(uniquePickupLocations);
        pickupDate = new Date(pickupDate.getTime() + (1 * 60000));

        this.addProperStepPosition(workOrderMilestoneSteps,
            {
                time: pickupDate,
                code: "GROUNDTRANSIT",
                name: "Ground Transit"
            });

        // Process hotel stays
        itineraryData.hotelStays.map((hotelStay) => {
            this.addProperStepPosition(workOrderMilestoneSteps,
                {
                    time: new Date(hotelStay.PlanCheckInDate),
                    code: "HOTELSTAY",
                    name: "Hotel Stay",
                    itineraryItem: hotelStay
                });

            this.addProperStepPosition(workOrderMilestoneSteps,
                {
                    time: new Date(hotelStay.PlanCheckOutDate),
                    code: "GROUNDTRANSIT",
                    name: "Ground Transit"
                });
        });

        // Need to parse through the itinerary info for unique dropoffs
        // Check that the itinerary status != 'INACTIVE' (ie placement/customer is in the active list)
        // AND that the dropoffstatuscode != 'CANCELLED'
        var uniqueDropoffLocations = [...new Map(itineraryData.customerPlacementMappingInfo.filter((item) => item.itineraryStatus != 'INACTIVE' && item.dropoffStatusCode != 'CANCELLED').map(item =>
            [item['facilityId'], item])).values()];

        if (includeAllStages == true) {
            uniqueDropoffLocations = [...new Map(itineraryData.customerPlacementMappingInfo.map(item =>
                [item['facilityId'], item])).values()];
        }

        //console.log(uniqueDropoffLocations);
        //console.log(itineraryData.flights);

        var dropoffLocationCount = uniqueDropoffLocations.length;

        // console.log('itinerary', itineraryData);
        // Process Flights
        if (includeAllStages == true) {
            itineraryData.flights.filter((item) => item.isCustomerFlight).map((flight) => {
                var prefix = "";
                if (flight.itineraryStatus == "INACTIVE") {
                    prefix = "Inactive"
                }

                this.addProperStepPosition(workOrderMilestoneSteps,
                    {
                        time: new Date(flight.DepartureLocalTime),
                        code: "FLIGHT",
                        name: "Flight",
                        prefix: prefix,
                        itineraryItem: flight
                    });

                // Check here
                // If there is only one dropoff location, and the code matches the final flight code, do NOT add a ground transit
                //console.log('Dropoff location count', dropoffLocationCount);
                //console.log(uniqueDropoffLocations[0].facilityCode == flight.arrivalAirportCode);
                if (!(dropoffLocationCount == 1 && uniqueDropoffLocations[0].facilityCode == flight.arrivalAirportCode)) {
                    this.addProperStepPosition(workOrderMilestoneSteps,
                        {
                            time: new Date(flight.ArrivalLocalTime),
                            code: "GROUNDTRANSIT",
                            name: "Ground Transit",
                            prefix: prefix,
                        });
                }
            });
        }
        else {
            itineraryData.flights.filter((item) => item.isCustomerFlight && item.itineraryStatus == "ACTIVE").map((flight) => {
                this.addProperStepPosition(workOrderMilestoneSteps,
                    {
                        time: new Date(flight.DepartureLocalTime),
                        code: "FLIGHT",
                        name: "Flight",
                        itineraryItem: flight
                    });

                // Check here
                // If there is only one dropoff location, and the code matches the final flight code, do NOT add a ground transit
                //console.log('Dropoff location count', dropoffLocationCount);
                //console.log(uniqueDropoffLocations[0].facilityCode == flight.arrivalAirportCode);
                if (!(dropoffLocationCount == 1 && uniqueDropoffLocations[0].facilityCode == flight.arrivalAirportCode)) {
                    this.addProperStepPosition(workOrderMilestoneSteps,
                        {
                            time: new Date(flight.ArrivalLocalTime),
                            code: "GROUNDTRANSIT",
                            name: "Ground Transit"
                        });
                }
            });
        }

        uniqueDropoffLocations.sort((a, b) => moment(a.planDropoffDate).valueOf() - moment(b.planDropoffDate).valueOf() || a.itineraryStatus.localeCompare(b.itineraryStatus)).map((pickup) => {

            var prefix = "";

            if (pickup.itineraryStatus == "INACTIVE") {
                prefix = "Inactive"
            }

            if (pickup.dropoffStatusCode == "CANCELLED") {
                prefix = "Cancelled"
            }

            this.addProperStepPosition(workOrderMilestoneSteps,
                {
                    time: new Date(pickup.planDropoffDate),
                    code: "DROPOFF",
                    name: "Dropoff Location",
                    prefix: prefix,
                    itineraryItem: {
                        id: pickup.facilityId,
                        placementId: pickup.placementId,
                        locationName: pickup.facilityName
                    }
                });
        });

        // Add trip end
        this.addProperStepPosition(workOrderMilestoneSteps,
            {
                time: itineraryData.workOrderInfo.PlanEndDate,
                code: "RETURNTRIP",
                name: "Return to Office"
            });

        // TODO: Order items within section
        // TODO: Validate plan pickup date is within plan start and end date

        // TODO: Initial Office/Start address?
        // TODO: Pickup address?
        // TODO: Return address?

        // ABG: Not sure why I have to do this, but devextreme will not handle offset dates properly
        itineraryData.workOrderInfo.planStartDate = moment.parseZone(itineraryData.workOrderInfo.planStartDate).format('yyyy-MM-DDTHH:mm:ss');
        itineraryData.workOrderInfo.planEndDate = moment.parseZone(itineraryData.workOrderInfo.planEndDate).format('yyyy-MM-DDTHH:mm:ss');

        var passFailData = [{ code: 'Pass', name: 'Pass' }, { code: 'Fail', name: 'Fail' }];
        var yesNoData = [{ code: 'Yes', name: 'Yes' }, { code: 'No', name: 'No' }];

        //console.log('Final Itinerary Data', workOrderMilestoneSteps);

        await this.setState({
            milestoneStages: workOrderMilestoneSteps,
            employeeIds: employeeData,
            customerIds: customerData,
            controlList: milestoneControlData.controlList,
            controlOptionList: milestoneControlData.controlOptionList,
            controlDependenciesList: milestoneControlData.controlDependenciesList,
            passFailList: passFailData,
            yesNoList: yesNoData,
            workOrder: itineraryData.workOrderInfo,
            customerMappingInfo: itineraryData.customerPlacementMappingInfo,
            loading: false
        });
    }

    addProperStepPosition(workOrderStageList, itineraryItem) {
        var index = 0;

        // Loop through the section entries and find the correct index
        workOrderStageList.map((entry) => {
            if (itineraryItem.time <= entry.time) {
                return;
            }

            index++;
        });

        workOrderStageList.splice(index, 0, itineraryItem);

    }

    CustomTitle(data) {
        if (!data.itineraryItem) {
            if (data.prefix == 'Cancelled' || data.prefix == 'Inactive') {
                return (
                    <h4 style={{ color: "#7d7d7b" }}>{data.name} - {"(" + data.prefix + ")"}</h4>
                );
            }
            else{
                return (
                    <h4>{data.name}</h4>
                );
            }
        }
        else if (data.prefix == 'Cancelled' || data.prefix == 'Inactive') {

            if (data.itineraryItem.placementId) {
                return (<h4 style={{ color: "#7d7d7b" }}> {data.name} - {data.itineraryItem.locationName} - {"(" +data.prefix +")"}</h4>);
            }
            else if (data.itineraryItem.facilityName) {
                return (<h4 style={{ color: "#7d7d7b" }}>{data.name} - {data.itineraryItem.facilityName} ({moment(data.itineraryItem.planCheckInDate).format("MM/DD/YY HH:mm")} - {moment(data.itineraryItem.planCheckOutDate).format("MM/DD/YY HH:mm")}) - {"(" + data.prefix + ")"}</h4>);
            }
            else if (data.itineraryItem.flightNumber) {
                return (<h4 style={{ color: "#7d7d7b" }}>{data.name} - ({data.itineraryItem.departureAirportCode} - {data.itineraryItem.arrivalAirportCode}) - {"(" + data.prefix + ")"}</h4>);
            }
        }
        else {
            if (data.itineraryItem.placementId) {
                return (<h4> {data.name} - {data.itineraryItem.locationName}</h4>);
            }
            else if (data.itineraryItem.facilityName) {
                return (<h4>{data.name} - {data.itineraryItem.facilityName} ({moment(data.itineraryItem.planCheckInDate).format("MM/DD/YY HH:mm")} - {moment(data.itineraryItem.planCheckOutDate).format("MM/DD/YY HH:mm")})</h4>);
            }
            else if (data.itineraryItem.flightNumber) {
                return (<h4>{data.name} - ({data.itineraryItem.departureAirportCode} - {data.itineraryItem.arrivalAirportCode})</h4>);
            }
        }
    }

    MilestoneStageItemRender(data) {
        //console.log('Milestone Stage Item', data);

        var store = new CustomStore({
            load: (loadOptions) => {
                //console.log('Load', loadOptions);

                return FetchAPIPromise('WorkOrderMilestone/GetMilestoneGroups?stage=' + data.code)
                    .then((json) => { json.map((item, index) => item.stageId = data.itineraryItem ? data.itineraryItem.id : null); return json; });
            }
        })

        return (
            <Accordion dataSource={store}
                itemTitleRender={MilestoneStageTitle}
                multiple={true}
                collapsible={true}
                itemRender={(item) => this.MilestoneItemRender(item, this.state.workOrder)} />
        );
    }

    toggleShowAllStages = async (event) => {
        //console.log('Changing scheduledNoDate');

        await this.setState(state => { return { IncludeAllStages: !state.IncludeAllStages, } });

        await this.GetData();
    }

    showAllMilestones = (e) => {
        console.log(e);

        this.viewAllMilestonesGrid.instance.refresh();

        this.setState({
            popupVisible: true
        });
    }

    hideInfo = (e) => {
        this.setState({
            popupVisible: false
        });
    }

    allMilestonesStore = new CustomStore({
        load: (loadOptions) => {
            //console.log('Load', loadOptions);

            return FetchAPIPromise('WorkOrderMilestone/GetAllMilestones/' + this.state.workOrderId);
        }
    })

    render() {

        return (
            <div className="container">
                <h1>Work Order Milestones</h1>

                <div className="row">
                    <fieldset>
                        <header><b>Work Order</b></header>
                        <br />

                        <NormalForm
                            id="form"
                            colCount={4}
                            formData={this.state.workOrder}>

                            <FormItem dataField="workOrderName" editorOptions={{ readOnly: true }}>
                                <FormLabel text="Name" />
                            </FormItem>

                            <FormItem dataField="workOrderNumber" editorOptions={{ readOnly: true }}>
                                <FormLabel text="Number" />
                            </FormItem>

                            <FormItem dataField="planStartDate" editorType="dxDateBox" editorOptions={{
                                readOnly: true, displayFormat: "MM/dd/yy, HH:mm", type: 'datetime'
                            }}>
                                <FormLabel text="Plan Start Date" />
                            </FormItem>

                            <FormItem dataField="planEndDate" editorType="dxDateBox" editorOptions={{
                                readOnly: true, displayFormat: "MM/dd/yy, HH:mm", type: 'datetime'
                            }}>
                                <FormLabel text="Plan End Date" />
                            </FormItem>
                        </NormalForm>
                    </fieldset>
                </div>
                <br />

                <div className="d-flex">
                    <div className="me-auto me-3">
                        <CheckBox text="Show Inactive/Cancelled Stages" value={this.state.IncludeAllStages} onValueChanged={this.toggleShowAllStages} />
                    </div>
                    <div className="ms-auto me-3">
                        <Button text="View All Milestones" onClick={this.showAllMilestones} />
                    </div>
                </div>
                <LoadPanel
                    visible={this.state.loading} />
                <br />

                <Accordion dataSource={this.state.milestoneStages}
                    collapsible={true}
                    itemTitleRender={this.CustomTitle}
                    itemRender={this.MilestoneStageItemRender}
                    multiple={true} />
                <br />

                <Popup
                    visible={this.state.popupVisible}
                    onHiding={this.hideInfo}
                    dragEnabled={false}
                    closeOnOutsideClick={false}
                    showTitle={true}
                    title="All Milestones">
                    <ScrollView width='100%' height='100%'>
                        <DataGrid dataSource={this.allMilestonesStore} showBorders={true} allowColumnResizing={true}
                            ref={ref => this.viewAllMilestonesGrid = ref} onExporting={this.onExporting}>
                            <Export enabled={true} fileName={"allMilestones" + this.state.workOrder.workOrderName} />
                            <FilterRow visible={true} />

                            <Column caption="Stage" dataField="stageName" />
                            <Column caption="Milestone" dataField="milestoneName" />
                            <Column caption="Event Date/Time" dataField="eventDateTime" dataType="datetime" format="MM/dd/yy, HH:mm '(Local)'" />
                            <Column caption="Customer Name" dataField="customerFullName" />
                            <Column caption="TS Name" dataField="employeeFullName" />
                            <Column caption="Comment" dataField="comment" />
                            <Column caption="Update By" dataField="updatedByFullName" />
                            <Column caption="Updated Date" dataField="updatedAt" dataType="datetime" format="MM/dd/yy, HH:mm" />

                            <MasterDetail enabled={true} component={MilestoneDetail} />
                        </DataGrid>
                    </ScrollView>
                    <br />
                </Popup>
            </div>
        );
    }
}

export default withParams(WorkOrderMilestonesContainer);