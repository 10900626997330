
import React, { useEffect, useState } from 'react';
import BaseComponent, { FetchAPIPromise, PostAPIPromise, formatComponentPageName } from '../BaseComponent.js';
import CustomStore from 'devextreme/data/custom_store';
import DataGrid, { FilterRow, Button as GridButton, Pager, Paging, Column, MasterDetail, Lookup, Editing } from 'devextreme-react/data-grid';
import * as moment from 'moment';
import Button from 'devextreme-react/button';
import { Popup } from 'devextreme-react/popup';
import * as $ from 'jquery';

// ABG: Don't love this, but, it is historically what we do in cases like this
let flightIssues = [];

function OpsCoordinatorCharterFlightInformation() {

    const [isViewingCharterCarriers, setIsViewingCharterCarriers] = useState(false);
    //const [flightIssues, setFlightIssues] = useState([]);

    useEffect(() => {
        async function fetchData() {
            const flightIssueData = await FetchAPIPromise('DictionaryFlightIssue');

            flightIssues = flightIssueData;
        }

        fetchData();
    }, []);

    const charterCarrierStore = new CustomStore({
        key: 'code',
        load: () => {
            return FetchAPIPromise('DictionaryCharterCarrier');
        },
        insert: (values) => {
            return PostAPIPromise('DictionaryCharterCarrier', values);
        },
        //remove: (key) => {
        //    return DeleteAPIPromise('Office/' + key)
        //        .then((result) => {
        //            if (result.status == -2) {
        //                alert('Office is referenced in the system and cannot be deleted. Consider inactivating.')
        //            }
        //        });
        //},
        update: (key, values) => {
            return PostAPIPromise('DictionaryCharterCarrier', values);
        },
        byKey: function (key) {
            // console.log(key);
            return FetchAPIPromise('DictionaryCharterCarrier/' + key);
        }  
    });

    var lkupDataSourceConfiguration = {
        store: charterCarrierStore
    };  

    const onRowUpdating= (options) => {
        options.newData = $.extend({}, options.oldData, options.newData);
    }

    const charterFlightStore = new CustomStore({
        key: 'itineraryId',
        load: () => {
            return FetchAPIPromise('CharterBookings/GetAll').then((response) => {
                console.log(response);
                return response;
                //moment.parseZone(workOrderData.planStartDate).format('yyyy-MM-DDTHH:mm:ss');
            });
        },
        //insert: (values) => {
        //    values.contractId = this.state.contractId;
        //    return PostAPIPromise('Office', values);
        //},
        //remove: (key) => {
        //    return DeleteAPIPromise('Office/' + key)
        //        .then((result) => {
        //            if (result.status == -2) {
        //                alert('Office is referenced in the system and cannot be deleted. Consider inactivating.')
        //            }
        //        });
        //},
        update: (key, values) => {
            return PostAPIPromise('CharterBookings', values);
        }
    });

    const dayOfWeekRender = (data) => {
        if (data.requestDate) {
            return moment(data.requestDate).format('dddd');
        }
    }

    const charterTypeRender = (data) => {

        // Basically, if all of the country codes are US, it's domestic. If the country codes start in US, it's international
        //console.log(cell.row.data.flights);
        let charterType = 'Pending';

        data.flights.map((item) => {
            if (item.departureAirportCountryCode == '5189' && item.arrivalAirportCountryCode == '5189') {
                charterType = 'Domestic';
            }
            else {
                charterType = 'International';
            }
        })

        return charterType;
    }

    const departureDateScheduledRender = (data) => {
        let departureDate = 'Pending';

        var initialFlight = data.flights.length > 0 && data.flights.sort((a, b) => moment(a.departureUTCTime).valueOf() - moment(b.departureUTCTime).valueOf())[0];

        if (initialFlight) {
            return moment(initialFlight.departureLocalTime).format('MM/DD/yyyy');
        }

        return departureDate;
    }

    const departureDayScheduledRender = (data) => {
        let departureDate = 'Pending';

        var initialFlight = data.flights.length > 0 && data.flights.sort((a, b) => moment(a.departureUTCTime).valueOf() - moment(b.departureUTCTime).valueOf())[0];

        if (initialFlight) {
            return moment(initialFlight.departureLocalTime).format('dddd');
        }

        return departureDate;
    }

    const flightTotalHoursRender = (data) => {
        // TODO: Should use UTC?
        var totalHours = 0;

        var firstDeparture = null;
        var lastArrival = null;

        data.flights.length > 0 && data.flights.sort((a, b) => moment(a.departureUTCTime).valueOf() - moment(b.departureUTCTime).valueOf()).map((item) => {
            if (!firstDeparture) {
                firstDeparture = item.actualDepartureUTCTime;
            }

            lastArrival = item.actualArrivalUTCTime;
        });

        if (firstDeparture && lastArrival) {
            //console.log(firstDeparture);
            //console.log(lastArrival);
            totalHours += moment(lastArrival).diff(moment(firstDeparture), 'minutes');

            return (Math.round(totalHours / 15) * 15) / 60;
        }
    }

    const showCharterCarriers = (e) => {
        setIsViewingCharterCarriers(true);
    }

    const hideCharterCarriers = (e) => {
        setIsViewingCharterCarriers(false);
    }

    const preFlightOptions = [{ name: 'Yes', code: true }, { name: 'No', code: false }];

    return (
        <div className="container-fluid">
            <h1>Charter Bookings</h1>
            <br />

            <div className="d-flex justify-content-between">
                <div className="ms-auto">
                    <Button text="Manage Charter Carriers" onClick={showCharterCarriers} />
                </div>
            </div>
            <br />

            <DataGrid dataSource={charterFlightStore} showBorders={true} allowColumnResizing={true} onRowUpdating={onRowUpdating}>
                <Editing allowAdding={false} mode="cell" allowUpdating={true} />

                <Column dataField="requestDate" caption="MVM/UC Charter Date Time COR Requested" dataType="datetime" editorType="dxDateBox" format="MM/dd/yyyy HH:mm" />
                <Column caption="Day of Week COR Requested" editorOptions={{ readOnly: true }} allowSorting={true} calculateCellValue={dayOfWeekRender} />
                <Column dataField="originatingCarrier" caption="Carrier" editorType="dxSelectBox">
                    <Lookup searchEnabled={true} dataSource={lkupDataSourceConfiguration} displayExpr="name" valueExpr="code" />
                </Column>
                <Column caption="Charter Type" editorOptions={{ readOnly: true }} allowSorting={true} calculateCellValue={charterTypeRender} />
                <Column dataField="seatCapacity" caption="Seat Capacity" dataType="number" editorOptions={{ min: 0, max: 999 }} />
                <Column dataField="totalCost" caption="Cost" format='currency' precision={3} />

                <Column caption="Departure Date Scheduled" editorOptions={{ readOnly: true }} allowSorting={true} calculateCellValue={departureDateScheduledRender} dataType="datetime" />
                <Column caption="Day Scheduled" editorOptions={{ readOnly: true }} allowSorting={true} calculateCellValue={departureDayScheduledRender} />

                <Column dataField="tailNumber" caption="Tail Number" />

                <Column caption="Flight Hours (Total)" editorOptions={{ readOnly: true }} allowSorting={true} calculateCellValue={flightTotalHoursRender} dataType="number" />

                <Column dataField="preFlightChecklistRun" caption="Ops Pre-Flight Checklist" editorType="dxSelectBox">
                    <Lookup searchEnabled={true} dataSource={preFlightOptions} displayExpr="name" valueExpr="code" allowClearing={true} />
                </Column>

                <Column dataField="invoiceNumber" caption="Invoice Number" />

                <MasterDetail enabled={true} component={FlightSegment} autoExpandAll={true} />
            </DataGrid>

            <Popup visible={isViewingCharterCarriers} onHiding={hideCharterCarriers} dragEnabled={false}
                closeOnOutsideClick={false} showTitle={true} title="Charter Carriers" width={900} height={500}>
                <DataGrid dataSource={charterCarrierStore} showBorders={true} allowColumnResizing={true} onRowUpdating={onRowUpdating}>
                    <Editing allowAdding={true} mode="popup" allowUpdating={true} />
                    <Column dataField="code" caption="Code" />
                    <Column dataField="name" caption="Name" />
                </DataGrid>
            </Popup>
        </div>);
}

function FlightSegment(props) {

    // console.log('incoming props', props);

    const statuses = [{ name: 'Complete', code: 'Complete' }, { name: 'Rescheduled', code: 'Rescheduled' }, { name: 'Cancelled', code: 'Cancelled' }];

    const flightsStore = new CustomStore({
        key: 'id',
        load: () => {
            return FetchAPIPromise('CharterBookings/GetAllFlightsForBooking?bookingId=' + props.data.row.data.itineraryId).then((response) => {
                console.log(response);

                response.map((item) => {
                    item.arrivalLocalTime = moment.parseZone(item.arrivalLocalTime).format('yyyy-MM-DDTHH:mm:ss');
                    item.departureLocalTime = moment.parseZone(item.departureLocalTime).format('yyyy-MM-DDTHH:mm:ss');

                    if (item.actualArrivalLocalTime) {
                        item.actualArrivalLocalTime = moment.parseZone(item.actualArrivalLocalTime).format('yyyy-MM-DDTHH:mm:ss');
                    }

                    if (item.actualDepartureLocalTime) {
                        item.actualDepartureLocalTime = moment.parseZone(item.actualDepartureLocalTime).format('yyyy-MM-DDTHH:mm:ss');
                    }
                });

                return response;
            });
        },
        update: (key, values) => {
            return PostAPIPromise('CharterBookings/UpdateFlight', values);
        }
    });

    const onRowUpdating = (options) => {
        options.newData = $.extend({}, options.oldData, options.newData);
    }

    const departureAirportNameRender = (data) => {
        return `${data.departureAirportCode} - ${data.departureAirportName}`;
    }

    const departureDelayTimeRender = (data) => {
        // TODO: Should use UTC?
        if (data.departureLocalTime && data.actualDepartureLocalTime) {
            var totalDifference = moment(data.actualDepartureLocalTime).diff(moment(data.departureLocalTime), 'minutes');

            return totalDifference;
        }
    }

    const arrivalDelayTimeRender = (data) => {
        // TODO: Should use UTC?
        if (data.arrivalLocalTime && data.actualArrivalLocalTime) {
            var totalDifference = moment(data.actualArrivalLocalTime).diff(moment(data.arrivalLocalTime), 'minutes');

            return totalDifference;
        }
    }

    const onCellPrepared = (e) => {
        if (e.rowType === "data" && e.column.name === "departureDelayTime") {
            // console.log(e);

            /*
            -Green = Most negative number of minutes – 15 minutes
            -Yellow = 16 minutes-30 minutes
            -Red = Greater than or equal to 31 minutes
            */
            if (e.row.data.departureLocalTime && e.row.data.actualDepartureLocalTime) {
                var totalDifference = moment(e.row.data.actualDepartureLocalTime).diff(moment(e.row.data.departureLocalTime), 'minutes');

                if (totalDifference <= 15) {
                    e.cellElement.style.backgroundColor = 'green';
                }
                else if (totalDifference > 15 && totalDifference <= 30) {
                    e.cellElement.style.backgroundColor = 'yellow';
                }
                else {
                    e.cellElement.style.backgroundColor = 'red';
                }
            }
        }
        else if (e.rowType === "data" && e.column.name === "arrivalDelayTime") {
            // console.log(e);

            /*
            -Green = Most negative number of minutes – 15 minutes
            -Yellow = 16 minutes-30 minutes
            -Red = Greater than or equal to 31 minutes
            */
            if (e.row.data.arrivalLocalTime && e.row.data.actualArrivalLocalTime) {
                var totalDifference = moment(e.row.data.actualArrivalLocalTime).diff(moment(e.row.data.arrivalLocalTime), 'minutes');

                if (totalDifference <= 15) {
                    e.cellElement.style.backgroundColor = 'green';
                }
                else if (totalDifference > 15 && totalDifference <= 30) {
                    e.cellElement.style.backgroundColor = 'yellow';
                }
                else {
                    e.cellElement.style.backgroundColor = 'red';
                }
            }
        }
    }

    const onEditorPreparing = (e) => {
        //console.log(e);

        if (e.dataField == 'departureIssueComments' && e.row.data.departureIssueCode == 'None') {
            e.editorOptions.readOnly = true;
        }
        else if (e.dataField == 'arrivalIssueComments' && e.row.data.arrivalIssueCode == 'None') {
            e.editorOptions.readOnly = true;
        }
    }

    const flightTotalHoursRender = (data) => {
        // TODO: Should use UTC?
        if (data.actualDepartureUTCTime && data.actualArrivalUTCTime) {
            var totalDifference = moment(data.actualArrivalUTCTime).diff(moment(data.actualDepartureUTCTime), 'minutes');

            return (Math.round(totalDifference / 15) * 15) / 60;
        }
    }

    const setDepartureIssue = (rowData, value, currentRowData) => {
        // console.log('Incoming New Value', value);

        rowData.departureIssueCode = value;

        if (value == 'None') {
            rowData.departureIssueComments = 'N/A';
        }
    }

    const setArrivalIssue = (rowData, value, currentRowData) => {
        // console.log('Incoming New Value', value);

        rowData.arrivalIssueCode = value;

        if (value == 'None') {
            rowData.arrivalIssueComments = 'N/A';
        }
    }

    const arrivalCountryRender = (data) => {
        //console.log(data);

        if (data.arrivalAirportCountryCode == '5189') {
            return data.arrivalAirportState;
        }
        else {
            return data.arrivalAirportCountryName;
        }
    }

    return (
        <div>
            <DataGrid dataSource={flightsStore} onRowUpdating={onRowUpdating} onEditorPreparing={onEditorPreparing}
                showBorders={true} allowColumnResizing={true} onCellPrepared={onCellPrepared}
                keyExpr="id">
                <Editing allowAdding={false} mode="cell" allowUpdating={true} />

                <Column caption="Flight Hours" editorOptions={{ readOnly: true }} allowSorting={true} calculateCellValue={flightTotalHoursRender} dataType="number" />

                <Column dataField="departureAirportCity" caption="US Departure City" editorOptions={{ readOnly: true }} />
                <Column caption="US Departure Airport" editorOptions={{ readOnly: true }} allowSorting={true} calculateCellValue={departureAirportNameRender} />

                <Column dataField="departureLocalTime" caption="US Departure Time Scheduled" editorOptions={{ readOnly: true }} dataType="datetime" format="MM/dd/yyyy HH:mm" />
                <Column dataField="actualDepartureLocalTime" caption="Actual US Departure Time" dataType="datetime" editorType="dxDateBox" format="MM/dd/yyyy HH:mm" />
                <Column name="departureDelayTime" caption="Delay Time in Minutes (Departures)" allowSorting={true} calculateCellValue={departureDelayTimeRender} dataType="number" />

                <Column dataField="departureIssueCode" caption="Departure Issues" setCellValue={setDepartureIssue}>
                    <Lookup searchEnabled={true} dataSource={flightIssues} displayExpr="name" valueExpr="code" allowClearing={true} />
                </Column>

                <Column dataField="departureIssueComments" caption="Comments" />
                <Column dataField="customerNotified" caption="ICE Customer Notified (Name)" />

                <Column dataField="arrivalAirportCity" caption="Arrival City/State" editorOptions={{ readOnly: true }} />
                <Column caption="Arrival State or Country" editorOptions={{ readOnly: true }} allowSorting={true} calculateCellValue={arrivalCountryRender} />

                <Column dataField="arrivalLocalTime" caption="Scheduled Arrival" editorOptions={{ readOnly: true }} dataType="datetime" format="MM/dd/yyyy HH:mm" />
                <Column dataField="actualArrivalLocalTime" caption="Actual Arrival" dataType="datetime" editorType="dxDateBox" format="MM/dd/yyyy HH:mm" />
                <Column name="arrivalDelayTime" caption="Delay Time in Minutes (Arrivals)" allowSorting={true} calculateCellValue={arrivalDelayTimeRender} dataType="number" />

                <Column dataField="arrivalIssueCode" caption="Arrival Issues" setCellValue={setArrivalIssue}>
                    <Lookup searchEnabled={true} dataSource={flightIssues} displayExpr="name" valueExpr="code" allowClearing={true} />
                </Column>

                <Column dataField="arrivalIssueComments" caption="Comments" />

                <Column dataField="statusCode" caption="Status">
                    <Lookup searchEnabled={true} dataSource={statuses} displayExpr="name" valueExpr="code" allowClearing={true} />
                </Column>

                <Column dataField="statusComments" caption="Comments" />
            </DataGrid>
        </div>
    );
}

export default OpsCoordinatorCharterFlightInformation;