
import React from 'react';
import * as moment from 'moment';
import BaseComponent, { FetchAPIPromise, PostAPIPromise, DeleteAPIPromise } from "../BaseComponent";
import DataGrid, { Column, FormItem, FilterRow, Pager, Paging, Lookup, Editing, Sorting, RequiredRule, StringLengthRule, Export } from 'devextreme-react/data-grid';
import Form, { Item, EmptyItem, Label } from "devextreme-react/form";
import { Link } from 'react-router-dom';
import Button from 'devextreme-react/button';
import CustomStore from 'devextreme/data/custom_store';
import { CheckBox } from 'devextreme-react/check-box';
import * as $ from 'jquery'
import ArrayStore from 'devextreme/data/array_store';

export class EmployeeNonbillHoursContainer extends BaseComponent {
    static displayName = EmployeeNonbillHoursContainer.name;

    constructor(props) {
        super(props);

        this.state = {

            nonbillTypes: [],
            employees: [],

            filter: {
                weekBegin: ''
            }
        };
    }

    componentDidMount = async () => {
        super.componentDidMount();
        this.fetchData();
    }

    GetComponentPageName = () => {
        return ("Nonbill Hours");
    }

    GetDropDownData = async () => {
        const nonbillTypeData = await this.FetchAPI('EmployeeNonbillHours/GetNonbillTypes');
        //console.log(officeData);

        const employeeData = await this.FetchAPI('Employee/GetScheduleEmployees');
        //console.log(employeeData);

        await this.setState({
            nonbillTypes: nonbillTypeData,
            employees: employeeData
        });
    }

    GetData = async () => {
        var today = moment();
        var from_date = today.clone().startOf('week');

        this.setState({
            filter: {
                weekBegin: from_date.format("MM/DD/YYYY")
            }
        });

        this.dataGrid.instance.refresh();
    }

    allowDeleting = (options) => {
        return options.row.data && !options.row.data.isImported;
    }

    allowUpdating = (options) => {
        return options.row.data && !options.row.data.isImported;
    }

    fieldDataChanged = async (e) => {
        //console.log('Field Data changed', e);
        //console.log(this.state.filters);

        if (e.dataField == 'weekBegin') {
            this.dataGrid.instance.refresh();
        }
    }

    isDisabledDate = (args) => {
        //console.log(args);

        if (args.view === "month") {
            return args.date.getDay() > 0;
        }

        return false;
    }

    customDataSource = new CustomStore({
        key: 'id',
        load: (loadOptions) => {
            //console.log('Load', loadOptions);

            if (this.state.filter.weekBegin) {
                var queryString = '';
                queryString += 'beginDate=' + this.state.filter.weekBegin;

                var weekEnd = moment(this.state.filter.weekBegin).add(6, 'day').format("MM/DD/YYYY");

                queryString += '&endDate=' + weekEnd;

                //console.log(queryString);

                return FetchAPIPromise('EmployeeNonbillHours/GetHoursByDate', queryString);
            }
        },
        insert: async (values) => {
            var returnValue = await PostAPIPromise('EmployeeNonbillHours', values);

            if (returnValue.status == -1) {
                throw new Error(returnValue.message);
            }
            else {
                return returnValue
            }
        },
        update: async (key, values) => {
            console.log(values);
            var returnValue = await PostAPIPromise('EmployeeNonbillHours', values);

            if (returnValue.status == -1) {
                throw new Error(returnValue.message);
            }
            else {
                return returnValue
            }
        },
        remove: (key) => {
            return DeleteAPIPromise('EmployeeNonbillHours/' + key);
        }
    });

    onRowUpdating(options) {
        options.newData = $.extend({}, options.oldData, options.newData);
    }

    render() {
        return (
            <div className="container">
                <h1>Nonbill Hours</h1>
                <Form colCount={2} formData={this.state.filter} onFieldDataChanged={this.fieldDataChanged}>
                    <EmptyItem />
                    <Item editorType="dxDateBox" dataField="weekBegin" editorOptions={{
                        disabledDates: this.isDisabledDate
                    }}>
                        <Label text="Week Begin" />
                    </Item>
                </Form>
                <Link to={`/nonbill-type`}>
                    <Button text="Manage Nonbill Types" />
                </Link>
                <br />
                <br />
                <DataGrid dataSource={this.customDataSource} key="id"
                    showBorders={true} allowColumnResizing={true}
                    ref={ref => this.dataGrid = ref} onRowUpdating={this.onRowUpdating}>

                    <Export enabled={true} fileName={"nonbillHours"} />
                    <Editing mode="popup" allowAdding={true} allowUpdating={this.allowUpdating} allowDeleting={this.allowDeleting}>
                    </Editing>

                    <FilterRow visible={true} />
                    <Paging defaultPageSize={10} />
                    <Pager showPageSizeSelector={true}
                        allowedPageSizes={[5, 10, 20]}
                        showInfo={true} />

                    <Column caption="Employee Name" dataField="employeeName">
                        <FormItem visible={false} />
                    </Column>
                    <Column caption="Employee Name" dataField="employeeId" visible={false}>
                        <RequiredRule />
                        <FormItem editorType="dxSelectBox" editorOptions={{ dataSource: this.state.employees, displayExpr: 'employeeName', valueExpr: 'employeeId', searchEnabled: true }} />
                    </Column>
                    <Column caption="Description" dataField="description">
                        <RequiredRule />
                        <StringLengthRule max={100} />
                    </Column>
                    <Column caption="Work Date" dataField="workDate" dataType="date" format="MM/dd/yy">
                        <RequiredRule />
                    </Column>
                    <Column caption="Nonbill Type" dataField="nonbillTypeCode">
                        <RequiredRule />
                        <Lookup allowClearing={true} dataSource={this.state.nonbillTypes} displayExpr="name" valueExpr="code" />
                    </Column>
                    <Column caption="Hours" dataField="hours">
                        <RequiredRule />
                        <FormItem colSpan={2} />
                    </Column>
                    <Column caption="Created By" dataField="createUser">
                        <FormItem visible={false} />
                    </Column>
                    <Column caption="Created At" dataField="createDate" dataType="datetime" format="MM/dd/yy, HH:mm">
                        <FormItem visible={false} />
                    </Column>
                    <Column caption="Updated By" dataField="updateDisplayUser">
                        <FormItem visible={false} />
                    </Column>
                    <Column caption="Updated At" dataField="updateDate" dataType="datetime" format="MM/dd/yy, HH:mm">
                        <FormItem visible={false} />
                    </Column>
                </DataGrid>
                <br />
            </div>
        );
    }
}