import React from 'react';
import BaseComponent, { FetchAPIPromise, withParams } from "../BaseComponent";
import DataGrid, { Column, FilterRow, Pager, Paging, Selection, MasterDetail, Export } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import CheckBox from 'devextreme-react/check-box';
import { Link } from 'react-router-dom';
import Button from 'devextreme-react/button';
import TextBox from 'devextreme-react/text-box';
import { alert, confirm } from 'devextreme/ui/dialog';

class PlacementAssignContainer extends BaseComponent {
    static displayName = PlacementAssignContainer.name;

    constructor(props) {
        super(props);

        this.state = {
            workOrderId: '',
            filter: {
                notDispatched: true,
                dispatched: false,
                addedOnSupport: false,
                cancelled: false,
                text: ""
            },
            placements: []
        };
    }

    componentDidMount = async () => {
        super.componentDidMount();
        //console.log('ComponentDidMount');
        //console.log(this.props.params);

        const { id } = this.props.params

        await this.setState({ workOrderId: id });

        await this.fetchData();
    }

    GetComponentPageName = () => {
        return ("Assign Placements");
    }

    placementDataSource = new CustomStore({
        key: 'id',
        onLoading: (loadOptions) => {
            console.log('Loading: ' + loadOptions);

            loadOptions.userData["notDispatched"] = this.state.filter.notDispatched;
            loadOptions.userData["dispatched"] = this.state.filter.dispatched;
            loadOptions.userData["addedOnSupport"] = this.state.filter.addedOnSupport;
            loadOptions.userData["cancelled"] = this.state.filter.cancelled;
            loadOptions.userData["text"] = this.state.filter.text;
        },
        load: (loadOptions) => {
            console.log('Load: ' + loadOptions);

            var queryString = ' ';
            var queryOptions = Object.keys(loadOptions.userData);

            console.log(queryOptions);

            for (var i = 0; i < queryOptions.length; i++) {

                if (queryString) {
                    queryString += '&';
                }

                queryString += queryOptions[i] + '=' + loadOptions.userData[queryOptions[i]];
            }
            console.log(queryString);

            return FetchAPIPromise('Placement', queryString);
        }
    });

    handleChange = (event) => {
        this.setState(
            (state) => ({
                filter: {
                    ...state.filter,
                    [event.element.id]: !state.filter[event.element.id]
                }
            })
        );
    };

    savePlacements = async () => {
        this.dataGrid.instance.getSelectedRowsData().then(async (rowData) => {
            if (rowData.length == 0) {
                alert("Please select placements.");
            }
            else {
                var array = {};
                array.placementIds = rowData.map(i => i.id);
                array.workOrderID = this.state.workOrderId;
                array.remove = false;
                console.log(array);

                var validationResult = await this.PostAPI('Placement/CheckPlacementIsUsed', array);

                console.log(validationResult);

                if (validationResult.status == -1) {
                    alert(validationResult.message);
                    return;
                }
                else if (validationResult.status == 0) {
                    alert("One or more Customers from these Placements are already on Work Order and cannot be added.");
                    return;
                }
                else {
                    for (var i = 0; i < validationResult.errorList.length; i++) {
                        var item = validationResult.errorList[i];

                        console.log(item);

                        if (item.startsWith('Age Error')) {
                            alert(item + " is not the same Transport Unit. Assigned Placement Transport Units must be the same as the Work Order.");
                            return;
                        }
                        else if (item.startsWith('Inactive Roster Error ')) {
                            alert(item + " is already on this Work Order in the Inactive Roster and cannot be added. ");
                            return;
                        }
                        else if (item.startsWith('Workorder Incomplete')) {
                            var confirmAnswer = await confirm(item + " is part of an active Work Order. Are you sure you want to assign?");

                            if (!confirmAnswer) {
                                return;
                            }
                        }
                        else {
                            alert(item + " has already been dispatched on another Work Order.");
                            return;

                        }
                    }
                }

                var result = await this.PostAPI('Placement/SavePlacementsToWO', array);

                console.log(result);
                if (result.status == -1) {
                    alert(result.message);
                }
                else {
                    alert('Saved Successfully!');
                    this.props.navigate('/workorder-resources/' + array.workOrderID);
                }
            }
        })
    }

    search = () => {
        this.dataGrid.instance.refresh();
    }

    handleTextChange = async (event) => {
        await this.setState(
            (prevState) => ({
                filter: {
                    ...prevState.filter,
                    text: event.value
                }
            })
        );
    }

    render() {
        return (
            <div className="container">
                <h1>Assign Placements</h1>
                <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                        Status:{' '}&nbsp;
                        <CheckBox value={this.state.filter.notDispatched} id="notDispatched" text="Not Dispatched" onValueChanged={this.handleChange} />&nbsp;
                        <CheckBox value={this.state.filter.dispatched} id="dispatched" text="Dispatched" onValueChanged={this.handleChange} />&nbsp;
                        <CheckBox value={this.state.filter.addedOnSupport} id="addedOnSupport" text="Added on Support" onValueChanged={this.handleChange} />&nbsp;
                        <CheckBox value={this.state.filter.cancelled} id="cancelled" text="Permanently Cancelled" onValueChanged={this.handleChange} />&nbsp;&nbsp;&nbsp;
                        <TextBox value={this.state.filter.text} id="text" defaultValue="" width={200} placeholder="Search by A#/Subject ID" onValueChanged={this.handleTextChange} />&nbsp;&nbsp;&nbsp;
                        <Button text="Search" icon="search" onClick={this.search} />
                    </div>

                    <div className="ms-auto me-3 form-inline">
                        {(this.IsReadOnly)
                            ? <Button disabled={true} icon="plus" text="Add New Placement" />
                            : <Link to={`/add-placement/${this.state.workOrderId}`}><Button icon="plus" text="Add New Placement" /></Link>
                        }
                    </div>
                </div>

                <br />

                <DataGrid dataSource={this.placementDataSource} allowColumnResizing={true}
                    showBorders={true} ref={ref => this.dataGrid = ref} onExporting={this.onExporting}>
                    <Export enabled={true} fileName={"placementList"} />
                    <Selection mode="multiple" selectAllMode="allPages" showCheckBoxesMode="always" deferred={true} />
                    <Paging defaultPageSize={10} />
                    <Pager showPageSizeSelector={true}
                        allowedPageSizes={[5, 10, 20]}
                        showInfo={true} />
                    <FilterRow visible={true} />

                    <Column caption="Name" dataField="placementShortName" />
                    <Column caption="Placement Date" dataField="orrPlacementDate" dataType="datetime" format="MM/dd/yy, HH:mm '(Local)'" />
                    <Column caption="Transport Type" dataField="transportUnit" />
                    <Column caption="Movement Type" dataField="movementType" />
                    <Column caption="Departure City" dataField="departureCity" />
                    <Column caption="DHS Custody Date" dataField="dhsCustodyDate" dataType="datetime" format="MM/dd/yy, HH:mm" />
                    <Column caption="Subclassification Code" dataField="subclassificationName" />
                    <Column caption="Pickup Location" dataField="pickupLocationName" />
                    <Column caption="Destination" dataField="destinationName" />
                    <Column caption="Status" dataField="statusName" />

                    <MasterDetail enabled={true} autoExpandAll={true} component={PlacementDetail} />
                </DataGrid>
                <br />
                <div className="d-flex justify-content-between">
                    <div className="ms-auto me-3 form-inline">
                        {(this.IsReadOnly)
                            ? <Button disabled={true} text="Cancel" type="normal" />
                            : <Link to={`/workorder-resources/${this.state.workOrderId}`} ><Button text="Cancel" type="normal" /></Link>
                        }
                        &nbsp;
                        <Button disabled={this.IsReadOnly()} text="Save" type="success" onClick={this.savePlacements} />
                    </div>
                </div>
                <br />
            </div>
        );
    }
}

class PlacementDetail extends React.Component {
    constructor(props) {
        console.log('Detail Props', props);
        super(props);
        this.dataSource = props.data.row.data.customerList;
        this.placementShortName = props.data.row.data.placementShortName;
    }
    render() {
        return (
            <DataGrid dataSource={this.dataSource} showBorders={true} allowColumnResizing={true} onExporting={this.onExporting}>
                <Export enabled={true} fileName={"placementDetail" + this.placementShortName} />
                <Column caption="A#/Subject ID" dataField="comboIDDisplay" />
                <Column caption="First Name" dataField="firstName" />
                <Column caption="Middle Name" dataField="middleName" />
                <Column caption="Last Name" dataField="lastName" />
                <Column caption="Gender" dataField="gender" />
            </DataGrid>
        );
    }
}

export default withParams(PlacementAssignContainer);