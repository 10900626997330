
import React from 'react';
import * as moment from 'moment';
import BaseComponent, { FetchAPIPromise, PostAPIPromise, DeleteAPIPromise } from "../BaseComponent";
import DataGrid, { Column, FormItem, FilterRow, Pager, Paging, Lookup, Editing, Sorting, RequiredRule, StringLengthRule, Form, Export, Button } from 'devextreme-react/data-grid';
import { Item } from "devextreme-react/form";
import CustomStore from 'devextreme/data/custom_store';
import { CheckBox } from 'devextreme-react/check-box';
import * as $ from 'jquery'
import ArrayStore from 'devextreme/data/array_store';

const leaveHoursDataSource = new ArrayStore({

});

const LeaveHoursCellRender = function (options) {
    //console.log(options);

    var leaveHours = null;

    if (options.row.data.leaveHours && options.row.data.leaveHours.length > 0) {
        leaveHours = 0;

        options.row.data.leaveHours.map((item, index) => {
            if (item.leaveHours) {
                leaveHours += item.leaveHours;
            }
        });
    }

    return (<span>
        {leaveHours}
    </span>);
}

const mergeLeaveHours = (disabledStartTime, disabledEndTime) => {
    console.log(disabledStartTime);
    console.log(disabledEndTime);
    console.log('Recalculating dates');

    // Check existing dates, maybe update/insert dates, etc
    if (disabledStartTime && disabledEndTime) {
        //console.log('Need to update dates');

        //console.log(leaveHoursDataSource);

        var beginDate = moment(disabledStartTime);
        var endDate = moment(disabledEndTime);

        // TODO: Check existing rows in the leave hours
        var newDataSource = [];

        var existingItem = leaveHoursDataSource._array.find(x => x.workDate == beginDate.format('MM/DD/YYYY'));
        //console.log(existingItem);

        newDataSource.push({ workDate: beginDate.format('MM/DD/YYYY'), leaveHours: existingItem ? existingItem.leaveHours : null });

        beginDate.add(1, 'days');

        while (beginDate.isSameOrBefore(endDate, 'day')) {
            existingItem = leaveHoursDataSource._array.find(x => x.workDate == beginDate.format('MM/DD/YYYY'));
            //console.log(existingItem);

            newDataSource.push({ workDate: beginDate.format('MM/DD/YYYY'), leaveHours: existingItem ? existingItem.leaveHours : null });

            beginDate.add(1, 'days');
        }

        //console.log(newDataSource);

        leaveHoursDataSource.clear();

        newDataSource.map((item, index) => {
            leaveHoursDataSource.insert(item);
        });
    }
}

export class EmployeeLeaveRequestsContainer extends BaseComponent {
    static displayName = EmployeeLeaveRequestsContainer.name;

    constructor(props) {
        super(props);

        this.rowKey = -1;
        this.dataGrid = React.createRef();
        this.leaveHoursRender = this.leaveHoursRender.bind(this);

        this.state = {
            statuses: [],
            employees: [],
            leaveTypes: [],
            employeeId: 0,
            filter: {
                submitted: true,
                approved: false,
                notapproved: false
            }
        };
    }

    componentDidMount = async () => {
        super.componentDidMount();
        this.fetchData();
    }

    GetComponentPageName = () => {
        return ("Leave Requests");
    }

    customDataSource = new CustomStore({
        key: 'id',
        onLoading: (loadOptions) => {
            //console.log('Loading', loadOptions);

            loadOptions.userData["submitted"] = this.state.filter.submitted;
            loadOptions.userData["approved"] = this.state.filter.approved;
            loadOptions.userData["notapproved"] = this.state.filter.notapproved;
        },
        load: (loadOptions) => {
            //console.log('Load', loadOptions);

            var queryString = '';
            var queryOptions = Object.keys(loadOptions.userData);

            console.log(queryOptions);

            for (var i = 0; i < queryOptions.length; i++) {

                if (queryString) {
                    queryString += '&'
                }

                queryString += queryOptions[i] + '=' + loadOptions.userData[queryOptions[i]];
            }

            //console.log(queryString);

            return FetchAPIPromise('EmployeeLeaveRequests/GetEmployeeRequestsByStatus', queryString);
        },
        insert: (values) => {
            values.leaveHours = [];
            
            leaveHoursDataSource._array.map((hours, index) => {
                if (hours.leaveHours > 0) {
                    values.leaveHours.push({ workDate: hours.workDate, leaveHours: hours.leaveHours });
                }
            });

            return PostAPIPromise('EmployeeLeaveRequests', values);
        },
        update: (key, values) => {
            values.leaveHours = [];

            leaveHoursDataSource._array.map((hours, index) => {
                if (hours.leaveHours > 0) {
                    values.leaveHours.push({ workDate: hours.workDate, leaveHours: hours.leaveHours });
                }
            });


            return PostAPIPromise('EmployeeLeaveRequests', values);
        },
        remove: (key) => {
            return DeleteAPIPromise('EmployeeLeaveRequests/' + key);
        }
    });


    handleChange = (event) => {
        this.setState(
            (state) => ({
                filter: {
                    ...state.filter,
                    [event.element.id]: !state.filter[event.element.id]
                }
            }),
            () => {
                //console.log(this.dataGrid);
                this.dataGrid.current.instance.refresh();
            }
        );
    };

    GetDropDownData = async () => {
        const statusesData = await this.FetchAPI('DictionaryApprovalStatus');
        const employeesData = await this.FetchAPI('Employee/GetEmployeesAsSupervisor');
        const leaveTypesData = await this.FetchAPI('DictionaryLeaveType');

        console.log(statusesData);

        this.setState({
            statuses: statusesData,
            employees: employeesData,
            leaveTypes: leaveTypesData
        });
    }

    GetData = async () => {
        const confirmLoginData = await this.FetchAPI('UserConfirmLogin');

        this.setState({
            employeeId: confirmLoginData.employee.employeeId
        });
    }

    onRowUpdating(options) {
        console.log('Row Updating');
        options.newData = $.extend({}, options.oldData, options.newData);
    }

    onEditorPreparing = (e) => {
        if (e.dataField == 'employeeId' && e.parentType == 'dataRow') {
            //console.log('Editor Options', e.editorOptions);
            if (!e.row.isNewRow && !(e.row.data && e.row.data.createUserId == this.state.employeeId && e.row.data.statusCode == 'SUBMITTED')) {
                e.editorOptions.readOnly = true;
            }
        }

        if (e.parentType == 'dataRow' && e.row.data.statusCode != 'SUBMITTED' && e.dataField != 'statusCode') {
            e.editorOptions.readOnly = true;
        }

        //console.log(e);
    }

    allowDeleting = (options) => {
        //console.log(options);
        //console.log('Can Delete', options.row.data && options.row.data.createUserId == this.state.employeeId && options.row.data.statusCode == 'SUBMITTED');
        console.log('Can Delete', options.row.data && options.row.data.createUserId != options.row.data.employeeId);

        //return options.row.data && options.row.data.createUserId == this.state.employeeId && options.row.data.statusCode == 'SUBMITTED';
        return options.row.data && options.row.data.createUserId != options.row.data.employeeId && options.row.data.statusCode == 'SUBMITTED';
    }

    onRowValidating = (e) => {
        console.log('validating', e);

        if (e.newData.disabledStartTime && e.newData.disabledEndTime) {
            if (moment(e.newData.disabledStartTime).isAfter(moment(e.newData.disabledEndTime))) {
                //console.log('INVALID');
                e.isValid = false;
                e.errorText = 'End Date cannot precede Start Date';
            }
        }

        if (e.newData.disabledStartTime && e.newData.disabledEndTime) {
            var duration = moment(new Date(e.newData.disabledEndTime)).diff(new Date(e.newData.disabledStartTime), 'months', true)
            console.log(duration);
            if (duration > 3) {
                e.isValid = false;
                e.errorText = 'Leave request cannot exceed 3 months in length';
            }
        }

        var leaveType = (e.newData.leaveTypeCode ? e.newData.leaveTypeCode : e.oldData.leaveTypeCode);

        if (leaveType != 'UNPAID') {
            // Ensure no save occurs if there are no entries with leave hours
            var leaveTotal = 0;

            leaveHoursDataSource._array.map((item, index) => {
                if (item.leaveHours) {
                    leaveTotal += item.leaveHours;
                }
            });

            //console.log('Leave Total: ' + leaveTotal);

            if (leaveTotal <= 0) {
                e.isValid = false;
                e.errorText = 'If Leave Type is not Unpaid, Leave Hours are required';
            }
        }
    }

    setStateValue(rowData, value, currentRowData) {
        if (value == 'UNPAID') {
            console.log('Clearing leave hours');
            rowData.leaveHours = null;
            leaveHoursDataSource.clear();
        }
        else {
            mergeLeaveHours(currentRowData.disabledStartTime, currentRowData.disabledEndTime);
        }

        // Set conditional required field state
        this.defaultSetCellValue(rowData, value);
    }

    updateDateRange(rowData, value, currentRowData) {
        this.defaultSetCellValue(rowData, value);

        // TODO: What if is/is not unpaid?
        //console.log(rowData.hasOwnProperty('disabledStartTime'));
        //console.log(rowData.hasOwnProperty('disabledEndTime'));

        console.log(currentRowData);

        if ((rowData.disabledStartTime || rowData.disabledEndTime) && currentRowData.leaveTypeCode !== 'UNPAID') {
            console.log('One of the dates were changed.');

            // One of the dates were changed
            var disabledStartTime = currentRowData.disabledStartTime;

            if (rowData.disabledStartTime) {
                disabledStartTime = rowData.disabledStartTime;
            }

            var disabledEndTime = currentRowData.disabledEndTime;

            if (rowData.disabledEndTime) {
                disabledEndTime = rowData.disabledEndTime;
            }

            mergeLeaveHours(disabledStartTime, disabledEndTime);
        }
    }

    onEditingStart = (e) => {
        this.rowKey = e.key;

        //console.log(e);

        leaveHoursDataSource.clear();

        if (e.data.leaveHours && e.data.leaveTypeCode !== 'UNPAID') {
            console.log(e.data.leaveHours);

            // Check existing dates, maybe update/insert dates, etc
            var beginDate = moment(e.data.disabledStartTime);
            var endDate = moment(e.data.disabledEndTime);

            var currentEntry = e.data.leaveHours.find(element => moment(element.workDate).isSame(beginDate, 'date'));
            var currentHours = null;

            if (currentEntry) {
                currentHours = currentEntry.leaveHours;
            }

            // TODO: Check existing rows in the leave hours
            leaveHoursDataSource.insert({ workDate: beginDate.format('MM/DD/YYYY'), leaveHours: currentHours });

            beginDate.add(1, 'days');

            while (beginDate.isSameOrBefore(endDate, 'day')) {
                currentEntry = e.data.leaveHours.find(element => moment(element.workDate).isSame(beginDate, 'date'));
                currentHours = null;

                if (currentEntry) {
                    currentHours = currentEntry.leaveHours;
                }

                leaveHoursDataSource.insert({ workDate: beginDate.format('MM/DD/YYYY'), leaveHours: currentHours });

                beginDate.add(1, 'days');
            }
        }
    }

    onInitNewRow = (e) => {
        this.rowKey = -1;

        leaveHoursDataSource.clear();

        // Set active value default to true
        e.data.statusCode = 'SUBMITTED';
        e.data.leaveTypeCode = 'UNPAID';
    }

    onSaved = (cell, e) => {
        // TODO: Maybe make this an actual value
        console.log('leave hours saved');
        cell.setValue(100);
    }

    customizeItem = (item) => {
        if (item.dataField === 'leaveHours') {
            let index = this.dataGrid.current.instance.getRowIndexByKey(this.rowKey);
            index = (index == -1) ? 0 : index;
            var leaveType = this.dataGrid.current.instance.cellValue(index, "leaveTypeCode");      

             console.log('Customize Item', item);
             console.log(leaveType);

            item.visible = leaveType != null && leaveType !== 'UNPAID';
        }
    }

    leaveHoursRender(cell) {
        //console.log('Leave Hours render', cell);
        let onSaved = this.onSaved.bind(this, cell);

        var canEdit = cell.data && cell.data.statusCode == 'SUBMITTED';

        return <DataGrid ref={ref => this.leaveHoursDataGrid = ref} dataSource={leaveHoursDataSource} onSaved={onSaved}>
            <Editing mode="cell" allowUpdating={true} selectTextOnEditStart={true} />
            <Sorting mode="none" />

            <Column caption="Date" dataField="workDate" dataType="datetime" format="MM/dd/yy" allowEditing={false} />
            <Column caption="Hours" dataField="leaveHours" dataType="number" allowEditing={canEdit} editorOptions={{ min: 0, max: 24 }} calculateCellValue={this.calculateCellValue} />
        </DataGrid>;
    }

    calculateCellValue = (e) => {
        if (!e.leaveHours) {
            return 0;
        }
        else {
            return e.leaveHours;
        }
    }

    onSaving = () => {
        //Prevents loss of edit when clicking save button without deselecting cell
        if (this.leaveHoursDataGrid) {
            this.leaveHoursDataGrid.instance.closeEditCell();
        }
    }

    render() {
        return (
            <div className="container">
                <h1>Leave Requests</h1>
                <div className="d-flex">
                    <div className="me-auto ms-3">
                        Request Status:{' '}
                        <CheckBox value={this.state.filter.submitted} id="submitted" text="Submitted" onValueChanged={this.handleChange} /> &nbsp;
                        <CheckBox value={this.state.filter.approved} id="approved" text="Approved" onValueChanged={this.handleChange} /> &nbsp;
                        <CheckBox value={this.state.filter.notapproved} id="notapproved" text="Not Approved" onValueChanged={this.handleChange} />
                    </div>
                </div>

                <br />
                <DataGrid dataSource={this.customDataSource}
                    showBorders={true} allowColumnResizing={true}
                    ref={this.dataGrid} onInitNewRow={this.onInitNewRow} onRowUpdating={this.onRowUpdating} onEditorPreparing={this.onEditorPreparing}
                    onRowValidating={this.onRowValidating} onEditingStart={this.onEditingStart} onExporting={this.onExporting} onSaving={this.onSaving}>

                    <Export enabled={true} fileName={"leaveRequests"} />
                    <Editing mode="popup" allowAdding={!this.IsReadOnly()} allowUpdating={!this.IsReadOnly()} allowDeleting={(e) => this.allowDeleting(e) && !this.IsReadOnly()}>
                        <Form customizeItem={this.customizeItem}>
                            <Item dataField="employeeId" />
                            <Item dataField="name" />
                            <Item dataField="disabledStartTime" />
                            <Item dataField="disabledEndTime" />
                            <Item dataField="leaveTypeCode" />
                            <Item dataField="statusCode" />
                            <Item dataField="leaveHours" />
                        </Form>
                    </Editing>

                    <FilterRow visible={true} />
                    <Paging defaultPageSize={10} />
                    <Pager showPageSizeSelector={true}
                        allowedPageSizes={[5, 10, 20]}
                        showInfo={true} />

                    <Column caption="Employee Name" dataField="employeeName">
                        <FormItem visible={false} />
                    </Column>
                    <Column caption="Employee Name" dataField="employeeId" visible={false}>
                        <RequiredRule />
                        <FormItem editorType="dxSelectBox" editorOptions={{ dataSource: this.state.employees, displayExpr: 'employeeName', valueExpr: 'employeeId', searchEnabled: true }} />
                    </Column>
                    <Column caption="Description" dataField="name">
                        <RequiredRule />
                        <StringLengthRule max={100} />
                    </Column>
                    <Column caption="Start Date" dataField="disabledStartTime" dataType="datetime" format="MM/dd/yy, HH:mm" setCellValue={this.updateDateRange} editorOptions={{ max: new Date(new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate())}}>
                        <RequiredRule />
                    </Column>
                    <Column caption="End Date" dataField="disabledEndTime" dataType="datetime" format="MM/dd/yy, HH:mm" setCellValue={this.updateDateRange} editorOptions={{ max: new Date(new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate()) }}>
                        <RequiredRule />
                    </Column>
                    <Column caption="Leave Type" dataField="leaveTypeCode" setCellValue={this.setStateValue}>
                        <RequiredRule />
                        <Lookup allowClearing={true} dataSource={this.state.leaveTypes} displayExpr="name" valueExpr="code" />
                    </Column>
                    <Column caption="Status" dataField="statusCode">
                        <RequiredRule />
                        <Lookup allowClearing={true} dataSource={this.state.statuses} displayExpr="statusName" valueExpr="statusCode" />
                    </Column>
                    <Column caption="Leave Hours" dataField="leaveHours" cellRender={LeaveHoursCellRender} editCellRender={this.leaveHoursRender}>
                        <FormItem colSpan={2} />
                    </Column>
                    <Column caption="Created By" dataField="createUser">
                        <FormItem visible={false} />
                    </Column>
                    <Column caption="Created At" dataField="createDate" dataType="datetime" format="MM/dd/yy, HH:mm">
                        <FormItem visible={false} />
                    </Column>
                    <Column caption="Updated By" dataField="updateDisplayUser">
                        <FormItem visible={false} />
                    </Column>
                    <Column caption="Updated At" dataField="updateDate" dataType="datetime" format="MM/dd/yy, HH:mm">
                        <FormItem visible={false} />
                    </Column>
                </DataGrid>
                <br />
            </div>
        );
    }
}