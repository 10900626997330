
import React from 'react';
import BaseComponent, { FetchAPIPromise } from '../BaseComponent';
import DataGrid, { FilterRow, Column, Pager, Paging } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import Button from 'devextreme-react/button';
import { Link } from 'react-router-dom';
import Form, { Item, Label, RequiredRule } from 'devextreme-react/form';
import { msalAuth } from '../../msal/MsalAuthProvider';
import { systemConfig } from '../SystemConfig';

export class CustomerSearchContainer extends BaseComponent {
    constructor(props) {
        super(props);
        this.formElement = React.createRef();

        this.state = {
            loading: false,
            userIsBulkImport: false,
            userIsExternalClient: false,

            filters: {
                searchText: ''
            }
        };
    }

    componentDidMount = async () => {
        super.componentDidMount();
        this.fetchData();
    }

    GetComponentPageName = () => {
        return ("Client Portal");
    }

    placementList = new CustomStore({
        onLoading: (loadOptions) => {
            console.log('Loading: ', loadOptions);

            loadOptions.userData["notDispatched"] = true;
            loadOptions.userData["dispatched"] = true;
            loadOptions.userData["addedOnSupport"] = true;
            loadOptions.userData["cancelled"] = true;
            loadOptions.userData["text"] = this.state.filters.searchText;
        },
        load: (loadOptions) => {
            console.log('Load: ' + loadOptions);

            var queryString = ' ';
            var queryOptions = Object.keys(loadOptions.userData);

            console.log(queryOptions);

            if (loadOptions.userData[queryOptions[4]]) {
                for (var i = 0; i < queryOptions.length; i++) {

                    if (queryString) {
                        queryString += '&';
                    }

                    queryString += queryOptions[i] + '=' + loadOptions.userData[queryOptions[i]];
                }
                console.log(queryString);

                return FetchAPIPromise('Placement/SearchCustomerPlacementHistory', queryString);
            }
        }
    });

    GetData = async () => {
        var userRoles = msalAuth.getActiveAccount().idTokenClaims.roles;

        var isBulkImport = userRoles.findIndex(element => element === 'BulkImport') > -1;
        var isExternalClient = userRoles.findIndex(element => element === 'ExternalClient') > -1;

        await this.setState({
            userIsBulkImport: isBulkImport,
            userIsExternalClient: isExternalClient
        });
    }

    SearchCases = (e) => {
        var result = this.editFormControl.instance.validate();

        if (result.isValid) {
            this.dataGrid.instance.refresh();
        }
    }

    render() {

        return (
            <div className="container">
                <h1>Client Portal</h1>

                <div className="d-flex justify-content-between">
                    <div className="ms-auto">
                        <Link to={"/Artifacts/Work Instruction - Importing Unaccompanied Child Travel Request into " + systemConfig.docCreationSystemName + ".docx"} target="_blank">
                            <Button icon="help" hint="Work Instruction" text="Work Instruction" />
                        </Link>
                        &nbsp;
                        <Link to="/Artifacts/MVM Travel Request.xlsx" target="_blank">
                            <Button icon="xlsfile" hint="Bulk Upload Template" text="Bulk Upload Template" />
                        </Link>
                    </div>
                </div>

                <br />
                <br />
                {this.state.userIsBulkImport &&
                    <div className="text-center">
                        <Link to="/upload-request/customer-portal">
                            <Button icon="plus" text="Upload Transport Request" />
                        </Link>
                    <br />
                    <br />
                    </div>
                }

                {this.state.userIsBulkImport &&
                    <div className="text-center">
                        <h4>- OR -</h4>
                        <br />
                    </div>
                }

                {this.state.userIsExternalClient &&
                    <div className="text-center">
                        <Link to="/initial-transport-request">
                            <Button icon="plus" text="Enter New Transport Request" />
                        </Link>
                        <br />
                        <br />
                    </div>
                }

                {this.state.userIsExternalClient &&
                    <div className="text-center">
                        <h4>- OR -</h4>
                        <br />
                    </div>
                }

                <Form
                    ref={ref => this.editFormControl = ref}
                    formData={this.state.filters} colCount={3}>
                    <Item colSpan={3} dataField="searchText" editorType="dxTextBox" editorOptions={{ placeholder: 'Search for A Number', onEnterKey: this.SearchCases, showClearButton: true }}>
                        <Label visible={false} />
                        <RequiredRule />
                    </Item>
                </Form>
                <br />

                <DataGrid dataSource={this.placementList} ref={ref => this.dataGrid = ref}
                    showBorders={true} allowColumnResizing={true}>
                    <FilterRow visible={true} />
                    <Paging defaultPageSize={10} />
                    <Pager showPageSizeSelector={true}
                        allowedPageSizes={[5, 10, 20]}
                        showInfo={true} />

                    <Column dataField="aNum" caption="A Number" />
                    <Column dataField="firstName" />
                    <Column dataField="middleName" />
                    <Column dataField="lastName" />
                    <Column dataField="gender" />
                    <Column dataField="dob" caption="DOB" format="M/d/yyyy" dataType="date" />
                    <Column dataField="nationality" />
                    <Column dataField="orrPlacementDate" caption="Placement Date" format="MM/d/yyyy HH:mm" dataType="datetime" />
                    <Column dataField="pickupLocationName" caption="Origin" />
                    <Column dataField="facilityName" caption="Destination" />
                    <Column dataField="historyStatus" caption="Dispatch Status" />
                </DataGrid>
            </div>
        );
    }
}