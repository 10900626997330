import React from 'react';
import BaseComponent, { FetchAPIPromise, PostAPIPromise } from '../BaseComponent';
import CustomStore from 'devextreme/data/custom_store';
import Form, { Item, Label } from 'devextreme-react/form';
import DataGrid, { FilterRow, FormItem, Form as GridForm, Column, Editing, Button as GridButton, MasterDetail } from 'devextreme-react/data-grid';
import Button from 'devextreme-react/button';
import { Popup } from 'devextreme-react/popup';
import * as $ from 'jquery'
import TextArea from 'devextreme-react/text-area';

export class FroschHistoryContainer extends BaseComponent {
    constructor(props) {
        super(props);

        this.state = {
            searchLimits: [20, 50, 100],
            searchStatuses: [],

            filters: {
                searchText: '',
                searchStatus: '',
                workOrderNumber: '',
                searchLimit: 20
            },

            itineraryInformation: '',
            itineraryInformationVisible: false,

            packageInformation: '',
            packageInformationVisible: false
        };
    }

    componentDidMount = async () => {
        super.componentDidMount();
        this.fetchData();
    }

    GetComponentPageName = () => {
        return ("Frosch History");
    }

    GetDropDownData = async () => {
        const statusData = await this.FetchAPI('DictionaryFroschApiStatus');

        this.setState({
            searchStatuses: statusData
        });
    }

    customDataSource = new CustomStore({
        key: 'id',
        onLoading: (loadOptions) => {
            //console.log('Loading', loadOptions);

            loadOptions.userData["searchText"] = this.state.filters.searchText;
            loadOptions.userData["searchStatus"] = this.state.filters.searchStatus;
            loadOptions.userData["workOrderNumber"] = this.state.filters.workOrderNumber;
            loadOptions.userData["searchLimit"] = this.state.filters.searchLimit;
        },
        load: (loadOptions) => {
            //console.log('Load', loadOptions);

            var queryString = '';
            var queryOptions = Object.keys(loadOptions.userData);

            //console.log(queryOptions);

            for (var i = 0; i < queryOptions.length; i++) {

                if (queryString) {
                    queryString += '&'
                }

                queryString += queryOptions[i] + '=' + loadOptions.userData[queryOptions[i]];
            }

            //console.log(queryString);

            return FetchAPIPromise('FroschItinerary/GetFroschHistory', queryString);
        },
        update: (key, values) => {
            //console.log('Updating', values);
            return PostAPIPromise('FroschItinerary/SaveFroschItinerary', values);
        }
    });

    onRowUpdating(options) {
        options.newData = $.extend({}, options.oldData, options.newData);
    }

    viewItinerary = (e) => {
        //console.log(e);

        this.setState({
            itineraryInformationVisible: true,
            itineraryInformation: e.row.data.itinerary
        });
    }

    hideItinerary = (e) => {
        this.setState({
            itineraryInformationVisible: false,
            itineraryInformation: ''
        });
    }

    search = async (e) => {
        //console.log(e);

        this.dataGrid.instance.refresh();
    }

    canReprocess = (e) => {
        // console.log(e);

        //return e.row.data.status == 'Error';
        return true;
    }

    reprocess = async (e) => {
        //console.log(e);

        await this.setState({
            loading: true
        });

        try {
            const result = await this.PutAPI('FroschItinerary?apiItineraryId=' + e.row.data.id);

            //console.log(result);

            if (result.status < 1) {
                alert('Failed. Please try again later.');
            }

            this.dataGrid.instance.refresh();
        }
        finally {
            await this.setState({
                loading: false
            });
        }
    }

    setIgnoreForwarded = async (e) => {
        //console.log(e);

        await this.setState({
            loading: true
        });

        try {
            const result = await this.PostAPI('FroschItinerary/IgnoreForwardItinerary?apiItineraryId=' + e.row.data.id);

            //console.log(result);

            if (result.status < 1) {
                alert('Failed. Please try again later.');
            }

            this.dataGrid.instance.refresh();
        }
        finally {
            await this.setState({
                loading: false
            });
        }
    }

    render() {

        return (
            <div className="container">
                <h1>Frosch History</h1>

                <Form ref={ref => this.filterForm = ref}
                    formData={this.state.filters} colCount={2}>
                    <Item dataField="searchText">
                        <Label text="Search Text" />
                    </Item>
                    <Item dataField="workOrderNumber" editorType="dxTextBox">
                        <Label text="Work Order #" />
                    </Item>                    

                    <Item dataField="searchStatus" editorType="dxSelectBox" editorOptions={{ dataSource: this.state.searchStatuses, searchEnabled: true, showClearButton: true, displayExpr: 'name', valueExpr: 'code' }}>
                        <Label text="Status" />
                    </Item>
                    <Item dataField="searchLimit" editorType="dxSelectBox" editorOptions={{ dataSource: this.state.searchLimits }}>
                        <Label text="Limit" />
                    </Item>
                </Form>
                <br />

                <div className="d-flex justify-content-between">
                    <div className="ms-auto">
                        <Button text="Search" icon="search" onClick={this.search} />
                    </div>
                </div>
                <br />

                <div className="row">
                    <DataGrid dataSource={this.customDataSource} onRowUpdating={this.onRowUpdating}
                        showBorders={true} allowColumnResizing={true}
                        keyExpr="id" ref={ref => this.dataGrid = ref}>
                        <Editing mode="popup" allowAdding={false} allowDeleting={false} allowUpdating={!this.IsReadOnly()}>
                            <GridForm>
                                <Item dataField="itinerary" colSpan={2} editorType="dxTextArea" editorOptions={{ height: 600 }} />
                            </GridForm>
                        </Editing>
                        <FilterRow visible={true} />

                        <Column dataField="id" caption="Id">
                            <FormItem visible={false} />
                        </Column>

                        <Column dataField="itinerary" caption="Itinerary" />
                        <Column dataField="packageType" caption="Package Type" />
                        <Column dataField="workOrderNumber" caption="Work Order #" />
                        <Column dataField="status" caption="Status">
                            <FormItem visible={false} />
                        </Column>
                        <Column dataField="message" caption="Message">
                            <FormItem visible={false} />
                        </Column>

                        <Column type="buttons">
                            <GridButton name="edit" visible={this.canReprocess} />
                            <GridButton text="View Itinerary" onClick={this.viewItinerary} />
                            <GridButton text="Reprocess" visible={this.canReprocess} onClick={this.reprocess} />
                            <GridButton text="Ignore" onClick={this.setIgnoreForwarded} />
                        </Column>

                        <MasterDetail enabled={true} component={PackageDetail} />
                    </DataGrid>
                    <br />
                </div>

                <Popup visible={this.state.itineraryInformationVisible} onHiding={this.hideItinerary} dragEnabled={false}
                    closeOnOutsideClick={true} showTitle={true} title="Itinerary Information">
                    <TextArea value={this.state.itineraryInformation} height={700} readOnly={true} />
                </Popup>
                <br />
            </div>
        );
    }
}

class PackageDetail extends React.Component {
    constructor(props) {
        //console.log('Detail Props', props);
        super(props);
        this.dataSource = [props.data.row.data.history];

        this.state = {
            packageInformation: '',
            packageInformationVisible: false
        };
    }

    viewPackage = (e) => {
        //console.log(e);

        this.setState({
            packageInformationVisible: true,
            packageInformation: e.row.data.package
        });
    }

    hidePackage = (e) => {
        this.setState({
            packageInformationVisible: false,
            packageInformation: ''
        });
    }

    render() {
        return (
            <div>
                <DataGrid dataSource={this.dataSource}
                    showBorders={true} allowColumnResizing={true}
                    keyExpr="id">

                    <Column dataField="id" caption="Id" />
                    <Column dataField="receivedDate" caption="Received Date" dataType="datetime" format="MM/dd/yy HH:mm" />
                    <Column dataField="package" caption="Package" />
                    <Column dataField="status" caption="Status" />
                    <Column dataField="message" caption="Message" />

                    <Column type="buttons">
                        <GridButton text="View Package" onClick={this.viewPackage} />
                    </Column>
                </DataGrid>

                <Popup visible={this.state.packageInformationVisible} onHiding={this.hidePackage} dragEnabled={false}
                    closeOnOutsideClick={true} showTitle={true} title="Package Information">
                    <TextArea value={this.state.packageInformation} height={700} readOnly={true} />
                </Popup>
            </div>
        );
    }
}