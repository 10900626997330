import React from 'react';
import PropTypes from 'prop-types';
import CustomStore from 'devextreme/data/custom_store';
import BaseComponent, { FetchAPIPromise, PostAPIPromise } from '../BaseComponent.js';
import DataGrid, { Column, GroupPanel, FilterRow, Selection, MasterDetail, Button as GridButton, Export } from 'devextreme-react/data-grid';
import { Editing } from 'devextreme-react/gantt';
import { msalAuth } from '../../msal/MsalAuthProvider';
import Popup from 'devextreme-react/popup';
import { ScrollView } from 'devextreme-react/scroll-view';
import Button from 'devextreme-react/button';
import Form, { Item, Label, RequiredRule, EmptyItem, ButtonItem } from 'devextreme-react/form';
import { Map as ReactMap } from 'devextreme-react/map';

export class WorkOrderFlightsContainer extends BaseComponent {
    static propTypes = {
        workOrder: PropTypes.object,
        itineraryItem: PropTypes.object
    };

    constructor(props) {
        super(props);
        this.workOrderName = props.workOrder.workOrderName;
        this.state = {
            userIsSysAdmin: false,

            flightData: {},

            customerList: [],
            employeeList: [],

            isAddingAdditionalBooking: false,
            additionalTravelerBookingInfo: {},

            isMovingEmployeeFlight: false,
            itineraryItemId: null,
            eligibleFlightCustomers: [],

            isMovingSupportFlight: false,
            eligibleFlightSupportStaff: [],

            isCorrectingEmployeeFlight: false,
            eligibleFlightEmployees: [],

            isMovingEmployeeWorkOrder: false,
            destinationWorkOrder: {
                workOrderNumber: ''
            }
        };

        this.key = {
            google: "AIzaSyDLhzLhxQLyaz3C_gigq8Rd9N8hC0yRT5c"
        };
    }

    componentDidUpdate(prevProps) {

        if (this.props.itineraryItem != prevProps.itineraryItem) // Check if it's a new user, you can also use some unique property, like the ID (this.props.user.id !== prevProps.user.id)
        {
            this.fetchData();
        }
    }

    GetDropDownData = async () => {
        var customerListData = await this.FetchAPI('BookTicket/GetCustomersByWorkOrder/' + this.props.workOrder.id);
        var employeeListData = await this.FetchAPI('WorkOrderResourcesEmployeeList/' + this.props.workOrder.id);

        await this.setState({
            customerList: customerListData,
            employeeList: employeeListData
        });
    }

    GetData = async () => {
        var userRoles = msalAuth.getActiveAccount().idTokenClaims.roles;

        var isUserSystemAdmin = userRoles.findIndex(element => element === 'SystemAdmin') > -1;
        //console.log('User ' + (isUserSystemAdmin ? 'IS' : 'IS NOT') + ' a Sys Admin');

        const flightData = await this.FetchAPI('Itinerary/GetFlightInformationById?flightId=' + this.props.itineraryItem.id);

        await this.setState({
            userIsSysAdmin: isUserSystemAdmin,

            flightData: flightData
        });
    }

    employeeFlightsStore = new CustomStore({
        key: 'employeeItineraryId',
        load: (loadOptions) => {
            //console.log('Load', loadOptions);
            //console.log(this.props.workOrder.id);

            if (this.state.flightData) {
                return FetchAPIPromise('WorkOrderEmployeeFlights/GetByFlightId/' + this.state.flightData.id);
            }
        },
        update: (key, values) => {
            return PostAPIPromise('WorkOrderEmployeeFlights?itineraryId=' + key);
        }
    });

    componentIsReadOnly = () => {
        return this.IsReadOnly() || this.props.workOrder.statusID > 1;
    }

    supportFlightsStore = new CustomStore({
        key: 'supportStaffItineraryId',
        load: (loadOptions) => {
            //console.log('Load', loadOptions);
            //console.log(this.props.workOrder.id);

            if (this.state.flightData) {
                return FetchAPIPromise('WorkOrderSupportStaffFlights/GetByFlightId/' + this.state.flightData.id);
            }
        },
        update: (key, values) => {
            return PostAPIPromise('WorkOrderSupportStaffFlights?itineraryId=' + key);
        }
    });

    setEmployeeFlightStatus = async (e) => {
        //console.log(e);

        await this.employeeFlightsStore.update(e.row.key, {});

        this.employeeFlightsDataGrid.instance.refresh();
    }

    setSupportFlightStatus = async (e) => {
        console.log(e);

        await this.supportFlightsStore.update(e.row.key, {});

        this.supportStaffFlightsDataGrid.instance.refresh();
    }

    canCancel = (options) => {
        return options.row.data && options.row.data.itineraryStatus != 'CANCELLED';
    }

    canActivate = (options) => {
        return options.row.data && options.row.data.itineraryStatus != 'ACTIVE' && options.row.data.bookingStatus == 'Confirmed';
    }

    isAssignEmployeeFlightVisible = (options) => {
        return options.row.data && !options.row.data.employeeID && this.state.userIsSysAdmin;
    }

    isMoveEmployeeFlightVisible = (options) => {
        return options.row.data && !options.row.data.employeeID && this.state.userIsSysAdmin;
    }

    onRowPrepared = (e) => {
        if (e.rowType == 'data' && e.data.itineraryStatus == 'CANCELLED') {
            e.rowElement.className = e.rowElement.className + ' cancelledRow';
        }
    }

    moveEmployeeFlight = async (e) => {
        //console.log(e);

        const customersData = await this.FetchAPI('WorkOrderResourcesCustomers/GetFlattenedCustomerList/' + this.props.workOrder.id);
        const inactiveCustomersData = await this.FetchAPI('WorkOrderResourcesCustomers/GetFlattenedInactiveCustomerList/' + this.props.workOrder.id);

        //console.log(customersData);
        //console.log(inactiveCustomersData);

        this.setState({
            isMovingEmployeeFlight: true,
            itineraryItemId: e.row.data.employeeItineraryId,
            eligibleFlightCustomers: customersData.concat(inactiveCustomersData)
        });
    }

    moveEmployeeWorkOrderFlight = async (e) => {
        //console.log(e);

        this.setState({
            isMovingEmployeeWorkOrder: true,
            itineraryItemId: e.row.data.employeeItineraryId
        });
    }

    moveSupportFlight = async (e) => {
        const employeesData = await this.FetchAPI('WorkOrderResourcesSupportStaffList/' + this.props.workOrder.id);

        console.log(employeesData);

        this.setState({
            isMovingSupportFlight: true,
            itineraryItemId: e.row.data.employeeItineraryId,
            eligibleFlightSupportStaff: employeesData
        });
    }

    correctEmployeeFlight = async (e) => {
        // console.log(e);

        const employeesData = await this.FetchAPI('WorkOrderResourcesEmployeeList/' + this.props.workOrder.id);
        const inactiveEmployeesData = await this.FetchAPI('WorkOrderResourcesEmployeeList/GetInactiveEmployees/' + this.props.workOrder.id);

        this.setState({
            isCorrectingEmployeeFlight: true,
            itineraryItemId: e.row.data.employeeItineraryId,
            eligibleFlightEmployees: employeesData.concat(inactiveEmployeesData)
        });
    }

    addAdditionalBookings = async (e) => {
        // console.log(e);

        //const employeesData = await this.FetchAPI('WorkOrderResourcesEmployeeList/' + this.props.workOrder.id);
        //const inactiveEmployeesData = await this.FetchAPI('WorkOrderResourcesEmployeeList/GetInactiveEmployees/' + this.props.workOrder.id);

        this.setState({
            isAddingAdditionalBooking: true,
            //itineraryItemId: e.row.data.employeeItineraryId,
            additionalTravelerBookingInfo: {}
            //eligibleFlightEmployees: employeesData.concat(inactiveEmployeesData)
        });
    }

    hideAdditionalBookingDialog = () => {
        this.setState({
            isAddingAdditionalBooking: false,
            additionalTravelerBookingInfo: {}
            //itineraryItemId: null,
            //eligibleFlightCustomers: []
        });
    }

    hideEmployeeMoveDialog = () => {
        this.setState({
            isMovingEmployeeFlight: false,
            itineraryItemId: null,
            eligibleFlightCustomers: []
        });
    }

    hideEmployeeMoveWorkOrderDialog = () => {
        this.setState({
            isMovingEmployeeWorkOrder: false,

            destinationWorkOrder: {
                workOrderNumber: ''
            }
        });
    }

    hideMoveSupportDialog = () => {
        this.setState({
            isMovingSupportFlight: false,
            itineraryItemId: null,
            eligibleFlightSupportStaff: []
        });
    }

    hideCorrectEmployeeDialog = () => {
        this.setState({
            isCorrectingEmployeeFlight: false,
            itineraryItemId: null,
            eligibleFlightEmployees: []
        });
    }

    saveEmployeeFlightMove = async () => {
        this.moveEmployeeFlightsDataGrid.instance.getSelectedRowsData().then(async (rowData) => {
            if (rowData.length == 0) {
                alert("Please select a Customer to move Flight to.");
            }
            else {
                var rowToMove = rowData[0];

                //console.log(rowToMove);

                var param = {
                    customerId: rowToMove.customerID,
                    itineraryItemId: this.state.itineraryItemId
                }

                var moveResult = await this.PostAPI('WorkOrderCustomerFlights/MoveEmployeeFlight', param);

                //console.log(moveResult);

                if (moveResult.status == -1) {
                    alert(moveResult.message);
                }
                else {
                    this.hideEmployeeMoveDialog();
                    this.employeeFlightsDataGrid.instance.refresh();
                    alert('Flight move Successful. Reload the page to view the updated data.');
                }
            }
        })
    }

    saveSupportFlightMove = async () => {
        this.moveSupportFlightsDataGrid.instance.getSelectedRowsData().then(async (rowData) => {
            if (rowData.length == 0) {
                alert("Please select a Support Staff to move Flight to.");
            }
            else {
                var rowToMove = rowData[0];

                //console.log(rowToMove);

                var param = {
                    travelerID: rowToMove.id,
                    itineraryItemId: this.state.itineraryItemId
                }

                var moveResult = await this.PostAPI('WorkOrderSupportStaffFlights/MoveSupportStaffFlight', param);

                if (moveResult.status == -1) {
                    alert(moveResult.message);
                }
                else {
                    this.hideMoveSupportDialog();
                    this.employeeFlightsDataGrid.instance.refresh();
                    this.supportStaffFlightsDataGrid.instance.refresh();
                }
            }
        })
    }

    saveEmployeeFlightCorrection = async () => {
        this.correctEmployeeFlightsDataGrid.instance.getSelectedRowsData().then(async (rowData) => {
            if (rowData.length == 0) {
                alert("Please select an Employee to correct Flight to.");
            }
            else {
                var rowToMove = rowData[0];

                console.log(rowToMove);

                var param = {
                    employeeId: rowToMove.employeeId,
                    itineraryItemId: this.state.itineraryItemId
                }

                var moveResult = await this.PostAPI('WorkOrderEmployeeFlights/CorrectEmployeeFlight', param);

                //console.log(moveResult);

                if (moveResult.status == -1) {
                    alert(moveResult.message);
                }
                else {
                    this.hideCorrectEmployeeDialog();
                    this.employeeFlightsDataGrid.instance.refresh();
                    alert('Flight correction Successful.');
                }
            }
        })
    }

    saveEmployeeFlightWorkOrderMove = async () => {

        var result = this.moveEmployeeWorkOrderForm.instance.validate();

        if (result.isValid) {

            var param = {
                itineraryItemId: this.state.itineraryItemId,
                destinationWorkOrderNumber: this.state.destinationWorkOrder.workOrderNumber
            };

            var moveResult = await this.PostAPI('WorkOrderEmployeeFlights/MoveWorkOrderFlight', param);

            console.log(moveResult);

            if (moveResult.status == -1) {
                alert(moveResult.message);
            }
            else {
                this.hideEmployeeMoveWorkOrderDialog();
                this.employeeFlightsDataGrid.instance.refresh();
            }
        }
    }

    saveAdditionalTravelers = async () => {
        var result = this.editCommercialFormControl.instance.validate();

        if (result.isValid) {

            var flightDetails = this.state.additionalTravelerBookingInfo;

            var param = {
                flightId: this.state.flightData.id,
                bookingInfo: flightDetails
            };

            console.log(flightDetails);

            if ((!flightDetails.customerList || flightDetails.customerList.length == 0) && (!flightDetails.employeeList || flightDetails.employeeList.length == 0)) {
                alert('Commercial Flight Itinerary must contain at least one Customer and/or Employee');
            } else {

                const result = await this.PostAPI('FroschItinerary/AddAdditionalTravelers', param);

                console.log(result);

                if (result.status == 1) {
                    await this.setState({
                        isAddingAdditionalBooking: false,
                        additionalTravelerBookingInfo: {}
                    });

                    this.customerFlightsDataGrid.instance.refresh();
                    this.employeeFlightsDataGrid.instance.refresh();
                }
                else if (result.status == -1) {
                    alert(result.message);
                }
                else {
                    //console.log(result);
                    alert('Failed. Please try again later.');
                }
                //    }
            }
        }
    }

    customerFlightsStore = new CustomStore({
        key: 'flightItineraryId',
        load: (loadOptions) => {
            //console.log('Load', loadOptions);
            //console.log(this.props.workOrder.id);

            if (this.props.workOrder.id) {
                return FetchAPIPromise('WorkOrderCustomerFlights/GetByFlightId/' + this.state.flightData.id);
            }
        }
        //update: (key, values) => {
        //    return PostAPIPromise('WorkOrderCustomerFlights?itineraryId=' + key);
        //}
    });

    isCustomersAssignFlightVisible = (options) => {
        return options.row.data && !options.row.data.customerID && this.state.userIsSysAdmin;
    }

    isCustomersMoveFlightVisible = (options) => {
        return options.row.data && !options.row.data.customerID && this.state.userIsSysAdmin;
    }

    isEmployeesMoveFlightVisible = (options) => {
        return options.row.data && !options.row.data.employeeID && this.state.userIsSysAdmin;
    }

    // TODO: Employee matching still not quite right
    render() {
        return (
            <>
                <Form formData={this.state.flightData} colCount={3}>
                    <Item itemType="group" caption={`${this.state.flightData.departureAirportCode} - ${this.state.flightData.arrivalAirportCode}`} colCount={2} colSpan={2}>
                        <Item dataField="flightNumber" editorOptions={{ readOnly: true }}>
                        </Item>
                        <EmptyItem />

                        <Item dataField="marketingCarrier" editorOptions={{ readOnly: true }}>
                        </Item>
                        <Item dataField="operatingCarrier" editorOptions={{ readOnly: true }}>
                        </Item>

                        <Item dataField="departureAirportCode" editorOptions={{ readOnly: true }}>
                        </Item>
                        <Item dataField="arrivalAirportCode" editorOptions={{ readOnly: true }}>
                        </Item>

                        <Item dataField="departureAirportName" editorOptions={{ readOnly: true }}>
                        </Item>
                        <Item dataField="arrivalAirportName" editorOptions={{ readOnly: true }}>
                        </Item>

                        <Item dataField="departureAirportTerminal" editorOptions={{ readOnly: true }}>
                        </Item>
                        <Item dataField="arrivalAirportTerminal" editorOptions={{ readOnly: true }}>
                        </Item>

                        <Item dataField="departureLocalTime" editorType="dxDateBox" editorOptions={{
                            displayFormat: "MM/dd/yyyy HH:mm", type: 'datetime', readOnly: true
                        }}>
                        </Item>
                        <Item dataField="arrivalLocalTime" editorType="dxDateBox" editorOptions={{
                            displayFormat: "MM/dd/yyyy HH:mm", type: 'datetime', readOnly: true
                        }}>
                        </Item>

                        {/*<Item dataField="locationFullAddress" colSpan={2} editorOptions={{ readOnly: true }}>*/}
                        {/*    <Label text="Address" />*/}
                        {/*</Item>*/}

                        {/*<Item colSpan={2}>*/}
                        {/*    <Label text="Primary POC" />*/}
                        {/*    {this.state.pickupLocationData.primaryPOCName ? `${this.state.pickupLocationData.primaryPOCName} ${this.state.pickupLocationData.primaryPOCPhone1}` : 'None Identified'}*/}
                        {/*</Item>*/}
                    </Item>

                    <Item>
                        <ReactMap
                            markers={[{
                                location: `${this.state.flightData.departureAirportName}`,
                                tooltip: {
                                    text: `${this.state.flightData.departureAirportName}`,
                                },
                            },
                            {
                                location: `${this.state.flightData.arrivalAirportName}`,
                                tooltip: {
                                    text: `${this.state.flightData.arrivalAirportName}`,
                                },
                            }]}
                            defaultZoom={12}
                            apiKey={this.key}
                            height={300}
                            width={300}
                            provider="google">
                        </ReactMap>
                    </Item>

                    <EmptyItem />
                    <EmptyItem />

                    <ButtonItem buttonOptions={{ text: 'Add Additional Traveler(s)', onClick: this.addAdditionalBookings }} />

                    <Item colSpan={3}>
                        <header><b>Customers</b></header>
                        <br />

                        <DataGrid ref={ref => this.customerFlightsDataGrid = ref} dataSource={this.customerFlightsStore} showBorders={true} allowColumnResizing={true} onRowPrepared={this.onRowPrepared}>
                            <FilterRow visible={true} />

                            <Column caption="Customer Name" dataField="travelerName" />
                            <Column caption="Booking Status" dataField="bookingStatus" />
                            <Column caption="Confirmation Number" dataField="confirmationNumber" />
                            <Column caption="Service Class" dataField="classOfService" />
                            <Column caption="Itinerary Status" dataField="itineraryStatus" />
                            <Column visible={!this.componentIsReadOnly()} type="buttons">
                                <GridButton
                                    text="Move"
                                    hint="Move"
                                    onClick={this.moveFlight}
                                    visible={this.isCustomersMoveFlightVisible}
                                />
                                <GridButton
                                    text="Move Work Order"
                                    hint="Move Work Order"
                                    onClick={this.moveWorkOrderFlight}
                                    visible={this.isCustomersMoveFlightVisible}
                                />
                                <GridButton
                                    text="Correct"
                                    hint="Correct"
                                    onClick={this.correctFlight}
                                    visible={this.isCustomersAssignFlightVisible}
                                />
                                <GridButton
                                    text="Cancel"
                                    hint="Cancel"
                                    onClick={this.setCustomerFlightStatus}
                                    visible={this.canCancel}
                                />
                                <GridButton
                                    text="Make Active"
                                    hint="Make Active"
                                    onClick={this.setCustomerFlightStatus}
                                    visible={this.canActivate}
                                />
                            </Column>
                        </DataGrid>
                    </Item>

                    <Item colSpan={3}>
                        <header><b>Employees</b></header>
                        <br />

                        <DataGrid ref={ref => this.employeeFlightsDataGrid = ref} dataSource={this.employeeFlightsStore} showBorders={true} allowColumnResizing={true} onRowPrepared={this.onRowPrepared}>
                            <FilterRow visible={true} />

                            <Column caption="Employee Name" dataField="travelerName" />
                            <Column caption="Booking Status" dataField="bookingStatus" />
                            <Column caption="Confirmation Number" dataField="confirmationNumber" />
                            <Column caption="Service Class" dataField="classOfService" />
                            <Column caption="Itinerary Status" dataField="itineraryStatus" />
                            <Column visible={!this.componentIsReadOnly()} type="buttons">
                                <GridButton
                                    text="Move as Customer"
                                    hint="Move as Customer"
                                    onClick={this.moveEmployeeFlight}
                                    visible={this.isEmployeesMoveFlightVisible}
                                />
                                <GridButton
                                    text="Move as Support"
                                    hint="Move as Support"
                                    onClick={this.moveSupportFlight}
                                    visible={this.isEmployeesMoveFlightVisible}
                                />
                                <GridButton
                                    text="Move Work Order"
                                    hint="Move Work Order"
                                    onClick={this.moveEmployeeWorkOrderFlight}
                                    visible={this.isEmployeesMoveFlightVisible}
                                />
                                <GridButton
                                    text="Correct"
                                    hint="Correct"
                                    onClick={this.correctEmployeeFlight}
                                    visible={this.isAssignEmployeeFlightVisible}
                                />
                                <GridButton
                                    text="Cancel"
                                    hint="Cancel"
                                    onClick={this.setEmployeeFlightStatus}
                                    visible={this.canCancel}
                                />
                                <GridButton
                                    text="Make Active"
                                    hint="Make Active"
                                    onClick={this.setEmployeeFlightStatus}
                                    visible={this.canActivate}
                                />
                            </Column>
                        </DataGrid>
                    </Item>

                    <Item colSpan={3}>
                        <header><b>Support Staff</b></header>
                        <br />

                        <DataGrid ref={ref => this.supportStaffFlightsDataGrid = ref} dataSource={this.supportFlightsStore} showBorders={true} allowColumnResizing={true} onRowPrepared={this.onRowPrepared}>
                            <FilterRow visible={true} />

                            <Column caption="Staff Name" dataField="travelerName" />
                            <Column caption="Booking Status" dataField="bookingStatus" />
                            <Column caption="Confirmation Number" dataField="confirmationNumber" />
                            <Column caption="Service Class" dataField="classOfService" />
                            <Column caption="Itinerary Status" dataField="itineraryStatus" />
                            <Column type="buttons">
                                <GridButton
                                    text="Cancel"
                                    hint="Cancel"
                                    onClick={this.setSupportFlightStatus}
                                    visible={this.canCancel}
                                />
                                <GridButton
                                    text="Make Active"
                                    hint="Make Active"
                                    onClick={this.setSupportFlightStatus}
                                    visible={this.canActivate}
                                />
                            </Column>
                        </DataGrid>
                    </Item>
                </Form>

                <Popup visible={this.state.isMovingEmployeeFlight} onHiding={this.hideEmployeeMoveDialog} dragEnabled={true}
                    closeOnOutsideClick={false} showTitle={false} width={800} height={475}>
                    <ScrollView height={375}>
                        <p>Select the Customer to apply this Flight to</p>
                        <br />
                        <DataGrid ref={ref => this.moveEmployeeFlightsDataGrid = ref} dataSource={this.state.eligibleFlightCustomers} showBorders={true} allowColumnResizing={true}>
                            <FilterRow visible={true} />
                            <Selection mode="single" selectAllMode="allPages" deferred={true} />

                            <Column caption="A#/Subject ID" dataField="comboIDDisplay" />
                            <Column caption="First Name" dataField="firstName" />
                            <Column caption="Middle Name" dataField="middleName" />
                            <Column caption="Last Name" dataField="lastName" />
                            <Column caption="Age" dataField="age" />
                            <Column caption="Gender" dataField="gender" />
                            <Column caption="Nationality" dataField="nationality" />
                        </DataGrid>
                    </ScrollView>
                    <br />

                    <div style={{ textAlign: 'center' }}>
                        <Button text="Save" onClick={this.saveEmployeeFlightMove.bind(this)} />
                        &nbsp;&nbsp;
                        <Button text="Cancel" onClick={this.hideEmployeeMoveDialog} />
                    </div>
                </Popup>

                <Popup visible={this.state.isCorrectingEmployeeFlight} onHiding={this.hideCorrectEmployeeDialog} dragEnabled={true}
                    closeOnOutsideClick={false} showTitle={false} width={800} height={475}>
                    <ScrollView height={375}>
                        <p>Select the Employee to correct this Flight to</p>
                        <br />
                        <DataGrid ref={ref => this.correctEmployeeFlightsDataGrid = ref} dataSource={this.state.eligibleFlightEmployees} showBorders={true} allowColumnResizing={true}>
                            <FilterRow visible={true} />
                            <Selection mode="single" selectAllMode="allPages" deferred={true} />

                            <Column caption="Employee No" dataField="employeeNo" />
                            <Column caption="First Name" dataField="firstName" />
                            <Column caption="Middle Name" dataField="middleName" />
                            <Column caption="Last Name" dataField="lastName" />
                            <Column caption="Gender" dataField="sex" />
                            <Column caption="Office" dataField="office" />
                        </DataGrid>
                    </ScrollView>
                    <br />

                    <div style={{ textAlign: 'center' }}>
                        <Button text="Save" onClick={this.saveEmployeeFlightCorrection.bind(this)} />
                        &nbsp;&nbsp;
                        <Button text="Cancel" onClick={this.hideCorrectEmployeeDialog} />
                    </div>
                </Popup>

                <Popup visible={this.state.isMovingSupportFlight} onHiding={this.hideMoveSupportDialog} dragEnabled={true}
                    closeOnOutsideClick={false} showTitle={false} width={800} height={475}>
                    <ScrollView height={375}>
                        <p>Select the Support Staff to move this Flight to</p>
                        <br />
                        <DataGrid ref={ref => this.moveSupportFlightsDataGrid = ref} dataSource={this.state.eligibleFlightSupportStaff} showBorders={true} allowColumnResizing={true}>
                            <FilterRow visible={true} />
                            <Selection mode="single" selectAllMode="allPages" deferred={true} />

                            <Column caption="First Name" dataField="firstName" />
                            <Column caption="Middle Name" dataField="middleName" />
                            <Column caption="Last Name" dataField="lastName" />
                        </DataGrid>
                    </ScrollView>
                    <br />

                    <div style={{ textAlign: 'center' }}>
                        <Button text="Save" onClick={this.saveSupportFlightMove.bind(this)} />
                        &nbsp;&nbsp;
                        <Button text="Cancel" onClick={this.hideMoveSupportDialog} />
                    </div>
                </Popup>

                <Popup visible={this.state.isMovingEmployeeWorkOrder} onHiding={this.hideEmployeeMoveWorkOrderDialog} dragEnabled={true}
                    closeOnOutsideClick={false} showTitle={false} width={800} height={475}>
                    <ScrollView height={375}>
                        <p>Enter the Work Order to move this Flight to</p>
                        <br />
                        <Form ref={ref => this.moveEmployeeWorkOrderForm = ref} formData={this.state.destinationWorkOrder}>
                            <Item editorType="dxTextBox" dataField="workOrderNumber">
                                <RequiredRule />
                            </Item>
                        </Form>
                    </ScrollView>
                    <br />

                    <div style={{ textAlign: 'center' }}>
                        <Button text="Save" onClick={this.saveEmployeeFlightWorkOrderMove.bind(this)} />
                        &nbsp;&nbsp;
                        <Button text="Cancel" onClick={this.hideEmployeeMoveWorkOrderDialog} />
                    </div>
                </Popup>

                <Popup visible={this.state.isAddingAdditionalBooking} onHiding={this.hideAdditionalBookingDialog} dragEnabled={true}
                    closeOnOutsideClick={false} showTitle={false} width={800} height={475}>
                    <ScrollView height={360}>
                        <Form ref={ref => this.editCommercialFormControl = ref}
                            id="form" formData={this.state.additionalTravelerBookingInfo} colCount={2}>

                            <Item editorType="dxTextBox" dataField="recordLocator" editorOptions={{ maxLength: 10 }}>
                                <Label text="Record Locator" />
                                <RequiredRule />
                            </Item>

                            <Item editorType="dxDateBox" dataField="reservationDate" editorOptions={{ type: 'datetime', displayFormat: "MM/dd/yyyy HH:mm" }}>
                                <Label text="Reservation Date" />
                                <RequiredRule />
                            </Item>

                            <Item editorType="dxTextBox" dataField="confirmationNumber" editorOptions={{ maxLength: 10 }}>
                                <Label text="Confirmation #" />
                                <RequiredRule />
                            </Item>

                            <Item editorType="dxTextBox" dataField="bookingStatus" editorOptions={{ maxLength: 20 }}>
                                <Label text="Booking Status" />
                                <RequiredRule />
                            </Item>

                            <Item editorType="dxTextBox" dataField="classOfService" editorOptions={{ maxLength: 20 }}>
                                <Label text="Class of Service" />
                                <RequiredRule />
                            </Item>

                            <EmptyItem />

                            <Item colSpan={2} editorType="dxTagBox" dataField="customerList" editorOptions={{ applyValueMode: 'useButtons', showSelectionControls: true, dataSource: this.state.customerList, displayExpr: 'display', valueExpr: 'customerID' }}>
                                <Label text="Customers" />
                            </Item>

                            <Item colSpan={2} editorType="dxTagBox" dataField="employeeList" editorOptions={{ applyValueMode: 'useButtons', showSelectionControls: true, dataSource: this.state.employeeList, displayExpr: 'displayValue', valueExpr: 'employeeId' }}>
                                <Label text="Employees" />
                            </Item>
                        </Form>
                    </ScrollView>
                    <br />

                    <div style={{ textAlign: 'center' }}>
                        <Button text="Save" onClick={this.saveAdditionalTravelers} />
                        &nbsp;&nbsp;
                        <Button text="Cancel" onClick={this.hideAdditionalBookingDialog} />
                    </div>
                </Popup>
            </>
        );
    }
}