import React from 'react';
import CustomStore from 'devextreme/data/custom_store';
import BaseComponent, { FetchAPIPromise, withParams } from '../BaseComponent';
import DataGrid, { FilterRow, Column, Pager, Paging, Export } from 'devextreme-react/data-grid';
import { Link } from 'react-router-dom';
import * as moment from 'moment';
import DateBox from 'devextreme-react/date-box';
import Button from 'devextreme-react/button';
import { CheckBox } from 'devextreme-react/check-box';
import TextBox from 'devextreme-react/text-box';

export class TransportSpecialistTripsContainer extends BaseComponent {
    static displayName = TransportSpecialistTripsContainer.name;

    constructor(props) {
        super(props);

        this.state = {
            filter: {
                fromDate: null,
                toDate: null,
                active: true,
                complete: false,
                cancelled: false,
                text: ""
            }
        };
    }

    GetComponentPageName = () => {
        return 'My Trips';
    }

    GetData = async () => {
        var today = moment();
        var from_date = today.clone().startOf('week');
        var to_date = today.clone().endOf('week');

        await this.setState({
            filter: {
                fromDate: from_date.format("MM/DD/YYYY HH:mm"),
                toDate: to_date.format("MM/DD/YYYY HH:mm"),
                active: true,
                complete: false,
                cancelled: false,
                text: ""
            }
        });
    }

    handleChange = (event) => {
        //console.log(event);
        this.setState(
            (state) => ({
                filter: {
                    ...state.filter,
                    [event.element.id]: !state.filter[event.element.id]
                }
            })
        );
    };

    handleDateChange = (event) => {
        //console.log(event);

        var newFromDate = moment(event.value);
        var newToDate = newFromDate.clone().endOf('week');

        this.setState(
            (state) => ({
                filter: {
                    ...state.filter,
                    fromDate: newFromDate.format("MM/DD/YYYY HH:mm"),
                    toDate: newToDate.format("MM/DD/YYYY HH:mm")
                }
            })
        );

        if (this.dataGrid) {
            this.dataGrid.instance.refresh();
        }
    };

    handleTextChange = async (event) => {
        await this.setState(
            (prevState) => ({
                filter: {
                    ...prevState.filter,
                    text: event.value
                }
            })
        );
    }

    isDisabledDate = (args) => {
        //console.log('Disabled date', args);

        if (args.view === "month") {
            var fromDate = moment().startOf('week');
            var checkDate = moment(args.date);
            return args.date.getDay() > 0 || fromDate.diff(checkDate, 'day') > 13 || fromDate.diff(checkDate, 'day') < 0;
        }

        return false;
    }

    search = () => {
        this.dataGrid.instance.refresh();
    }

    customDataSource = new CustomStore({
        key: 'id',
        onLoading: (loadOptions) => {
            //console.log('Loading', loadOptions);

            var activeFilterValue = this.state.filter.active;
            var completeFilterValue = this.state.filter.complete;
            var canceledFilterValue = this.state.filter.cancelled;

            if (!activeFilterValue && !completeFilterValue && !canceledFilterValue) {
                activeFilterValue = true;
                completeFilterValue = true;
                canceledFilterValue = true;
            }

            loadOptions.userData["fromDate"] = this.state.filter.fromDate;
            loadOptions.userData["toDate"] = this.state.filter.toDate;
            loadOptions.userData["active"] = activeFilterValue;
            loadOptions.userData["complete"] = completeFilterValue;
            loadOptions.userData["cancelled"] = canceledFilterValue;
            loadOptions.userData["text"] = this.state.filter.text;
        },
        load: (loadOptions) => {
            //console.log('Load', loadOptions);

            var queryString = '';
            var queryOptions = Object.keys(loadOptions.userData);

            //console.log(queryOptions);

            for (var i = 0; i < queryOptions.length; i++) {

                if (queryString) {
                    queryString += '&'
                }

                queryString += queryOptions[i] + '=' + loadOptions.userData[queryOptions[i]];
            }

            //console.log(queryString);

            return FetchAPIPromise('WorkOrder/SearchAssignedWorkOrdersForEmployee', queryString);
        }
    });

    render() {
        return (

            <div className="container-fluid">
                <h1>My Trips</h1>
                <br />
                <div className="d-flex justify-content-between">
                    <div className="d-flex me-auto align-items-center">
                        From &nbsp;<DateBox type="date" disabledDates={this.isDisabledDate} acceptCustomValue={false} onValueChanged={this.handleDateChange} value={this.state.filter.fromDate} />&nbsp;
                        To &nbsp;<DateBox type="date" readOnly={true} onValueChanged={this.handleChange} value={this.state.filter.toDate} />&nbsp;
                        Work Order Status:{' '}&nbsp;
                        <CheckBox value={this.state.filter.active} id="active" text="Active" onValueChanged={this.handleChange} /> &nbsp;
                        <CheckBox value={this.state.filter.complete} id="complete" text="Completed" onValueChanged={this.handleChange} />&nbsp;
                        <CheckBox value={this.state.filter.cancelled} id="cancelled" text="Cancelled" onValueChanged={this.handleChange} />&nbsp;&nbsp;&nbsp;
                        <TextBox value={this.state.filter.text} id="text" width={200} placeholder="Search by Work Order #" onValueChanged={this.handleTextChange} />&nbsp;&nbsp;&nbsp;
                        <Button text="Search" icon="search" onClick={this.search} />
                    </div>
                </div>

                <br />
                <DataGrid dataSource={this.customDataSource}
                    showBorders={true} allowColumnResizing={true}
                    ref={ref => this.dataGrid = ref} onExporting={this.onExporting}>
                    <Export enabled={true} fileName={"workOrderList"} />
                    <FilterRow visible={true} />
                    <Paging defaultPageSize={20} />
                    <Pager showPageSizeSelector={true}
                        allowedPageSizes={[5, 10, 20]}
                        showInfo={true} />
                    <Column caption="Number" dataField="number" dataType="string" />
                    <Column caption="Work Order Name" dataField="name" />
                    <Column caption="Start Date" dataField="planStartDate" dataType="datetime" format="MM/dd/yy HH:mm" />
                    <Column caption="End Date" dataField="planEndDate" dataType="datetime" format="MM/dd/yy HH:mm" />
                    <Column caption="Departure City" dataField="departureCity" />
                    <Column caption="Destination City" dataField="destinationCity" />
                    <Column caption="Status" dataField="status" />
                    <Column caption="Transport Type" dataField="transportMethod" />
                    <Column caption="Transport Method" dataField="transportType" />
                    <Column caption="Unit" dataField="transportUnit" />
                    <Column caption="Type" dataField="workOrderType" />
                </DataGrid>

                <br />
            </div>
        );
    }
}

export default withParams(TransportSpecialistTripsContainer);