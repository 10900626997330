import React from 'react';
import CustomStore from 'devextreme/data/custom_store';
import BaseComponent, { FetchAPIPromise, withParams } from '../BaseComponent';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from 'devextreme-react/button';
import DataGrid, { FilterRow, Column, Paging, Pager } from 'devextreme-react/data-grid';

class LinkToSupportContainer extends BaseComponent {
    static propTypes = {
        workOrderId: PropTypes.object
    };

    constructor(props) {
        super(props);
        this.state = ({
            workOrder: {}
        });
    }

    componentDidMount = async () => {
        super.componentDidMount();
        //console.log('ComponentDidMount');
        //console.log(this.props.params);
        //console.log(this.props.params.id);
        const { id } = this.props.params;

        await this.setState({ workOrderId: id });

        await this.fetchData();
    }

    GetComponentPageName = () => {
        return (this.state.workOrder.workOrderNumber ? ("#" + this.state.workOrder.workOrderNumber + " WO Link To Support") : 'WO Link To Support');
    }

    GetData = async () => {
        const workOrder = await this.FetchAPI('WorkOrder/' + this.state.workOrderId);
        console.log(workOrder);

        await this.setState({
            workOrder: workOrder
        });
    }

    supportWorkOrders = new CustomStore({
        key: "id",
        load: () => {
            return FetchAPIPromise('WorkOrder/GetSupportWorkOrders/' + this.state.workOrderId);
        }
    });

    linkSupportWorkOrders = async () => {
        var s = await this.dataGrid.instance.getSelectedRowKeys();
        if (s.length == 0) {
            alert("Please select workorder.");
        }
        else {
            var linkData = {
                supportWorkOrderIds: s,
                regularWorkOrderID: this.state.workOrderId
            };
            var result = await this.PostAPI('WorkOrder/SaveSupportWorkOrders', linkData);
            console.log(result);
            if (result.status == 1) {
                this.props.navigate('/workorder-detail/' + this.state.workOrderId);
            }
            else {
                alert('Failed. Please try again later.');
            }
        }
    }

    render() {
        return (
            <div className="container">
                <h1>Link to Support Work Order</h1>
                <br />

                <div className="row">
                    <div className="ms-auto me-3">
                        <label>Work Order:&nbsp;</label>
                        <b><label>{this.state.workOrder.workOrderDisplay}</label></b>
                    </div>
                </div>
                <br />

                <div className="row">
                    <DataGrid dataSource={this.supportWorkOrders} showBorders={true}
                        allowColumnResizing={true}
                        selection={{ mode: 'multiple', selectAllMode: 'allPages', showCheckBoxesMode: 'always', deferred: true }}
                        ref={ref => this.dataGrid = ref}>
                        <FilterRow visible={true} />
                        <Paging defaultPageSize={5} />
                        <Pager showPageSizeSelector={true}
                            allowedPageSizes={[5, 10, 20]}
                            showInfo={true} visible={true} />
                        <Column caption="Work Order Number" dataField="workOrderNumber" />
                        <Column caption="Work Order Name" dataField="workOrderName" />
                        <Column caption="Start Date" dataField="planStartDate" dataType="datetime" format="MM/dd/yy, HH:mm" />
                        <Column caption="End Date" dataField="planEndDate" dataType="datetime" format="MM/dd/yy, HH:mm" />
                        <Column caption="Departure City" dataField="departureCityName" />
                        <Column caption="Destination City" dataField="destinationCityName" />
                    </DataGrid>
                </div>
                <br />

                <div className="d-flex">
                    <div className="ms-auto me-3">
                        <Link to={`/workorder-detail/${this.state.workOrderId}`}>
                            <Button text="Back" type="normal" stylingMode="contained" />
                        </Link>
                        &nbsp;
                        <Button text="Link to Support Work Order" type="default" stylingMode="contained" onClick={this.linkSupportWorkOrders} />
                    </div>
                </div>
            </div>
        );
    }
}

export default withParams(LinkToSupportContainer);