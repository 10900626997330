import React from 'react';
import PropTypes from 'prop-types';
import CustomStore from 'devextreme/data/custom_store';
import BaseComponent, { FetchAPIPromise, PostAPIPromise } from '../BaseComponent.js';
import DataGrid, { Column, GroupPanel, FilterRow, Selection, MasterDetail, Button as GridButton, Export } from 'devextreme-react/data-grid';
import { Editing } from 'devextreme-react/gantt';
import { msalAuth } from '../../msal/MsalAuthProvider';
import Popup from 'devextreme-react/popup';
import { ScrollView } from 'devextreme-react/scroll-view';
import Button from 'devextreme-react/button';
import Form, { Item, RequiredRule } from 'devextreme-react/form';

export class WorkOrderEmployeeFlightsContainer extends BaseComponent {
    static propTypes = {
        workOrder: PropTypes.object
    };

    constructor(props) {
        super(props);
        this.workOrderName = props.workOrder.workOrderName;
        this.state = {
            userIsSysAdmin: false,

            isMovingFlight: false,
            itineraryItemId: null,
            eligibleFlightCustomers: [],

            isMovingSupportFlight: false,
            eligibleFlightSupportStaff: [],

            isMovingEmployeeFlight: false,

            isCorrectingFlight: false,
            eligibleFlightEmployees: [],

            isMergingEmployeeFlight: false,
            overwritingMergeItem: [],
            eligibleMergeFlights: [],

            isMovingWorkOrder: false,
            destinationWorkOrder: {
                workOrderNumber: ''
            },

            isCorrectingSupportFlight: false
        };
    }

    GetData = async () => {
        var userRoles = msalAuth.getActiveAccount().idTokenClaims.roles;

        var isUserSystemAdmin = userRoles.findIndex(element => element === 'SystemAdmin') > -1;
        //console.log('User ' + (isUserSystemAdmin ? 'IS' : 'IS NOT') + ' a Sys Admin');

        await this.setState({
            userIsSysAdmin: isUserSystemAdmin
        });
    }

    flightsStore = new CustomStore({
        key: 'employeeItineraryId',
        load: (loadOptions) => {
            //console.log('Load', loadOptions);
            //console.log(this.props.workOrder.id);

            if (this.props.workOrder.id) {
                return FetchAPIPromise('WorkOrderEmployeeFlights/' + this.props.workOrder.id);
            }
        },
        update: (key, values) => {
            return PostAPIPromise('WorkOrderEmployeeFlights?itineraryId=' + key);
        }
    });

    componentIsReadOnly = () => {
        return this.IsReadOnly() || this.props.workOrder.statusID > 1;
    }

    supportFlightsStore = new CustomStore({
        key: 'supportStaffItineraryId',
        load: (loadOptions) => {
            //console.log('Load', loadOptions);
            //console.log(this.props.workOrder.id);

            if (this.props.workOrder.id) {
                return FetchAPIPromise('WorkOrderSupportStaffFlights/' + this.props.workOrder.id);
            }
        },
        update: (key, values) => {
            return PostAPIPromise('WorkOrderSupportStaffFlights?itineraryId=' + key);
        }
    });

    setFlightStatus = async (e) => {
        //console.log(e);

        await this.flightsStore.update(e.row.key, {});

        this.dataGrid.instance.refresh();
    }

    setSupportFlightStatus = async (e) => {
        console.log(e);

        await this.supportFlightsStore.update(e.row.key, {});

        this.supportStaffFlightsDataGrid.instance.refresh();
    }

    canCancel = (options) => {
        return options.row.data && options.row.data.itineraryStatus != 'CANCELLED';
    }

    canActivate = (options) => {
        return options.row.data && options.row.data.itineraryStatus != 'ACTIVE';
    }

    isAssignFlightVisible = (options) => {
        return options.row.data && !options.row.data.employeeID && this.state.userIsSysAdmin;
    }

    isUnlinkFlightVisible = (options) => {
        return options.row.data && options.row.data.employeeID && this.state.userIsSysAdmin;
    }

    isAssignSupportFlightVisible = (options) => {
        return options.row.data && !options.row.data.travelerID && this.state.userIsSysAdmin;
    }

    isMoveFlightVisible = (options) => {
        return options.row.data && !options.row.data.employeeID && this.state.userIsSysAdmin;
    }

    isMoveEmployeeVisible = (options) => {
        return options.row.data && !options.row.data.travelerID && this.state.userIsSysAdmin;
    }

    onRowPrepared = (e) => {
        if (e.rowType == 'data' && e.data.itineraryStatus == 'CANCELLED') {
            e.rowElement.className = e.rowElement.className + ' cancelledRow';
        }
    }

    moveFlight = async (e) => {
        //console.log(e);

        const customersData = await this.FetchAPI('WorkOrderResourcesCustomers/GetFlattenedCustomerList/' + this.props.workOrder.id);
        const inactiveCustomersData = await this.FetchAPI('WorkOrderResourcesCustomers/GetFlattenedInactiveCustomerList/' + this.props.workOrder.id);

        //console.log(customersData);
        //console.log(inactiveCustomersData);

        this.setState({
            isMovingFlight: true,
            itineraryItemId: e.row.data.employeeItineraryId,
            eligibleFlightCustomers: customersData.concat(inactiveCustomersData)
        });
    }

    moveWorkOrderFlight = async (e) => {
        //console.log(e);

        this.setState({
            isMovingWorkOrder: true,
            itineraryItemId: e.row.data.employeeItineraryId
        });
    }

    moveSupportFlight = async (e) => {
        const employeesData = await this.FetchAPI('WorkOrderResourcesSupportStaffList/' + this.props.workOrder.id);
        const inactiveEmployeesData = await this.FetchAPI('WorkOrderResourcesSupportStaffList/GetInactiveEmployees/' + this.props.workOrder.id);

        this.setState({
            isMovingSupportFlight: true,
            itineraryItemId: e.row.data.employeeItineraryId,
            eligibleFlightSupportStaff: employeesData.concat(inactiveEmployeesData)
        });
    }

    moveEmployeeFlight = async (e) => {
        const employeesData = await this.FetchAPI('WorkOrderResourcesEmployeeList/' + this.props.workOrder.id);
        const inactiveEmployeesData = await this.FetchAPI('WorkOrderResourcesEmployeeList/GetInactiveEmployees/' + this.props.workOrder.id);

        this.setState({
            isMovingEmployeeFlight: true,
            itineraryItemId: e.row.data.supportStaffItineraryId,
            eligibleFlightEmployees: employeesData.concat(inactiveEmployeesData)
        });
    }

    correctFlight = async (e) => {
        // console.log(e);

        const employeesData = await this.FetchAPI('WorkOrderResourcesEmployeeList/' + this.props.workOrder.id);
        const inactiveEmployeesData = await this.FetchAPI('WorkOrderResourcesEmployeeList/GetInactiveEmployees/' + this.props.workOrder.id);

        this.setState({
            isCorrectingFlight: true,
            itineraryItemId: e.row.data.employeeItineraryId,
            eligibleFlightEmployees: employeesData.concat(inactiveEmployeesData)
        });
    }

    mergeFlight = async (e) => {
         console.log(e);

        const flightsData = await this.FetchAPI('WorkOrderEmployeeFlights/' + this.props.workOrder.id);

        this.setState({
            isMergingEmployeeFlight: true,
            overwritingMergeItem: [e.row.data],
            eligibleMergeFlights: flightsData
        });
    }

    unlinkFlight = async (e) => {
        // console.log(e);

        var result = await this.PostAPI('WorkOrderEmployeeFlights/UnlinkFlight?itineraryId=' + e.row.data.employeeItineraryId);

        if (result.status == -1) {
            alert(result.message);
        }
        else {
            this.dataGrid.instance.refresh();
            alert('Flight unlink Successful.');
        }
    }

    correctSupportFlight = async (e) => {
        // console.log(e);

        const employeesData = await this.FetchAPI('WorkOrderResourcesSupportStaffList/' + this.props.workOrder.id);
        const inactiveEmployeesData = await this.FetchAPI('WorkOrderResourcesSupportStaffList/GetInactiveEmployees/' + this.props.workOrder.id);

        this.setState({
            isCorrectingSupportFlight: true,
            itineraryItemId: e.row.data.supportStaffItineraryId,
            eligibleFlightEmployees: employeesData.concat(inactiveEmployeesData)
        });
    }

    hideMoveDialog = () => {
        this.setState({
            isMovingFlight: false,
            itineraryItemId: null,
            eligibleFlightCustomers: []
        });
    }

    hideMoveEmployeeDialog = () => {
        this.setState({
            isMovingEmployeeFlight: false,
            itineraryItemId: null,
            eligibleFlightEmployees: []
        });
    }

    hideMergeEmployeeDialog = () => {
        this.setState({
            isMergingEmployeeFlight: false,
            overwritingMergeItem: [],
            eligibleMergeFlights: []
        });
    }

    hideMoveWorkOrderDialog = () => {
        this.setState({
            isMovingWorkOrder: false,

            destinationWorkOrder: {
                workOrderNumber: ''
            }
        });
    }

    hideMoveSupportDialog = () => {
        this.setState({
            isMovingSupportFlight: false,
            itineraryItemId: null,
            eligibleFlightSupportStaff: []
        });
    }

    hideCorrectDialog = () => {
        this.setState({
            isCorrectingFlight: false,
            itineraryItemId: null,
            eligibleFlightEmployees: []
        });
    }

    hideCorrectSupportDialog = () => {
        this.setState({
            isCorrectingSupportFlight: false,
            itineraryItemId: null,
            eligibleFlightEmployees: []
        });
    }

    saveFlightMove = async () => {
        this.moveFlightsDataGrid.instance.getSelectedRowsData().then(async (rowData) => {
            if (rowData.length == 0) {
                alert("Please select a Customer to move Flight to.");
            }
            else {
                var rowToMove = rowData[0];

                //console.log(rowToMove);

                var param = {
                    customerId: rowToMove.customerID,
                    itineraryItemId: this.state.itineraryItemId
                }

                var moveResult = await this.PostAPI('WorkOrderCustomerFlights/MoveEmployeeFlight', param);

                //console.log(moveResult);

                if (moveResult.status == -1) {
                    alert(moveResult.message);
                }
                else {
                    this.hideMoveDialog();
                    this.dataGrid.instance.refresh();
                    alert('Flight move Successful. Reload the page to view the updated data.');
                }
            }
        })
    }

    saveSupportFlightMove = async () => {
        this.moveSupportFlightsDataGrid.instance.getSelectedRowsData().then(async (rowData) => {
            if (rowData.length == 0) {
                alert("Please select a Support Staff to move Flight to.");
            }
            else {
                var rowToMove = rowData[0];

                //console.log(rowToMove);

                var param = {
                    travelerID: rowToMove.id,
                    itineraryItemId: this.state.itineraryItemId
                }

                var moveResult = await this.PostAPI('WorkOrderSupportStaffFlights/MoveSupportStaffFlight', param);

                if (moveResult.status == -1) {
                    alert(moveResult.message);
                }
                else {
                    this.hideMoveSupportDialog();
                    this.dataGrid.instance.refresh();
                    this.supportStaffFlightsDataGrid.instance.refresh();
                }
            }
        })
    }

    saveFlightCorrection = async () => {
        this.correctFlightsDataGrid.instance.getSelectedRowsData().then(async (rowData) => {
            if (rowData.length == 0) {
                alert("Please select an Employee to correct Flight to.");
            }
            else {
                var rowToMove = rowData[0];

                console.log(rowToMove);

                var param = {
                    employeeId: rowToMove.employeeId,
                    itineraryItemId: this.state.itineraryItemId
                }

                var moveResult = await this.PostAPI('WorkOrderEmployeeFlights/CorrectEmployeeFlight', param);

                //console.log(moveResult);

                if (moveResult.status == -1) {
                    alert(moveResult.message);
                }
                else {
                    this.hideCorrectDialog();
                    this.dataGrid.instance.refresh();
                    alert('Flight correction Successful.');
                }
            }
        })
    }

    saveSupportFlightCorrection = async () => {
        this.correctSupportFlightsDataGrid.instance.getSelectedRowsData().then(async (rowData) => {
            if (rowData.length == 0) {
                alert("Please select a Support Staff to correct Flight to.");
            }
            else {
                var rowToMove = rowData[0];

                console.log(rowToMove);

                var param = {
                    travelerID: rowToMove.id,
                    itineraryItemId: this.state.itineraryItemId
                }

                var moveResult = await this.PostAPI('WorkOrderSupportStaffFlights/CorrectSupportStaffFlight', param);

                //console.log(moveResult);

                if (moveResult.status == -1) {
                    alert(moveResult.message);
                }
                else {
                    this.hideCorrectSupportDialog();
                    this.supportStaffFlightsDataGrid.instance.refresh();
                    alert('Flight correction Successful.');
                }
            }
        })
    }

    saveEmployeeFlightMove = async () => {
        this.moveEmployeeFlightsDataGrid.instance.getSelectedRowsData().then(async (rowData) => {
            if (rowData.length == 0) {
                alert("Please select an Employee to move Flight to.");
            }
            else {
                var rowToMove = rowData[0];

                //console.log(rowToMove);

                var param = {
                    employeeID: rowToMove.employeeId,
                    itineraryItemId: this.state.itineraryItemId
                }

                var moveResult = await this.PostAPI('WorkOrderEmployeeFlights/MoveSupportStaffFlight', param);

                if (moveResult.status == -1) {
                    alert(moveResult.message);
                }
                else {
                    this.hideMoveEmployeeDialog();
                    this.dataGrid.instance.refresh();
                    this.supportStaffFlightsDataGrid.instance.refresh();
                }
            }
        })
    }

    saveEmployeeFlightMerge = async (forceMerge) => {
        this.mergeEmployeeFlightsGrid.instance.getSelectedRowsData().then(async (rowData) => {
            if (rowData.length == 0) {
                alert("Please select a Flight to Merge Flight to.");
            }
            else {
                var rowToMerge = rowData[0];

                //console.log(rowToMove);

                var param = {
                    sourceItineraryItemId: this.state.overwritingMergeItem[0].employeeItineraryId,
                    destinationItineraryItemId: rowToMerge.employeeItineraryId,
                    isForcing: forceMerge
                };

                var moveResult = await this.PostAPI('WorkOrderEmployeeFlights/MergeEmployeeFlight', param);

                if (moveResult.status == -1) {
                    alert(moveResult.message);
                }
                else {
                    this.hideMergeEmployeeDialog();
                    this.dataGrid.instance.refresh();
                    this.supportStaffFlightsDataGrid.instance.refresh();
                }
            }
        })
    }

    saveFlightWorkOrderMove = async () => {

        var result = this.moveWorkOrderForm.instance.validate();

        if (result.isValid) {

            var param = {
                itineraryItemId: this.state.itineraryItemId,
                destinationWorkOrderNumber: this.state.destinationWorkOrder.workOrderNumber
            };

            var moveResult = await this.PostAPI('WorkOrderEmployeeFlights/MoveWorkOrderFlight', param);

            console.log(moveResult);

            if (moveResult.status == -1) {
                alert(moveResult.message);
            }
            else {
                this.hideMoveWorkOrderDialog();
                this.dataGrid.instance.refresh();
            }
        }
    }

    // TODO: Employee matching still not quite right
    render() {
        return (
            <div style={{ margin: "10px" }}>
                <DataGrid ref={ref => this.dataGrid = ref} dataSource={this.flightsStore} showBorders={true} allowColumnResizing={true} onRowPrepared={this.onRowPrepared} onExporting={this.onExporting}>
                    <Export enabled={true} fileName={"employeeFlights" + this.workOrderName} />
                    <Editing mode="row" allowUpdating={!this.componentIsReadOnly()} />
                    <GroupPanel visible={true} />

                    <Column caption="Employee Name" dataField="travelerName" groupIndex={0} />
                    <Column caption="Booking Status" dataField="bookingStatus" />
                    <Column caption="Flight Number" dataField="flightNumber" />
                    <Column caption="Departure Airport Code" dataField="departureAirportCode" />
                    <Column caption="Departure Time" dataField="departureLocalTime" dataType="datetime" format="MM/dd/yy, HH:mm" />
                    <Column caption="Arrival Airport Code" dataField="arrivalAirportCode" />
                    <Column caption="Arrival Time" dataField="arrivalLocalTime" dataType="datetime" format="MM/dd/yy, HH:mm" />
                    <Column caption="Itinerary Status" dataField="itineraryStatus" />
                    <Column visible={!this.componentIsReadOnly()} type="buttons">
                        <GridButton
                            text="Move as Customer"
                            hint="Move as Customer"
                            onClick={this.moveFlight}
                            visible={this.isMoveFlightVisible}
                        />
                        <GridButton
                            text="Move as Support"
                            hint="Move as Support"
                            onClick={this.moveSupportFlight}
                            visible={this.isMoveFlightVisible}
                        />
                        <GridButton
                            text="Move Work Order"
                            hint="Move Work Order"
                            onClick={this.moveWorkOrderFlight}
                            visible={this.isMoveFlightVisible}
                        />
                        <GridButton
                            text="Correct"
                            hint="Correct"
                            onClick={this.correctFlight}
                            visible={this.isAssignFlightVisible}
                        />
                        <GridButton
                            text="Unlink"
                            hint="Unlink"
                            onClick={this.unlinkFlight}
                            visible={this.isUnlinkFlightVisible}
                        />
                        <GridButton
                            text="Merge"
                            hint="Merge"
                            onClick={this.mergeFlight}
                        />
                        <GridButton
                            text="Cancel"
                            hint="Cancel"
                            onClick={this.setFlightStatus}
                            visible={this.canCancel}
                        />
                        <GridButton
                            text="Make Active"
                            hint="Make Active"
                            onClick={this.setFlightStatus}
                            visible={this.canActivate}
                        />
                    </Column>
                    <MasterDetail enabled={true} component={FlightDetail} />
                </DataGrid>
                <br />

                <header><b>Support Staff Flights</b></header>
                <br />

                <DataGrid ref={ref => this.supportStaffFlightsDataGrid = ref} dataSource={this.supportFlightsStore} showBorders={true} allowColumnResizing={true} onRowPrepared={this.onRowPrepared} onExporting={this.onExporting}>
                    <Export enabled={true} fileName={"supportStaffFlights" + this.workOrderName} />
                    <Editing mode="row" allowUpdating={true} />
                    <GroupPanel visible={true} />

                    <Column caption="Employee Name" dataField="travelerName" groupIndex={0} />
                    <Column caption="Booking Status" dataField="bookingStatus" />
                    <Column caption="Flight Number" dataField="flightNumber" />
                    <Column caption="Departure Airport Code" dataField="departureAirportCode" />
                    <Column caption="Departure Time" dataField="departureLocalTime" dataType="datetime" format="MM/dd/yy, HH:mm" />
                    <Column caption="Arrival Airport Code" dataField="arrivalAirportCode" />
                    <Column caption="Arrival Time" dataField="arrivalLocalTime" dataType="datetime" format="MM/dd/yy, HH:mm" />
                    <Column caption="Itinerary Status" dataField="itineraryStatus" />
                    <Column type="buttons">
                        <GridButton
                            text="Move as Employee"
                            hint="Move as Employee"
                            onClick={this.moveEmployeeFlight}
                            visible={this.isMoveEmployeeVisible}
                        />
                        <GridButton
                            text="Correct"
                            hint="Correct"
                            onClick={this.correctSupportFlight}
                            visible={this.isAssignSupportFlightVisible}
                        />
                        <GridButton
                            text="Cancel"
                            hint="Cancel"
                            onClick={this.setSupportFlightStatus}
                            visible={this.canCancel}
                        />
                        <GridButton
                            text="Make Active"
                            hint="Make Active"
                            onClick={this.setSupportFlightStatus}
                            visible={this.canActivate}
                        />
                    </Column>
                    <MasterDetail enabled={true} component={FlightDetail} />
                </DataGrid>

                <Popup visible={this.state.isMovingFlight} onHiding={this.hideMoveDialog} dragEnabled={true}
                    closeOnOutsideClick={false} showTitle={false} width={800} height={475}>
                    <ScrollView height={375}>
                        <p>Select the Customer to apply this Flight to</p>
                        <br />
                        <DataGrid ref={ref => this.moveFlightsDataGrid = ref} dataSource={this.state.eligibleFlightCustomers} showBorders={true} allowColumnResizing={true}>
                            <FilterRow visible={true} />
                            <Selection mode="single" selectAllMode="allPages" deferred={true} />

                            <Column caption="A#/Subject ID" dataField="comboIDDisplay" />
                            <Column caption="First Name" dataField="firstName" />
                            <Column caption="Middle Name" dataField="middleName" />
                            <Column caption="Last Name" dataField="lastName" />
                            <Column caption="Age" dataField="age" />
                            <Column caption="Gender" dataField="gender" />
                            <Column caption="Nationality" dataField="nationality" />
                        </DataGrid>
                    </ScrollView>
                    <br />

                    <div style={{ textAlign: 'center' }}>
                        <Button text="Save" onClick={this.saveFlightMove.bind(this)} />
                        &nbsp;&nbsp;
                        <Button text="Cancel" onClick={this.hideMoveDialog} />
                    </div>
                </Popup>

                <Popup visible={this.state.isMovingEmployeeFlight} onHiding={this.hideMoveEmployeeDialog} dragEnabled={true}
                    closeOnOutsideClick={false} showTitle={false} width={800} height={475}>
                    <ScrollView height={375}>
                        <p>Select the Employee to apply this Flight to</p>
                        <br />
                        <DataGrid ref={ref => this.moveEmployeeFlightsDataGrid = ref} dataSource={this.state.eligibleFlightEmployees} showBorders={true} allowColumnResizing={true}>
                            <FilterRow visible={true} />
                            <Selection mode="single" selectAllMode="allPages" deferred={true} />

                            <Column caption="Employee No" dataField="employeeNo" />
                            <Column caption="First Name" dataField="firstName" />
                            <Column caption="Middle Name" dataField="middleName" />
                            <Column caption="Last Name" dataField="lastName" />
                            <Column caption="Gender" dataField="sex" />
                            <Column caption="Office" dataField="office" />
                        </DataGrid>
                    </ScrollView>
                    <br />

                    <div style={{ textAlign: 'center' }}>
                        <Button text="Save" onClick={this.saveEmployeeFlightMove.bind(this)} />
                        &nbsp;&nbsp;
                        <Button text="Cancel" onClick={this.hideMoveEmployeeDialog} />
                    </div>
                </Popup>

                <Popup visible={this.state.isCorrectingFlight} onHiding={this.hideCorrectDialog} dragEnabled={true}
                    closeOnOutsideClick={false} showTitle={false} width={800} height={475}>
                    <ScrollView height={375}>
                        <p>Select the Employee to correct this Flight to</p>
                        <br />
                        <DataGrid ref={ref => this.correctFlightsDataGrid = ref} dataSource={this.state.eligibleFlightEmployees} showBorders={true} allowColumnResizing={true}>
                            <FilterRow visible={true} />
                            <Selection mode="single" selectAllMode="allPages" deferred={true} />

                            <Column caption="Employee No" dataField="employeeNo" />
                            <Column caption="First Name" dataField="firstName" />
                            <Column caption="Middle Name" dataField="middleName" />
                            <Column caption="Last Name" dataField="lastName" />
                            <Column caption="Gender" dataField="sex" />
                            <Column caption="Office" dataField="office" />
                        </DataGrid>
                    </ScrollView>
                    <br />

                    <div style={{ textAlign: 'center' }}>
                        <Button text="Save" onClick={this.saveFlightCorrection.bind(this)} />
                        &nbsp;&nbsp;
                        <Button text="Cancel" onClick={this.hideCorrectDialog} />
                    </div>
                </Popup>

                <Popup visible={this.state.isCorrectingSupportFlight} onHiding={this.hideCorrectSupportDialog} dragEnabled={true}
                    closeOnOutsideClick={false} showTitle={false} width={800} height={475}>
                    <ScrollView height={375}>
                        <p>Select the Support Staff to correct this Flight to</p>
                        <br />
                        <DataGrid ref={ref => this.correctSupportFlightsDataGrid = ref} dataSource={this.state.eligibleFlightEmployees} showBorders={true} allowColumnResizing={true}>
                            <FilterRow visible={true} />
                            <Selection mode="single" selectAllMode="allPages" deferred={true} />

                            <Column caption="First Name" dataField="firstName" />
                            <Column caption="Middle Name" dataField="middleName" />
                            <Column caption="Last Name" dataField="lastName" />
                        </DataGrid>
                    </ScrollView>
                    <br />

                    <div style={{ textAlign: 'center' }}>
                        <Button text="Save" onClick={this.saveSupportFlightCorrection.bind(this)} />
                        &nbsp;&nbsp;
                        <Button text="Cancel" onClick={this.hideCorrectSupportDialog} />
                    </div>
                </Popup>

                <Popup visible={this.state.isMovingSupportFlight} onHiding={this.hideMoveSupportDialog} dragEnabled={true}
                closeOnOutsideClick={false} showTitle={false} width={800} height={475}>
                    <ScrollView height={375}>
                        <p>Select the Support Staff to move this Flight to</p>
                        <br />
                        <DataGrid ref={ref => this.moveSupportFlightsDataGrid = ref} dataSource={this.state.eligibleFlightSupportStaff} showBorders={true} allowColumnResizing={true}>
                            <FilterRow visible={true} />
                            <Selection mode="single" selectAllMode="allPages" deferred={true} />

                            <Column caption="First Name" dataField="firstName" />
                            <Column caption="Middle Name" dataField="middleName" />
                            <Column caption="Last Name" dataField="lastName" />
                        </DataGrid>
                    </ScrollView>
                    <br />

                    <div style={{ textAlign: 'center' }}>
                        <Button text="Save" onClick={this.saveSupportFlightMove.bind(this)} />
                        &nbsp;&nbsp;
                        <Button text="Cancel" onClick={this.hideMoveSupportDialog} />
                    </div>
                </Popup>

                <Popup visible={this.state.isMovingWorkOrder} onHiding={this.hideMoveWorkOrderDialog} dragEnabled={true}
                    closeOnOutsideClick={false} showTitle={false} width={800} height={475}>
                    <ScrollView height={375}>
                        <p>Enter the Work Order to move this Flight to</p>
                        <br />
                        <Form ref={ref => this.moveWorkOrderForm = ref} formData={this.state.destinationWorkOrder}>
                            <Item editorType="dxTextBox" dataField="workOrderNumber">
                                <RequiredRule />
                            </Item>
                        </Form>
                    </ScrollView>
                    <br />

                    <div style={{ textAlign: 'center' }}>
                        <Button text="Save" onClick={this.saveFlightWorkOrderMove.bind(this)} />
                        &nbsp;&nbsp;
                        <Button text="Cancel" onClick={this.hideMoveWorkOrderDialog} />
                    </div>
                </Popup>

                <Popup visible={this.state.isMergingEmployeeFlight} onHiding={this.hideMergeEmployeeDialog} dragEnabled={true}
                    closeOnOutsideClick={false} showTitle={false} width={1000} height={675}>
                    <ScrollView height={575}>
                        <p>Select the Flight to Merge this Flight on top of</p>
                        <DataGrid dataSource={this.state.overwritingMergeItem} showBorders={true} allowColumnResizing={true}>

                            <Column caption="Employee Name" dataField="travelerName" />
                            <Column caption="Booking Status" dataField="bookingStatus" />
                            <Column caption="Confirmation #" dataField="confirmationNumber" />
                            <Column caption="Flight Number" dataField="flightNumber" />
                            <Column caption="Departure Airport Code" dataField="departureAirportCode" />
                            <Column caption="Departure Time" dataField="departureLocalTime" dataType="datetime" format="MM/dd/yy, HH:mm" />
                            <Column caption="Arrival Airport Code" dataField="arrivalAirportCode" />
                            <Column caption="Arrival Time" dataField="arrivalLocalTime" dataType="datetime" format="MM/dd/yy, HH:mm" />
                            <Column caption="Service Class" dataField="classOfService" />
                            <Column caption="Itinerary Status" dataField="itineraryStatus" />
                        </DataGrid>
                        <br />
                        <DataGrid ref={ref => this.mergeEmployeeFlightsGrid = ref} dataSource={this.state.eligibleMergeFlights} showBorders={true} allowColumnResizing={true}>
                            <FilterRow visible={true} />
                            <Selection mode="single" selectAllMode="allPages" deferred={true} />

                            <Column caption="Employee Name" dataField="travelerName" groupIndex={0} />
                            <Column caption="Booking Status" dataField="bookingStatus" />
                            <Column caption="Confirmation #" dataField="confirmationNumber" />
                            <Column caption="Flight Number" dataField="flightNumber" />
                            <Column caption="Departure Airport Code" dataField="departureAirportCode" />
                            <Column caption="Departure Time" dataField="departureLocalTime" dataType="datetime" format="MM/dd/yy, HH:mm" />
                            <Column caption="Arrival Airport Code" dataField="arrivalAirportCode" />
                            <Column caption="Arrival Time" dataField="arrivalLocalTime" dataType="datetime" format="MM/dd/yy, HH:mm" />
                            <Column caption="Service Class" dataField="classOfService" />
                            <Column caption="Itinerary Status" dataField="itineraryStatus" />
                        </DataGrid>
                    </ScrollView>
                    <br />

                    <div style={{ textAlign: 'center' }}>
                        <Button text="Save" onClick={this.saveEmployeeFlightMerge.bind(this, false)} />
                        &nbsp;&nbsp;
                        <Button text="Cancel" onClick={this.hideMergeEmployeeDialog} />
                        &nbsp;&nbsp;
                        <Button text="Force" onClick={this.saveEmployeeFlightMerge.bind(this, true)} />
                    </div>
                </Popup>
            </div>
        );
    }
}

class FlightDetail extends React.Component {
    constructor(props) {
        //console.log('Detail Props', props);
        super(props);
        this.flightDetails = props.data.row.data.travelerName + "_" + props.data.row.data.flightNumber;
        this.dataSource = [props.data.row.data];
    }
    render() {
        return (
            <DataGrid dataSource={this.dataSource} showBorders={true} allowColumnResizing={true} onExporting={this.onExporting}>
                <Export enabled={true} fileName={"filghtDetail_" + this.flightDetails} />
                <Column caption="Record Locator" dataField="recordLocator" />
                <Column caption="Confirmation Number" dataField="confirmationNumber" />
                <Column caption="Service Class" dataField="classOfService" />
                <Column caption="Marketing Carrier" dataField="marketingCarrier" />
                <Column caption="Operating Carrier" dataField="operatingCarrier" />
                <Column caption="Departure Airport Name" dataField="departureAirportName" />
                <Column caption="Departure Terminal" dataField="departureAirportTerminal" />
                <Column caption="Arrival Airport Name" dataField="arrivalAirportName" />
                <Column caption="Arrival Terminal" dataField="arrivalAirportTerminal" />
            </DataGrid>
        );
    }
}