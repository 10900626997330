import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { FetchAPIPromise, PostAPIPromise, withParams, formatComponentPageName } from '../BaseComponent.js';
import TabPanel, { Item } from 'devextreme-react/tab-panel';
import Button from 'devextreme-react/button';
import Form, { Item as FormItem, Label, RequiredRule, EmptyItem } from 'devextreme-react/form';
import List, { ItemDragging } from 'devextreme-react/list';
import { Link } from 'react-router-dom';
import * as moment from 'moment';
import DataGrid, { FilterRow, Column, GroupPanel, Selection, MasterDetail, Button as GridButton, Editing, Export } from 'devextreme-react/data-grid';
import { Map as ReactMap } from 'devextreme-react/map';
import CustomStore from 'devextreme/data/custom_store';
import DropDownButton from 'devextreme-react/drop-down-button';
import { Tooltip, ITooltipOptions } from 'devextreme-react/tooltip';
import TextBox from 'devextreme-react/text-box';

import { WorkOrderResourcesEquipmentContainer } from './WorkOrderResourcesEquipmentContainer.js';
import { WorkOrderResourcesHotelStaysContainer } from './WorkOrderResourcesHotelStaysContainer.js';
import { WorkOrderResourcesCustomersContainer } from './WorkOrderResourcesCustomersContainer.js';
import { WorkOrderResourcesMealsContainer } from '../Inventory/WorkOrderResourcesMealsContainer';
import { WorkOrderResourcesClothesContainer } from '../Inventory/WorkOrderResourcesClothesContainer.js';
import { WorkOrderResourcesOtherContainer } from '../Inventory/WorkOrderResourcesOtherContainer.js';
import { WorkOrderResourcesEmployeesContainer } from './WorkOrderResourcesEmployeesContainer.js';
import { WorkOrderResourcesSupportStaffContainer } from './WorkOrderResourcesSupportStaffContainer.js';
import WorkOrderResourcesGroundTransportationContainer from './WorkOrderResourcesGroundTransportationContainer.js';
import { WorkOrderEmployeeHotelStaysContainer } from './WorkOrderEmployeeHotelStaysContainer.js';
import { WorkOrderEmployeeFlightsContainer } from './WorkOrderEmployeeFlightsContainer.js';
import { WorkOrderCustomerFlightsContainer } from './WorkOrderCustomerFlightsContainer.js';
import { WorkOrderFlightsContainer } from './WorkOrderFlightsContainer.js';
import WorkOrderTripStatusContainer from './WorkOrderTripStatusContainer.js';

import WorkOrderPickupInfo from './WorkOrderPickupInfo.js';
import WorkOrderDropoffInfo from './WorkOrderDropoffInfo.js';

function StageInfo(data) {
    if (!data.itineraryItem) {
        return (
            <div>
                <div>{data.name}</div>
            </div>
        );
    }
    else {
        if (data.code == 'PICKUP') {
            return (<div style={{ color: (data.itineraryStatus == 'INACTIVE' ? '#7d7d7b' : '#3cbab2') }}><img width={30} height={30} src='pickup.png' />&nbsp;&nbsp;<span style={{ fontSize: '12pt' }} ><b>{data.name}&nbsp;{(data.itineraryStatus == 'INACTIVE' && <span>(Canceled)</span>)}</b></span><span className="float-end"><img width='30px' height='30px' src={(data.transportUnit == 'UC' ? 'minors_xsmall.png' : 'family_xsmall.png')} />&nbsp;{data.customerCount}</span><br />
                <span style={{ marginLeft: 40 }}>{data.itineraryItem.locationName}</span><br />
                <span style={{ marginLeft: 40 }}>{moment(data.time).format("MM/DD/YY HH:mm")}</span>
            </div >);
        }
        else if (data.code == 'DROPOFF') {
            return (<div style={{ color: (data.itineraryStatus == 'INACTIVE' ? '#7d7d7b' : '#3cbab2') }}><img width={30} height={30} src='dropoff.png' />&nbsp;&nbsp;<span style={{ fontSize: '12pt' }} ><b>{data.name}&nbsp;{(data.itineraryStatus == 'INACTIVE' && <span>(Canceled)</span>)}</b></span><span className="float-end"><img width='30px' height='30px' src={(data.transportUnit == 'UC' ? 'minors_xsmall.png' : 'family_xsmall.png')} />&nbsp;{data.customerCount}</span><br />
                <span style={{ marginLeft: 40 }}>{data.itineraryItem.locationName}</span><br />
                <span style={{ marginLeft: 40 }}>{moment(data.time).format("MM/DD/YY HH:mm")}</span>
            </div >);
        }
        else if (data.code == 'HOTELSTAY') {
            return (<div style={{ color: (data.isCustomerItem ? '#3cbab2' : '#add8e6') }}><img width={30} height={30} src='hotelstay.png' />&nbsp;&nbsp;<span style={{ fontSize: '12pt' }} ><b>{data.name}</b></span><span className="float-end"><img width='30px' height='30px' src={(data.transportUnit == 'UC' ? 'minors_xsmall.png' : 'family_xsmall.png')} />&nbsp;<img width='30px' height='30px' src='employee_xsmall.png' /></span><br />
                <span style={{ marginLeft: 40 }}>{data.itineraryItem.facilityName}</span><br />
                <span style={{ marginLeft: 40 }}>({moment(data.itineraryItem.planCheckInDate).format("MM/DD/YY HH:mm")} - {moment(data.itineraryItem.planCheckOutDate).format("MM/DD/YY HH:mm")})</span>
            </div >);
        }
        else if (data.code == 'FLIGHT') {
            return (<div style={{
                color: (data.itineraryStatus == 'INACTIVE' ? '#7d7d7b' : (data.isCustomerItem ? '#3cbab2' : '#add8e6')) }}><img width={30} height={30} src='flight.png' />&nbsp;&nbsp;<span style={{ fontSize: '12pt' }} ><b>{data.name}&nbsp;{(data.itineraryStatus == 'INACTIVE' && <span> - (Inactive)</span>)}</b></span><span className="float-end">{data.isCustomerItem && <img width='30px' height='30px' src={(data.transportUnit == 'UC' ? 'minors_xsmall.png' : 'family_xsmall.png')} />}&nbsp;<img width='30px' height='30px' src='employee_xsmall.png' /></span><br />
                <span style={{ marginLeft: 40 }}>({data.itineraryItem.departureAirportCode} - {data.itineraryItem.arrivalAirportCode})</span><br />
                <span style={{ marginLeft: 40 }}>({moment(data.itineraryItem.depatureLocalTime).format("MM/DD/YY HH:mm")} - {moment(data.itineraryItem.arrivalLocalTime).format("MM/DD/YY HH:mm")})</span>
            </div >);
        }
    }
}

function WorkOrderResourcesAltContainer() {
    const params = useParams();

    const [workOrder, setWorkOrder] = useState({});
    const [tripStages, setTripStages] = useState([]);

    const [isViewingCustomersAndEmployees, setIsViewingCustomersAndEmployees] = useState(true);
    const [isViewingMap, setIsViewingMap] = useState(false);

    const [isViewingPickupLocation, setIsViewingPickupLocation] = useState(false);
    const [currentPickupLocationId, setCurrentPickupLocationId] = useState(-1);

    const [isViewingDropoffLocation, setIsViewingDropoffLocation] = useState(false);
    const [currentDropoffLocationId, setCurrentDropoffLocationId] = useState(-1);

    const [filters, setFilters] = useState({
        isShowingEmployeePlans: false,
        isShowingInactivePlans: false
    });

    const [isViewingFlight, setIsViewingFlight] = useState(false);
    //        itineraryItem: {},

    const [customerList, setCustomerList] = useState([]);
    const [employeeList, setEmployeeList] = useState([]);

    const [inactiveCustomerList, setInactiveCustomerList] = useState([]);
    const [inactiveEmployeeList, setInactiveEmployeeList] = useState([]);

    useEffect(() => {
        async function fetchData() {
            var workOrderData = await FetchAPIPromise('WorkOrder/' + params.id);

            //console.log('Work Order', workOrderData);

            setWorkOrder(workOrderData);

            var employeeData = await FetchAPIPromise('WorkOrderResourcesEmployeeList/GetByWorkOrderIdSimple/' + workOrderData.id);

            //console.log(employeeData);

            var inactiveEmployeeData = await FetchAPIPromise('WorkOrderResourcesEmployeeList/GetInactiveEmployees/' + workOrderData.id);

            var customerData = await FetchAPIPromise('WorkOrderResourcesCustomers/GetFlattenedCustomerList/' + workOrderData.id);

            var inactiveCustomerData = await FetchAPIPromise('WorkOrderResourcesCustomers/GetFlattenedInactiveCustomerList/' + workOrderData.id);

            setEmployeeList(employeeData);
            setCustomerList(customerData);

            setInactiveCustomerList(inactiveCustomerData);
            setInactiveEmployeeList(inactiveEmployeeData);

            //var milestoneControlData = await this.FetchAPI('WorkOrderMilestone/GetActions');
            //milestoneControlData.controlOptionList = milestoneControlData.controlOptionList.filter(c => c.isActive == true);

            formatComponentPageName('#' + workOrderData.workOrderNumber + ' WO Resources');

            var itineraryData = await FetchAPIPromise('Itinerary/GetItineraryByWorkOrderId?workOrderId=' + params.id);

            //console.log('Itinerary Data', itineraryData);

            var workOrderMilestoneSteps = [];

            // First section, plan start date
            var beginDate = new Date(itineraryData.workOrderInfo.planStartDate);

            //console.log(beginDate);

            //// Add trip begin
            //this.addProperStepPosition(workOrderMilestoneSteps,
            //    {
            //        time: beginDate,
            //        code: "PREPARATION",
            //        name: "Prepare for Trip"
            //    });

            // Need to parse through the itinerary info for unique pickups
            const uniquePickupLocations = [...new Map(itineraryData.customerPlacementMappingInfo.map(item =>
                [item['pickupLocationId'], item])).values()];

            var pickupDate = beginDate;

            uniquePickupLocations.sort((a, b) => moment(a.planPickupDate).valueOf() - moment(b.planPickupDate).valueOf()).map((pickup) => {
                if (new Date(pickup.planPickupDate) > pickupDate) {
                    pickupDate = new Date(pickup.planPickupDate);
                }

                addProperStepPosition(workOrderMilestoneSteps,
                    {
                        time: new Date(pickup.planPickupDate),
                        code: "PICKUP",
                        name: "Pick Up UNCs",
                        itineraryStatus: pickup.itineraryStatus,
                        isCustomerItem: true,
                        transportUnit: workOrderData.transportUnit,
                        customerCount: customerData.filter((item) => itineraryData.customerPlacementMappingInfo.find((mapping) => mapping.pickupLocationId == pickup.pickupLocationId && mapping.placementId == item.orrPlacementId)).length,
                        itineraryItem: {
                            id: pickup.pickupLocationId,
                            placementId: pickup.placementId,
                            locationName: pickup.pickupLocationName
                        }
                    });
            });

            pickupDate = new Date(pickupDate.getTime() + (1 * 60000));

            //this.addProperStepPosition(workOrderMilestoneSteps,
            //    {
            //        time: pickupDate,
            //        code: "GROUNDTRANSIT",
            //        name: "Ground Transit"
            //    });

            // Process hotel stays
            itineraryData.hotelStays.map((hotelStay) => {
                addProperStepPosition(workOrderMilestoneSteps,
                    {
                        time: new Date(hotelStay.planCheckInDate),
                        code: "HOTELSTAY",
                        name: "Hotel Stay",
                        transportUnit: workOrderData.transportUnit,
                        // TODO
                        itineraryStatus: "ACTIVE",
                        // TODO
                        isCustomerItem: true,
                        itineraryItem: hotelStay
                    });

                //this.addProperStepPosition(workOrderMilestoneSteps,
                //    {
                //        time: new Date(hotelStay.PlanCheckOutDate),
                //        code: "GROUNDTRANSIT",
                //        name: "Ground Transit"
                //    });
            });

            //console.log('itinerary', itineraryData);

            // Process Flights
            itineraryData.flights.map((flight) => {
                addProperStepPosition(workOrderMilestoneSteps,
                    {
                        time: new Date(flight.departureLocalTime),
                        code: "FLIGHT",
                        name: "Flight",
                        transportUnit: workOrderData.transportUnit,
                        itineraryStatus: flight.itineraryStatus,
                        isCustomerItem: flight.isCustomerFlight,
                        itineraryItem: flight
                    });

                //this.addProperStepPosition(workOrderMilestoneSteps,
                //    {
                //        time: new Date(flight.ArrivalLocalTime),
                //        code: "GROUNDTRANSIT",
                //        name: "Ground Transit"
                //    });
            });

            // Need to parse through the itinerary info for unique pickups
            const uniqueDropoffLocations = [...new Map(itineraryData.customerPlacementMappingInfo.map(item =>
                [item['facilityId'], item])).values()];

            uniqueDropoffLocations.sort((a, b) => moment(a.planDropoffDate).valueOf() - moment(b.planDropoffDate).valueOf()).map((pickup) => {

                addProperStepPosition(workOrderMilestoneSteps,
                    {
                        time: new Date(pickup.planDropoffDate),
                        code: "DROPOFF",
                        name: "Drop Off UNCs",
                        transportUnit: workOrderData.transportUnit,
                        itineraryStatus: pickup.itineraryStatus,
                        customerCount: customerData.filter((item) => itineraryData.customerPlacementMappingInfo.find((mapping) => mapping.facilityId == pickup.facilityId && mapping.placementId == item.orrPlacementId)).length,
                        isCustomerItem: true,
                        itineraryItem: {
                            id: pickup.facilityId,
                            placementId: pickup.placementId,
                            locationName: pickup.facilityName
                        }
                    });
            });

            //// Add trip end
            //this.addProperStepPosition(workOrderMilestoneSteps,
            //    {
            //        time: itineraryData.workOrderInfo.PlanEndDate,
            //        code: "RETURNTRIP",
            //        name: "Return to Office"
            //    });

            //// TODO: Order items within section
            //// TODO: Validate plan pickup date is within plan start and end date

            //// TODO: Initial Office/Start address?
            //// TODO: Pickup address?
            //// TODO: Return address?

            //var passFailData = [{ code: 'Pass', name: 'Pass' }, { code: 'Fail', name: 'Fail' }];
            //var yesNoData = [{ code: 'Yes', name: 'Yes' }, { code: 'No', name: 'No' }];

            //console.log('Final Itinerary Data', workOrderMilestoneSteps);

            setTripStages(workOrderMilestoneSteps);
        }

        fetchData();
    }, [params]);

    const addProperStepPosition = (workOrderStageList, itineraryItem) => {
        var index = 0;

        // Loop through the section entries and find the correct index
        workOrderStageList.map((entry) => {
            if (itineraryItem.time <= entry.time) {
                return;
            }

            index++;
        });

        workOrderStageList.splice(index, 0, itineraryItem);
    }

    const showCustomersAndEmployees = () => {
        setIsViewingCustomersAndEmployees(true);
        setIsViewingMap(false);
        setIsViewingPickupLocation(false);
        setIsViewingDropoffLocation(false);
        setIsViewingFlight(false);
        setCurrentPickupLocationId(-1);
        setCurrentDropoffLocationId(-1);
    }

    const showMap = () => {
        setIsViewingCustomersAndEmployees(false);
        setIsViewingMap(true);
        setIsViewingPickupLocation(false);
        setIsViewingDropoffLocation(false);
        setIsViewingFlight(false);
        setCurrentPickupLocationId(-1);
        setCurrentDropoffLocationId(-1);
    }

    const showTripStage = async (item) => {
        console.log(item);

        if (item.itemData.code == "PICKUP") {

            setIsViewingCustomersAndEmployees(false);
            setIsViewingPickupLocation(true);
            setIsViewingDropoffLocation(false);
            setIsViewingFlight(false);
            setIsViewingMap(false);
            setCurrentPickupLocationId(item.itemData.itineraryItem.id);
        }
        else if (item.itemData.code == "DROPOFF") {
            setIsViewingCustomersAndEmployees(false);
            setIsViewingMap(false);
            setIsViewingFlight(false);
            setIsViewingPickupLocation(false);
            setIsViewingDropoffLocation(true);
            setCurrentDropoffLocationId(item.itemData.itineraryItem.id);
        }
        //else if (item.itemData.code == "FLIGHT") {
        //    //await this.setState({
        //    //    itineraryItem: item.itemData.itineraryItem
        //    //});
        //    setIsViewingCustomersAndEmployees(false);
        //    setIsViewingMap(false);
        //    setIsViewingDropoffLocation(false);
        //    setIsViewingPickupLocation(false);
        //    setIsViewingFlight(true);
        //}
        //else {
        //    alert('not recognized');
        //}
    }

    const componentIsReadOnly = () => {
        // TODO
        // return this.IsReadOnly() || workOrder.statusID > 1;
        return false;
    }

    const onItemClick = (e) => {
        //console.log('Filter item click', e);

        if (e.itemData) {
            if (e.itemData.id == 1) {
                var isShowingEmployeePlans = filters.isShowingEmployeePlans;
                //console.log(filters);

                setFilters(prevState => ({
                    ...prevState,
                    isShowingEmployeePlans: !isShowingEmployeePlans
                }));
            }

            if (e.itemData.id == 2) {
                var isShowingInactivePlans = filters.isShowingInactivePlans;
                //console.log(filters);

                setFilters(prevState => ({
                    ...prevState,
                    isShowingInactivePlans: !isShowingInactivePlans
                }));
            }
        }
    }

    //console.log('Original trip stages', tripStages);

    var hiddenInactivePlanCount = tripStages.filter((item) => item.itineraryStatus != 'ACTIVE').length;
    var employeePlanCount = tripStages.filter((item) => !item.isCustomerItem).length;

    //console.log('Hidden inacive count', hiddenInactivePlanCount);

    var filteredTripStages = tripStages.filter((item) => item.isCustomerItem || filters.isShowingEmployeePlans);

    // Also filter by inactive filter
    filteredTripStages = filteredTripStages.filter((item) => filters.isShowingInactivePlans || item.itineraryStatus == 'ACTIVE');

    //console.log('Customer list', customerList);
    //console.log('Inactive customer list', inactiveCustomerList);

    const activeSeniorTotal = customerList.filter((item) => item.yearAge >= 10 && item.yearAge <= 17).length;
    const activeJuniorTotal = customerList.filter((item) => item.yearAge >= 6 && item.yearAge <= 9).length;
    const activeTenderTotal = customerList.filter((item) => item.yearAge <= 5).length;

    const inactiveSeniorTotal = inactiveCustomerList.filter((item) => item.yearAge >= 10 && item.yearAge <= 17).length;
    const inactiveJuniorTotal = inactiveCustomerList.filter((item) => item.yearAge >= 6 && item.yearAge <= 9).length;
    const inactiveTenderTotal = inactiveCustomerList.filter((item) => item.yearAge <= 5).length;

    const activeMaleTotal = employeeList.filter((item) => item.sex == 'Male').length;
    const activeFemaleTotal = employeeList.filter((item) => item.sex == 'Female').length;

    //console.log(activeMaleTotal);
    //console.log(activeFemaleTotal);

    const inactiveMaleTotal = inactiveEmployeeList.filter((item) => item.sex == 'Male').length;
    const inactiveFemaleTotal = inactiveEmployeeList.filter((item) => item.sex == 'Female').length;

    //console.log('Senior total', seniorTotal);
    //console.log('Junior total', juniorTotal);
    //console.log('Tender total', tenderTotal);

    //console.log('Inactive customers', inactiveCustomerList);
    //console.log('Inactive employees', inactiveEmployeeList);

    let activeUniquePlacements = [
        ...new Set(customerList.map((element) => element.orrPlacementId)),
    ];

    let inactiveUniquePlacements = [
        ...new Set(inactiveCustomerList.map((element) => element.orrPlacementId)),
    ];

    // console.log('Unique placement ids', unique_values);

    const activePlacementCount = activeUniquePlacements.length;
    const inactivePlacementCount = inactiveUniquePlacements.length;

    return (
        <>
            <div className="container">
                <h1>Work Order Resources</h1>

                <div className="row">
                    <fieldset>
                        <header><b>Work Order</b></header>
                        <br />

                        <Form
                            id="form"
                            colCount={14}
                            formData={workOrder}>

                            <FormItem colSpan={2} dataField="workOrderName" editorOptions={{ readOnly: true }}>
                                <Label text="Name" />
                            </FormItem>

                            <FormItem colSpan={2} dataField="workOrderNumber" editorOptions={{ readOnly: true }}>
                                <Label text="Number" />
                            </FormItem>

                            <FormItem colSpan={3} dataField="planStartDate" editorType="dxDateBox" editorOptions={{
                                readOnly: true, displayFormat: "MM/dd/yy, HH:mm", type: 'datetime'
                            }}>
                                <Label text="Plan Start Date" />
                            </FormItem>

                            <FormItem colSpan={3} dataField="planEndDate" editorType="dxDateBox" editorOptions={{
                                readOnly: true, displayFormat: "MM/dd/yy, HH:mm", type: 'datetime'
                            }}>
                                <Label text="Plan End Date" />
                            </FormItem>

                            <FormItem colSpan={2}>
                                <Link to={`/workorder-detail/${workOrder.id}`}>
                                    <Button icon="info" hint="Work Order Details" text="Details" />
                                </Link>
                            </FormItem>

                            <FormItem colSpan={2}>
                                <Link to={`/workorder-milestones/${workOrder.id}`}>
                                    <Button icon="event" hint="Work Order Milestones" text="Milestones" />
                                </Link>
                            </FormItem>
                        </Form>
                    </fieldset>
                </div>
                <br />
            </div>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-3">
                        <fieldset>
                            <header><b>Trip Stages</b></header>
                            <br />

                            <div className="d-flex justify-content-between" style={{ height: '35px' }}>
                                <DropDownButton className="ms-3 me-auto"
                                    splitButton={true}
                                    useSelectMode={false}
                                    text="Filters"
                                    icon="filter"
                                    items={[
                                        { id: 1, name: filters.isShowingEmployeePlans ? 'Hide Employee/Staff Plans' : 'Show Employee/Staff Plans', icon: 'group', badge: employeePlanCount },
                                        { id: 2, name: filters.isShowingInactivePlans ? 'Hide Hidden/Inactive Plans' : 'Show Hidden/Inactive Plans', icon: 'trash', badge: hiddenInactivePlanCount },
                                    ]}
                                    displayExpr="name"
                                    keyExpr="id"
                                    dropDownOptions={{ width: 300 }}
                                    width={150}
                                    onItemClick={onItemClick}
                                />

                                <div id="childPane" className="ms-auto me-2">
                                    {workOrder.transportUnit == 'Family' &&
                                        <img width='40px' height='40px' src='family_xsmall.png' />
                                    }
                                    {workOrder.transportUnit == 'UC' && 
                                        <img width='40px' height='40px' src='minors_xsmall.png' />
                                    }
                                    &nbsp;
                                    <span style={{ fontSize: '15pt' }}><b>{customerList.length}{(inactiveCustomerList.length > 0) && `(${inactiveCustomerList.length})`} </b></span>

                                    <Tooltip
                                        target="#childPane"
                                        showEvent="mouseenter"
                                        hideEvent="mouseleave"
                                        position="top"
                                        hideOnOutsideClick={false}
                                    >
                                        <div className="d-flex justify-content-between">
                                            {customerList.length > 0 &&
                                                <div style={{ marginLeft: "5px", marginRight: "5px" }}>
                                                    <b>{workOrder.transportUnit == 'UC' ? 'Active Minors' : 'Active Families'}</b>
                                                    <hr />
                                                    {workOrder.transportUnit == 'UC' &&
                                                        <>
                                                            {activeSeniorTotal > 0
                                                                ? <>{`Seniors: ${activeSeniorTotal}`}<br /></>
                                                                : null
                                                            }
                                                            {activeJuniorTotal > 0
                                                                ? <>{`Juniors: ${activeJuniorTotal}`}<br /></>
                                                                : null
                                                            }
                                                            {activeTenderTotal > 0
                                                                ? <>{`Tender: ${activeTenderTotal}`}<br /></>
                                                                : null
                                                            }
                                                            <hr />
                                                            Total: {customerList.length}
                                                        </>
                                                    }

                                                    {workOrder.transportUnit == 'Family' &&
                                                        <>
                                                            <>{`Units: ${activePlacementCount}`}<br /></>
                                                            <>{`Members: ${customerList.length}`}<br /></>
                                                        </>
                                                    }
                                                </div>
                                            }

                                            {inactiveCustomerList.length > 0 &&
                                                <div style={{ marginLeft: "5px", marginRight: "5px" }}>
                                                    <b>{workOrder.transportUnit == 'UC' ? 'Inactive Minors' : 'Inactive Families'}</b>
                                                    <hr />
                                                    {workOrder.transportUnit == 'UC' &&
                                                        <>
                                                            {inactiveSeniorTotal > 0
                                                                ? <>{`Seniors: ${inactiveSeniorTotal}`}<br /></>
                                                                : null
                                                            }
                                                            {inactiveJuniorTotal > 0
                                                                ? <>{`Juniors: ${inactiveJuniorTotal}`}<br /></>
                                                                : null
                                                            }
                                                            {inactiveTenderTotal > 0
                                                                ? <>{`Tender: ${inactiveTenderTotal}`}<br /></>
                                                                : null
                                                            }
                                                        <hr />
                                                        Total: {inactiveCustomerList.length}
                                                        </>
                                                    }

                                                    {workOrder.transportUnit == 'Family' &&
                                                        <>
                                                            <>{`Units: ${inactivePlacementCount}`}<br /></>
                                                            <>{`Members: ${inactiveCustomerList.length}`}<br /></>
                                                        </>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </Tooltip>
                                </div>
                                &nbsp;&nbsp;
                                <div id="employeePane" className="ms-auto me-2">
                                    <img width='40px' height='40px' src='employee_xsmall.png' />
                                    &nbsp;
                                    <span style={{ fontSize: '15pt' }}><b>{employeeList.length}{(inactiveEmployeeList.length > 0) && `(${inactiveEmployeeList.length})`} </b></span>

                                    <Tooltip
                                        target="#employeePane"
                                        showEvent="mouseenter"
                                        hideEvent="mouseleave"
                                        position="top"
                                        hideOnOutsideClick={false}
                                    >
                                        <div className="d-flex justify-content-between">
                                            {employeeList.length > 0 &&
                                                <div style={{ marginLeft: "5px", marginRight: "5px" }}>
                                                    <b>Active Employees</b>
                                                    <hr />
                                                    <>
                                                        {activeMaleTotal > 0
                                                            ? <>{`Male: ${activeMaleTotal}`}<br /></>
                                                            : null
                                                        }
                                                        {activeFemaleTotal > 0
                                                            ? <>{`Female: ${activeFemaleTotal}`}<br /></>
                                                            : null
                                                        }
                                                        <hr />
                                                        Total: {employeeList.length}
                                                    </>
                                                </div>
                                            }

                                            {inactiveEmployeeList.length > 0 &&
                                                <div style={{ marginLeft: "5px", marginRight: "5px" }}>
                                                    <b>Inactive Employees</b>
                                                    <hr />
                                                    <>
                                                        {inactiveMaleTotal > 0
                                                            ? <>{`Male: ${inactiveMaleTotal}`}<br /></>
                                                            : null
                                                        }
                                                        {inactiveFemaleTotal > 0
                                                            ? <>{`Female: ${inactiveFemaleTotal}`}<br /></>
                                                            : null
                                                        }
                                                        <hr />
                                                        Total: {inactiveEmployeeList.length}
                                                    </>
                                                </div>
                                            }
                                        </div>
                                    </Tooltip>
                                </div>
                            </div>
                            <br />

                            <List style={{ backgroundColor: '#ebebeb' }} dataSource={filteredTripStages} itemRender={StageInfo} onItemClick={showTripStage}>
                            </List>
                        </fieldset>
                    </div>

                    <div className="col-sm-9" style={{ minHeight: '800px' }}>
                        <fieldset>
                            <header><b>{isViewingCustomersAndEmployees ? 'Customers & Employees' : 'Resources'}</b></header>
                            <br />

                            <div>
                                <Button text="View Customers & Employees" onClick={showCustomersAndEmployees} />
                                {/*&nbsp;*/}
                                {/*<Button text="View Trip Status" onClick={this.showMap} />*/}
                            </div>
                            <br />

                            {isViewingPickupLocation &&
                                <div>
                                    <header><b>Pickup Location</b></header>
                                    <br />

                                    <WorkOrderPickupInfo workOrder={workOrder} pickupLocationId={currentPickupLocationId} />
                                </div>
                            }

                            {isViewingDropoffLocation &&
                                <div>
                                    <header><b>Dropoff Location</b></header>
                                    <br />

                                    <WorkOrderDropoffInfo workOrder={workOrder} dropoffLocationId={currentDropoffLocationId} />
                                </div>
                            }

                            {isViewingFlight &&
                                <div>
                                    <header><b>Flight</b></header>
                                    <br />

                                    {/*<WorkOrderFlightsContainer workOrder={workOrder} itineraryItem={this.state.itineraryItem} />*/}
                                </div>
                            }

                            {isViewingMap &&
                                <div>
                                    <header><b>Trip Status</b></header>
                                    <br />

                                    <WorkOrderTripStatusContainer workOrder={workOrder} />
                                </div>
                            }

                            {isViewingCustomersAndEmployees &&
                                <>
                                    <div className="row">
                                        <fieldset>
                                            <header><b>Customers</b></header>
                                            <br />
                                            <TabPanel>
                                                <Item title="Customers">
                                                    <WorkOrderResourcesCustomersContainer workOrder={workOrder} />
                                                </Item>
                                                <Item title="Flights">
                                                    <WorkOrderCustomerFlightsContainer workOrder={workOrder} />
                                                </Item>
                                                <Item title="Hotel Stays">
                                                    <WorkOrderResourcesHotelStaysContainer workOrder={workOrder} />
                                                </Item>
                                                <Item title="Meals" >
                                                    <WorkOrderResourcesMealsContainer workOrder={workOrder} />
                                                </Item>
                                                <Item title="Clothes">
                                                    <WorkOrderResourcesClothesContainer workOrder={workOrder} />
                                                </Item>
                                                <Item title="Other Items" >
                                                    <WorkOrderResourcesOtherContainer workOrder={workOrder} />
                                                </Item>
                                            </TabPanel>
                                        </fieldset>
                                    </div>
                                    <br />
                                    <div className="row">
                                        <fieldset>
                                            <header><b>Resources</b></header>
                                            <br />
                                            <TabPanel>
                                                <Item title="Employee">
                                                    <WorkOrderResourcesEmployeesContainer workOrder={workOrder} />
                                                </Item>
                                                <Item title="Support Staff">
                                                    <WorkOrderResourcesSupportStaffContainer workOrder={workOrder} />
                                                </Item>
                                                <Item title="Flights">
                                                    <WorkOrderEmployeeFlightsContainer workOrder={workOrder} />
                                                </Item>
                                                <Item title="Ground Transportation">
                                                    <WorkOrderResourcesGroundTransportationContainer workOrder={workOrder} />
                                                </Item>
                                                <Item title="Hotel Stays">
                                                    <WorkOrderEmployeeHotelStaysContainer workOrder={workOrder} />
                                                </Item>
                                                <Item title="Equipment">
                                                    <WorkOrderResourcesEquipmentContainer workOrder={workOrder} />
                                                </Item>
                                            </TabPanel>
                                        </fieldset>
                                    </div>
                                </>
                            }
                        </fieldset>
                    </div>
                </div>
                <br />
            </div>
        </>
    );
}

export default withParams(WorkOrderResourcesAltContainer);