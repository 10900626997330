import React from 'react';
import * as moment from 'moment';
import CustomStore from 'devextreme/data/custom_store';
import DataGrid, { FilterRow, Pager, Popup, Paging, Editing, RequiredRule, StringLengthRule, Form as EditForm, Column, Lookup, FormItem, Export, MasterDetail } from 'devextreme-react/data-grid';
import { GroupItem, Item } from 'devextreme-react/form';
import BaseComponent, { FetchAPIPromise, PostAPIPromise, PutAPIPromise, DeleteAPIPromise } from '../BaseComponent';
import Container from 'react-bootstrap/Container';
import * as $ from 'jquery';
import { values } from 'lodash';
import Form from 'devextreme-react/form';

const customDataSource = new CustomStore({
    key: 'id',
    load: async () => {
        return await FetchAPIPromise('SystemMessage');
    },
    insert: async (values) => {
        console.log(values);
        return await PostAPIPromise('SystemMessage', values).then((result) => {
            if (result.status != 1) {
                throw new Error(result.message);
            }
        });
    },
    update: async (key, values) => {
        return await PostAPIPromise('SystemMessage', values).then((result) => {
            if (result.status != 1) {
                console.log()
                throw new Error(result.message);
            }
        });
    },
    remove: async (key) => {
        return await DeleteAPIPromise('SystemMessage/' + key);
    }
});

const htmlEditorOptions = {
    height: "25vh",
    toolbar: {
        items: ["undo", "redo", "separator", "bold", "italic", "underline", "separator", {
            "name": "size",
            "acceptedValues": ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"]
        }, {
                "name": "color",
                "acceptedValues": ["Black", "Blue", "Red"]
            }, "separator",
            "alignLeft", "alignCenter", "alignRight", "separator", "orderedList", "bulletList", "separator", "clear"
        ]
    }
};

export class SystemMessageListContainer extends BaseComponent {
    constructor(props) {
        super(props);

        this.dataGridRef = React.createRef();
        this.rowKey = -1;
        this.showServiceDates = false;

        this.state = {
            statusOptions: null,
            isServiceDisruption: false
        };
    }
    get dataGrid() {
        return this.dataGridRef.current.instance;
    }

    componentDidMount() {
        super.componentDidMount();

        this.fetchData();
    }

    IsReadOnly() {
        if (window.location.pathname === '/system-message-history') {
            return true;
        }

        return super.IsReadOnly();
    }

    GetComponentPageName = () => {
        if (window.location.pathname === '/system-message-history') {
            return "System Message History";
        }
        else {
            return "System Message Management";
        }
    }

    GetDropDownData = async () => {
        const statusData = await this.FetchAPI('SystemMessage/StatusCodes');
        console.log("status data", statusData);
        this.setState({
            statusOptions: statusData
        });
    }

    onRowValidating = (e) => {
        console.log('validating', e);

        if (e.newData.visibleStartDate && e.newData.visibleEndDate) {
            console.log('here');
            if (moment(e.newData.visibleStartDate).isAfter(moment(e.newData.visibleEndDate))) {
                console.log('INVALID');
                e.isValid = false;
                e.errorText = 'End Date cannot precede Start Date';
            }
        }
        if (e.newData.serviceDisruptionStartDate && e.newData.serviceDisruptionEndDate) {
            console.log('here2');
            if (moment(e.newData.serviceDisruptionStartDate).isAfter(moment(e.newData.serviceDisruptionStartDate))) {
                console.log('INVALID');
                e.isValid = false;
                e.errorText = 'End Date cannot precede Start Date';
            }
        }
    }

    onInitNewRow = (e) => {
        this.rowKey = -1;

        e.data.isServiceDisruption = false;
    }

    onRowUpdating(options) {
        options.newData = $.extend({}, options.oldData, options.newData);
    }

    masterDetail = (e) => {
        console.log("Data", e);
        return (<Form colCount={2} readOnly={true} formData={e.data}>
                { /* TODO: If we update DevExtreme to v23+, these dates would be a good use case for the new DateRangeBox UI component -RPM */}
                <Item dataField="serviceDisruptionStartDate" editorType="dxDateBox" format="MM/dd/yy, HH:mm" visible={e.data.isServiceDisruption} />
                <Item dataField="serviceDisruptionEndDate" editorType="dxDateBox" format="MM/dd/yy, HH:mm" visible={e.data.isServiceDisruption} />
                <Item dataField="messageDetails" colSpan={2} editorType="dxHtmlEditor" />
        </Form>)
    }

    onEditingStart = (e) => {
        this.rowKey = e.key
    }

    customizeItem = (item) => {
        if (item && item.editorType === "dxDateBox" && item.dataField.startsWith("serviceDisruption")) {
            let index = this.dataGrid.getRowIndexByKey(this.rowKey);
            index = (index == -1) ? 0 : index;
            var isServiceDisruption = this.dataGrid.cellValue(index, "isServiceDisruption");

            console.log('Customize Item', item);
            console.log(isServiceDisruption);

            item.visible = isServiceDisruption;
            this.showServiceDates = isServiceDisruption;
        }
    }

    setCellValue = (newData, value) => {
        newData.isServiceDisruption = value;
        this.dataGrid.getDataSource().reload();
    }

    render() {
        console.log('Begin Render');

        return (
            <Container>
                <h1>{this.GetComponentPageName()}</h1>
                <br/>
                <DataGrid keyExpr="Id" dataSource={customDataSource}
                    showBorders={true} allowColumnResizing={true}
                    onInitNewRow={this.onInitNewRow} onRowUpdating={this.onRowUpdating} onRowValidating={this.onRowValidating}
                    onExporting={this.onExporting} ref={this.dataGridRef} onEditingStart={this.onEditingStart}>
                    <Export enabled={true} fileName={"systemMessageHistory"} />
                    <Editing mode="popup" allowAdding={!this.IsReadOnly()}
                        allowUpdating={!this.IsReadOnly()} allowDeleting={!this.IsReadOnly()}>
                        <Popup title={"Edit System Message"}
                            showTitle={true}
                            height="auto" />
                        <EditForm colCount={3} customizeItem={this.customizeItem}>
                            <GroupItem colCount={2} colSpan={3}>
                                { /* TODO: If we update DevExtreme to v23+, these dates would be a good use case for the new DateRangeBox UI component -RPM */ }
                                <Item dataField="visibleStartDate" editorType="dxDateBox" format="MM/dd/yy, HH:mm" isRequired={true} />
                                <Item dataField="visibleEndDate" editorType="dxDateBox" format="MM/dd/yy, HH:mm" isRequired={true} />
                                <Item dataField="serviceDisruptionStartDate" editorType="dxDateBox" format="MM/dd/yy, HH:mm" isRequired={true} />
                                <Item dataField="serviceDisruptionEndDate" editorType="dxDateBox" format="MM/dd/yy, HH:mm" isRequired={true} />
                            </GroupItem>
                            <GroupItem caption="Details" colCount={3} colSpan={3}>
                                <Item dataField="isServiceDisruption" colSpan={1} editorType="dxCheckBox" />
                                <Item dataField="statusCode" colSpan={2} editorType="dxSelectBox"
                                    editorOptions={{
                                        dataSource: this.state.statusOptions,
                                        displayExpr: 'statusName', valueExpr: 'statusCode'
                                    }}
                                    isRequired={true} />
                                <Item dataField="bannerMessage" colSpan={3} editorType="dxTextBox" isRequired={true} />
                                <Item dataField="messageDetails" colSpan={3} editorType="dxHtmlEditor" editorOptions={htmlEditorOptions} isRequired={true} />
                            </GroupItem>
                        </EditForm>
                    </Editing>
                    <FilterRow visible={true} />
                    <Paging defaultPageSize={10} />
                    <Pager showPageSizeSelector={true}
                        allowPageSizes={[5, 10, 20]}
                        showInfo={true} />

                    <MasterDetail
                        enabled={true}
                        render={this.masterDetail}
                    />

                    <Column dataField="bannerMessage" dataType="string"/>

                    <Column caption="Visibility Start Date" dataField="visibleStartDate" dataType="datetime" format="MM/dd/yy, HH:mm" />

                    <Column caption="Visibility End Date" dataField="visibleEndDate" dataType="datetime" format="MM/dd/yy, HH:mm" />

                    <Column dataField="serviceDisruptionStartDate" dataType="datetime" format="MM/dd/yy, HH:mm" visible={false} />

                    <Column dataField="serviceDisruptionEndDate" dataType="datetime" format="MM/dd/yy, HH:mm" visible={false} />

                    <Column caption="Status"
                        dataField="statusCode">
                        <Lookup allowClearing={true} dataSource={this.state.statusOptions} displayExpr="statusName" valueExpr="statusCode" />
                    </Column>

                    <Column dataField="messageDetails" dataType="string" visible={false} />

                    <Column dataField="isServiceDisruption" dataType="boolean" visible={false} setCellValue={this.setCellValue} />
                </DataGrid>
            </Container>
        );
    }
}