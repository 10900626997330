import React from 'react';
import * as $ from 'jquery'
import BaseComponent, { FetchAPIPromise, DeleteAPIPromise } from '../BaseComponent';
import PropTypes from 'prop-types';
import CustomStore from 'devextreme/data/custom_store';
import DataGrid, { FilterRow, Column, MasterDetail, Editing, Button as GridButton, Summary, TotalItem, Selection, Export } from 'devextreme-react/data-grid';
import { Link } from 'react-router-dom';
import Button from 'devextreme-react/button';
import Form, { Item as FormItem, Label, RequiredRule } from 'devextreme-react/form';
import Popup from 'devextreme-react/popup';
import Accordion, { Item as AccordionItem } from 'devextreme-react/accordion';
import { alert, confirm } from 'devextreme/ui/dialog';
import DataSource from 'devextreme/data/data_source';
import { HtmlEditor, Toolbar } from 'devextreme-react/html-editor';
import DropDownButton from 'devextreme-react/drop-down-button';

const downloads = ['Generate as PDF', 'Generate as DOCX'];

const htmlEditorOptions = {
    height: "50vh",
    toolbar: {
        items: ["undo", "redo", "separator", "bold", "italic", "underline", "separator", {
                "name": "size",
                "acceptedValues": ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"]
        }, {
                "name": "color",
                "acceptedValues": ["Black", "Blue", "Red"]
        }, "separator",
             "alignLeft", "alignCenter", "alignRight", "separator", "orderedList", "bulletList", "separator", "clear"
        ]
    }
};

export class WorkOrderResourcesCustomersContainer extends BaseComponent {
    static propTypes = {
        workOrder: PropTypes.object
    };

    constructor(props) {
        super(props);
        this.state = {
            familyUnits: [],
            familyUnitPrefix: "",
            inactiveReasons: [],
            placementStatuses: [],

            isViewingTextBox: false,
            loading: false,

            isMovingPlacement: false,
            showInactiveRoster: false,

            tripNotes: "",

            placementToInactivate: {
                id: null,
                comments: null,
                selectedPlacementStatusId: null,
                cancellationNotes: null
            }
        };
    }

    customDataSource = new CustomStore({
        key: ['workOrderID', 'id'],
        onLoaded: (result) => {
            //console.log('Loaded complete', result);

            if (this.props.workOrder.transportUnit == "Family") {
                var familyGrouping = new Array(this.props.workOrder.familyUnitCount + 1);

                result.map((item, index) => {
                    var groupIndex = index + 1;

                    if (!familyGrouping[groupIndex]) {
                        familyGrouping[groupIndex] = 0;
                    }

                    familyGrouping[groupIndex] = item.customerList.length;
                });

                var familyGroupList = '';

                for (var i = 0; i < familyGrouping.length; i++) {
                    if (i == 0) {
                        if (familyGrouping[i] && familyGrouping[i] > 0) {
                            familyGroupList += ('FU?:' + familyGrouping[i]);
                            familyGroupList += ', ';
                        }
                    }
                    else {
                        familyGroupList += ('FU' + i + ':' + (familyGrouping[i] ? familyGrouping[i] : 0));

                        if (i != familyGrouping.length - 1) {
                            familyGroupList += ', ';
                        }
                    }
                }

                //console.log(familyGroupList);

                $("textarea#familyUnitgroup").val(familyGroupList);
            }
        },
        load: (loadOptions) => {
            //console.log('Load', loadOptions);
            //console.log(this.props.workOrder.id);

            if (this.props.workOrder.id) {
                return FetchAPIPromise('WorkOrderResourcesCustomers/' + this.props.workOrder.id);
            }
        },
        remove: (key) => {
            //console.log(key);

            var queryString = 'workOrderId=' + key.workOrderID + '&placementId=' + key.id

            return DeleteAPIPromise('WorkOrderResourcesCustomers/DeleteActiveByIds', queryString)
                .then((result) => {
                    if (result.status == -2) {
                        // TODO: Check this
                        alert('Placement is referenced in the system and cannot be deleted.')
                    }
                    else if (result.status == 0) {
                        alert(result.message);
                    }
                });
        }
    });

    inactivePlacementDataSource = new CustomStore({
        key: ['workOrderID', 'id'],
        load: (loadOptions) => {
            //console.log('Load', loadOptions);
            //console.log(this.props.workOrder.id);

            if (this.props.workOrder.id) {
                return FetchAPIPromise('WorkOrderResourcesCustomers/GetInactivePlacements/' + this.props.workOrder.id);
            }
        },
        remove: (key) => {
            //console.log(key);

            var queryString = 'workOrderId=' + key.workOrderID + '&placementId=' + key.id

            return DeleteAPIPromise('WorkOrderResourcesCustomers/DeleteInactiveByIds', queryString)
                .then((result) => {
                    if (result.status == -2) {
                        // TODO: Check this
                        alert('Placement is referenced in the system and cannot be deleted.');
                    }
                    else if (result.status == 0) {
                        alert(result.message);
                    }
                });
        }
    });

    componentDidUpdate(prevProps) {
        //console.log('Props Updated');

        if (this.props.workOrder != prevProps.workOrder) // Check if it's a new user, you can also use some unique property, like the ID (this.props.user.id !== prevProps.user.id)
        {
            this.fetchData();
            this.dataGrid.instance.refresh();
            this.inactivePlacementDataGrid.instance.refresh();
            this.inactiveRosterAccordion.instance.collapseItem(0);
            this.state.tripNotes = this.props.workOrder.tripNotes;
        }
    }

    componentIsReadOnly = () => {
        return this.IsReadOnly() || this.props.workOrder.statusID > 1;
    }

    CreateCheatSheetPDF = async (e) => {
        //console.log('got here');
        //console.log(this.props.workOrder);

        var cheatSheet = await this.RawPostAPI("Reports/GetCheatSheetPDF/" + this.props.workOrder.id);
        //console.log(cheatSheet);

        var blob = await cheatSheet.blob();
        //console.log(blob);

        // Build a URL from the file
        const fileURL = URL.createObjectURL(blob);

        // Open the URL on new Window
        const pdfWindow = window.open();
        pdfWindow.location.href = fileURL;
    }

    CreateCheatSheetDOC = async (e) => {
        //console.log('got here');
        //console.log(this.props.workOrder);

        var cheatSheet = await this.RawPostAPI("Reports/GetCheatSheetDOC/" + this.props.workOrder.id);
        //console.log(cheatSheet);

        var blob = await cheatSheet.blob();
        //console.log(blob);

        // Build a URL from the file
        const fileURL = URL.createObjectURL(blob);

        // Open the URL on new Window
        const pdfWindow = window.open();
        pdfWindow.location.href = fileURL;
    }

    CreateToCSheet = async (e) => {
        //console.log('got here');
        //console.log(this.props.workOrder);

        var cheatSheet = await this.RawPostAPI("Reports/GetToCForm/" + this.props.workOrder.id);
        //console.log(cheatSheet);

        var blob = await cheatSheet.blob();
        //console.log(blob);

        // Build a URL from the file
        const fileURL = URL.createObjectURL(blob);

        // Open the URL on new Window
        const pdfWindow = window.open();
        pdfWindow.location.href = fileURL;
    }

    CreateWristbandSheet = async (e) => {
        //console.log('got here');
        //console.log(this.props.workOrder);

        var cheatSheet = await this.RawPostAPI("Reports/GetWristbandSheet/" + this.props.workOrder.workOrderNumber);
        //console.log(cheatSheet);

        var blob = await cheatSheet.blob();
        //console.log(blob);

        // Build a URL from the file
        const fileURL = URL.createObjectURL(blob);

        console.log(fileURL);

        // Open the URL on new Window
        const pdfWindow = window.open();
        pdfWindow.location.href = fileURL;
    }

    GetDropDownData = async () => {
        const inactiveReasonData = await this.FetchAPI('DictionaryInactiveReason');
        const placementStatusData = await this.FetchAPI('DictionaryPlacementStatus');

        await this.setState({
            inactiveReasons: inactiveReasonData,
            placementStatuses: placementStatusData
        });
    }

    hideMoveDialog = () => {
        this.setState({
            isMovingPlacement: false,
            placementToInactivate: {
                id: null,
                filteredPlacementStatuses: [],
                selectedPlacementStatusId: null,
                isPlacementStatusReadOnly: false,
                cancellationNotes: null,
                comments: null
            }
        });
    }

    makePlacementInactive = async (e) => {
        console.log('Making placement inactive', e);

        var workOrderHistory = await this.FetchAPI('PlacementHistory/GetWorkOrdersByPlacementId/' + e.row.data.id);

        console.log(workOrderHistory);

        var otherRequiredStatus = null;

        workOrderHistory.map((item) => {
            console.log(this.props.workOrder.id);
            console.log(item.workOrderID);
            console.log(this.props.workOrder.id == item.workOrderID);

            if (item.workOrderID != this.props.workOrder.id) {
                console.log('NOT the same work order');
                if (item.rosterStatus == 'Active' && item.workOrderType == 'Regular') {
                    // TODO: Test
                    otherRequiredStatus = 'Dispatched';
                }

                if (item.rosterStatus == 'Active' && item.workOrderType == 'Support') {
                    // TODO: Test
                    otherRequiredStatus = 'Added On Support';
                }
            }
        });

        console.log(otherRequiredStatus);

        var filterPlacementStatuses = this.state.placementStatuses;
        var defaultPlacementStatus = null;

        if (!otherRequiredStatus) {
            // Filter to dispatched or canceled
            filterPlacementStatuses = filterPlacementStatuses.filter((item) => item.id == 0 || item.id == 3);
        }
        else if (otherRequiredStatus == 'Added On Support') {
            filterPlacementStatuses = filterPlacementStatuses.filter((item) => item.id == 2);
            defaultPlacementStatus = 2;
        }
        else if (otherRequiredStatus == 'Dispatched') {
            filterPlacementStatuses = filterPlacementStatuses.filter((item) => item.id == 1);
            defaultPlacementStatus = 1;
        }

        // TODO: Disable/enable the select box
        // TODO: Hide/show/require the cancelation notes based on selection
        // Look across assignment history and look for -- 1) During cancelation, pick the resulting status
        // - If placement is in no other work order list, enabled, allow canceled or not dispatched
        // - If placement is in another work order list as dispatched, only allow dispatched
        // - If placement is in another work order list as added on support, only allow added on support?
        this.setState({
            isMovingPlacement: true,
            placementToInactivate: {
                id: e.row.data.id,
                filteredPlacementStatuses: filterPlacementStatuses,
                selectedPlacementStatusId: defaultPlacementStatus,
                isPlacementStatusReadOnly: defaultPlacementStatus != null,
                comments: null
            }
        });
    }

    saveInactivePlacement = async () => {
        var result = this.editFormControl.instance.validate();

        if (result.isValid) {

            var placementToMoveDetails = this.state.placementToInactivate;

            placementToMoveDetails.workOrderId = this.props.workOrder.id;

            const result = await this.PostAPI('WorkOrderResourcesCustomers/InactivatePlacement', placementToMoveDetails);

            //console.log(result);

            if (result.status == 1) {

                this.dataGrid.instance.refresh();
                this.inactivePlacementDataGrid.instance.refresh();

                this.setState({
                    isMovingPlacement: false,
                    placementToInactivate: {
                        id: null,
                        filteredPlacementStatuses: [],
                        selectedPlacementStatusId: null,
                        isPlacementStatusReadOnly: false,
                        cancellationNotes: null,
                        comments: null
                    }
                });
            }
            else {
                //console.log(result);
                alert('Failed. Please try again later.');
            }
        }
    }

    ReactivatePlacement = async (e) => {
        console.log('Re-activating placement', e);

        var confirmAnswer = await confirm("Are you sure you want to attempt to move this Placement back to the active roster?");

        if (confirmAnswer) {
            var array = {};
            array.placementIds = [];
            array.placementIds.push(e.row.data.id);
            array.workOrderID = this.props.workOrder.id;
            console.log(array);

            var validationResult = await this.PostAPI('Placement/CheckPlacementIsUsed', array);

            console.log(validationResult);

            if (validationResult.status == -1) {
                alert(validationResult.message);
                return;
            }
            else if (validationResult.status == 0) {
                alert("One or more Customers from these Placements are already on Work Order and cannot be added.")
            }
            else {
                for (var i = 0; i < validationResult.errorList.length; i++) {
                    var item = validationResult.errorList[i];

                    console.log(item);

                    if (item.startsWith('Age Error')) {
                        alert(item + " is not the same Transport Unit. Assigned Placement Transport Units must be the same as the Work Order.");
                        return;
                    }
                    else if (item.startsWith('Inactive Roster Error ')) {
                    }
                    else if (item.startsWith('Workorder Incomplete')) {
                        var confirmAnswer = await confirm(item + " is part of an active Work Order. Are you sure you want to assign?");

                        if (!confirmAnswer) {
                            return;
                        }
                    }
                    else {
                        alert(item + " has already been dispatched on another Work Order.");
                        return;

                    }
                }
            }

            var result = await this.PostAPI('Placement/ReactivatePlacementsOnWO', array);

            console.log(result);
            if (result.status == -1) {
                alert(result.message);
            }
            else {
                alert('Saved Successfully!');
                this.dataGrid.instance.refresh();
                this.inactivePlacementDataGrid.instance.refresh();
            }
        }
    }

    calculateCustomersTotal = (e) => {
        if (e.name === 'WorkOrderCustomerCount') {
            if (e.summaryProcess === 'start') {
                e.totalValue = 0;
            }
            else if (e.summaryProcess === 'calculate') {
                console.log(e.value);
                e.totalValue += e.value.customerList.length;
            }
        }
    }

    customizeItem = (item) => {

        if (item.dataField === 'cancellationNotes') {
            //console.log('Customize Item', item);
            //console.log(this.state.placementToInactivate);

            item.visible = this.state.placementToInactivate.selectedPlacementStatusId == 3;
            item.isRequired = this.state.placementToInactivate.selectedPlacementStatusId == 3;
        }
    }

    onFieldDataChanged = async (e) => {
        // console.log('Field data changed...', e);

        e.component.repaint();
    }

    hideTextBox = async (e) => {
        this.state.tripNotes = this.props.workOrder.tripNotes;

        this.setState({
            isViewingTextBox: false
        });
    }

    showTextBox = async (e) => {
        await this.setState({
            loading: true
        });
        await this.setState({
            isViewingTextBox: true,
            loading: false
        });
    }

    onCreateCheatSheet = async (e) => {
        if ((e.itemData) == downloads[0]) {
            this.CreateCheatSheetPDF();
        }
        else {
            this.CreateCheatSheetDOC();
        }
    }

    saveWorkOrder = async (e) => {
        console.log('Saving...');
        await this.setState({
            loading: true
        });

        try {
            var workOrder = JSON.parse(JSON.stringify(this.props.workOrder)); // Creating a deep copy in the case that result.status != 1, trip notes are not updated on the original workOrder from props.
            workOrder.tripNotes = this.state.tripNotes;

            if (workOrder.tripNotes.length > 3000) {
                throw new Error("Trip notes can be no longer than 3000 characters")
            } else if (workOrder.tripNotes.replaceAll(' ', '') === "" || workOrder.tripNotes.replaceAll(' ', '') === "<p></p>") {
                workOrder.tripNotes = null;
            }

            //console.log("Value of trip notes to be saved: ", workOrder.tripNotes);

            const result = await this.PostAPI('WorkOrder/UpdateTripNotes', workOrder);

            if (result.status != 1) {
                alert(result.message);
            } else {
                this.props.workOrder.tripNotes = this.state.tripNotes;
            }
        }
        catch (error) {
            alert(error);
        }
        finally {
            this.hideTextBox();

            await this.setState({
                loading: false
            });
        }
    }

    render() {
        console.log("Beginning render");
        console.log('Date', new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), new Date().getHours(), new Date().getMinutes()));
        return (
            <div style={{ padding: "10px" }}>
                <div className="row" style={{ height: '10vh' }}>
                    <div className="col" style={{ whiteSpace: 'nowrap'}} >
                        <div style={{ marginBottom: 10 }} className="row">
                            <div classname="col">
                                <Button onClick={this.CreateToCSheet} text="Create Transfer of Custody Form" />
                                &nbsp;
                                <Button onClick={this.CreateWristbandSheet} text="Generate Wristbands" />
                                &nbsp;
                                <DropDownButton
                                    text="Generate Cheet Sheet"
                                    icon="download"
                                    style={{ padding: 1 }}
                                    dropDownOptions={{ width: 150 }}
                                    items={downloads}
                                    onItemClick={this.onCreateCheatSheet}
                                />
                                &nbsp;
                                <Button
                                    onClick={this.showTextBox}
                                    text={(this.componentIsReadOnly() ? 'View Trip Notes' : 'Edit Trip Notes')}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div classname="col">
                                {(this.componentIsReadOnly())
                                    ? <Button disabled={true} icon="search" text="Search Placements" />
                                    : <Link to={`/assign-placements/${this.props.workOrder.id}`}><Button icon="search" text="Search Placements" /></Link>
                                }
                                &nbsp;
                                {(this.componentIsReadOnly())
                                    ? <Button disabled={true} icon="plus" text="Add New Placement" />
                                    : <Link to={`/add-placement/${this.props.workOrder.id}`}><Button icon="plus" text="Add New Placement" /></Link>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="col-1"><p style={{textAlign: 'right'}}>Trip Notes:</p></div>
                    <HtmlEditor style={{ marginRight: 10 }} className="col" value={this.props.workOrder.tripNotes} width={'100%'} height={'100%'} readOnly={true}/>

                    {this.props.workOrder.transportUnit == "Family" &&
                        <div className="ms-auto me-3 col-2">
                            <span>
                                <label style={{ float: "right" }}><b>No of Family Units: </b><span style={{ color: "blue" }}>{this.props.workOrder.familyUnitCount}</span></label>&nbsp;
                                <textarea style={{ float: "right" }} id="familyUnitgroup" readOnly={true} value={this.props.workOrder.familyGroup} />
                            </span>
                        </div>
                    }
                </div>

                <Popup
                    visible={this.state.isViewingTextBox}
                    onHiding={this.hideTextBox}
                    dragEnabled={true}
                    closeOnOutsideClick={false}
                    showCloseButton={true}
                    showTitle={true}
                    title="Trip Notes"
                    width={"80%"}
                    height={"70%"}>
                    <Form readOnly={this.componentIsReadOnly()} formData={this.state}>
                        <FormItem dataField="tripNotes" editorType="dxHtmlEditor" editorOptions={htmlEditorOptions}>
                            <Label visible={false} />
                        </FormItem>
                    </Form>
                    <br />
                    <div className="d-flex justify-content-end">
                        <Button disabled={this.componentIsReadOnly()} text="Save" onClick={this.saveWorkOrder} />
                        &nbsp;
                        <Button text="Cancel" onClick={this.hideTextBox} />
                    </div>
                </Popup>

                <br/>
                <h6>Active Roster</h6>
                <DataGrid dataSource={this.customDataSource} showBorders={true} allowColumnResizing={true}
                    ref={ref => this.dataGrid = ref} onExporting={this.onExporting}>
                    <Export enabled={true} fileName={"activeRoster" + this.props.workOrder.workOrderName} />
                    <Editing mode="row" allowDeleting={!this.componentIsReadOnly()} />
                    <FilterRow visible={true} />

                    <Column caption="Name" dataField="placementShortName" cellRender={function (options) { return (<Link to={`/edit-placement/${options.row.data.id}/${options.row.data.workOrderID}`}>{options.value}</Link>); }} />
                    <Column caption="Placement Date" dataField="orrPlacementDate" dataType="datetime" format="MM/dd/yy, HH:mm '(Local)'" />
                    <Column caption="Movement Type" dataField="movementType" />
                    <Column caption="DHS Custody Date" dataField="dhsCustodyDate" dataType="datetime" format="MM/dd/yy, HH:mm" />
                    <Column caption="Subclassification Code" dataField="subclassificationName" />
                    <Column caption="Pickup Location" dataField="pickupLocationName" />
                    <Column caption="Req. Agency" dataField="requestingAgency" />
                    <Column caption="Destination" dataField="destinationName" />
                    <Column visible={!this.componentIsReadOnly()} type="buttons">
                        <GridButton
                            text="Make Inactive"
                            onClick={this.makePlacementInactive}
                        />
                        <GridButton name="delete" />
                    </Column>

                    <Summary calculateCustomSummary={this.calculateCustomersTotal}>
                        <TotalItem
                            cssClass="summaryRow"
                            showInColumn="placementShortName"
                            summaryType="custom"
                            name="WorkOrderCustomerCount"
                            alignment="left"
                            displayFormat="Total Customers: {0}" />
                    </Summary>

                    <MasterDetail enabled={true} autoExpandAll={true} component={PlacementDetail} />
                </DataGrid>

                <br />
                <Accordion collapsible={true} ref={ref => this.inactiveRosterAccordion = ref} deferRendering={false}>
                    <AccordionItem title="Inactive Roster">
                        <DataGrid dataSource={this.inactivePlacementDataSource} showBorders={true} allowColumnResizing={true}
                            ref={ref => this.inactivePlacementDataGrid = ref}>
                            <Export enabled={true} fileName={"inactiveRoster" + this.props.workOrder.workOrderName} />
                            <Editing mode="row" allowDeleting={!this.componentIsReadOnly()} />
                            <FilterRow visible={true} />

                            <Column caption="Name" dataField="placementShortName" cellRender={function (options) { return (<Link to={`/edit-placement/${options.row.data.id}`}>{options.value}</Link>); }} />
                            <Column caption="Placement Date" dataField="orrPlacementDate" dataType="datetime" format="MM/dd/yy, HH:mm '(Local)'" />
                            <Column caption="Movement Type" dataField="movementType" />
                            <Column caption="DHS Custody Date" dataField="dhsCustodyDate" dataType="datetime" format="MM/dd/yy, HH:mm" />
                            <Column caption="Subclassification Code" dataField="subclassificationName" />
                            <Column caption="Pickup Location" dataField="pickupLocationName" />
                            <Column caption="Req. Agency" dataField="requestingAgency" />
                            <Column caption="Destination" dataField="destinationName" />
                            <Column caption="Inactive Reason" dataField="inactiveReason" />
                            <Column caption="Comments" dataField="inactiveComments" />
                            <Column visible={!this.componentIsReadOnly()} type="buttons">
                                <GridButton 
                                    text="Re-activate"
                                    onClick={this.ReactivatePlacement}
                                />
                                <GridButton name="delete" />
                            </Column>

                            <Summary calculateCustomSummary={this.calculateCustomersTotal} >
                                <TotalItem
                                    cssClass="summaryRow"
                                    showInColumn="placementShortName"
                                    summaryType="custom"
                                    name="WorkOrderCustomerCount"
                                    displayFormat="Total Customers: {0}" />
                            </Summary>

                            <MasterDetail enabled={true} autoExpandAll={false} component={PlacementDetail} />
                        </DataGrid>
                    </AccordionItem>
                </Accordion>

                <Popup visible={this.state.isMovingPlacement} onHiding={this.hideMoveDialog} dragEnabled={true}
                    closeOnOutsideClick={false} showTitle={false} width={475} height={350}>

                    <p>Are you sure you want to move this Placement to the inactive roster?</p>
                    <Form ref={ref => this.editFormControl = ref} onFieldDataChanged={this.onFieldDataChanged}
                        id="form" formData={this.state.placementToInactivate} colCount={1} customizeItem={this.customizeItem}>
                        <FormItem editorType="dxSelectBox" dataField="reasonCode" editorOptions={{
                            searchEnabled: true,
                            dataSource: this.state.inactiveReasons,
                            displayExpr: 'name',
                            valueExpr: 'code'
                        }}>
                            <RequiredRule />
                        </FormItem>
                        <FormItem editorType="dxTextArea" dataField="comments" editorOptions={{
                            maxLength: 1000
                        }}>
                            <RequiredRule />
                            <Label text="Reason" />
                        </FormItem>

                        <FormItem editorType="dxSelectBox" dataField="selectedPlacementStatusId" editorOptions={{
                            searchEnabled: true,
                            dataSource: this.state.placementToInactivate.filteredPlacementStatuses,
                            displayExpr: 'name',
                            valueExpr: 'id',
                            readOnly: this.state.placementToInactivate.isPlacementStatusReadOnly
                        }}>
                            <RequiredRule />
                            <Label text="New Placement Status" />
                        </FormItem>

                        <FormItem editorType="dxTextArea" dataField="cancellationNotes" editorOptions={{
                            maxLength: 1000
                        }}>
                            <Label text="Cancelation Notes" />
                        </FormItem>
                    </Form>
                    <br />

                    <div style={{ textAlign: 'center' }}>
                        <Button text="Yes" onClick={this.saveInactivePlacement} />
                        &nbsp;&nbsp;
                        <Button text="No" onClick={this.hideMoveDialog} />
                    </div>
                </Popup>
            </div>
        );
    }
}

class PlacementDetail extends BaseComponent {
    constructor(props) {
        //console.log('Detail Props', props);
        super(props);

        this.dataSource = new DataSource({
            key: 'id',
            load: (loadOptions) => {
                return props.data.row.data.customerList;
            }
        })

        this.transportUnitCode = props.data.row.data.transportUnitCode;
        this.placementId = props.data.row.data.id;
        this.workOrderId = props.data.row.data.workOrderID;
        this.workOrderStatusId = props.data.row.data.workOrderStatusId;
        this.placementShortName = props.data.row.data.placementShortName;
        this.active = !props.data.row.data.inactiveReason;
        if (this.transportUnitCode == 'UC' && props.data.row.data.customerList.length > 1 && this.active)
            this.selectionMode = 'multiple';
        else
            this.selectionMode = 'none';

    }

    splitPlacements = async (e) => {

        var placementSplitObject = {
            placementId: this.placementId,
            customerIds: {}
        };

        var itemsSelected = await this.dataGrid.instance.getSelectedRowsData().then(async (rowData) => {
            if (rowData.length == 0) {
                await alert("Please select one or more UNCs.");
                return false;
            }
            else if (rowData.length == this.dataSource.totalCount()) {
                await alert("All UNCs cannot be split off the placement");
                return false;
            }
            else {
                placementSplitObject.customerIds = rowData.map(i => i.id);
                return true;
            }

        })

        if (itemsSelected) {

            var result = await confirm('Are you sure you want to split the selected UNC(s) into a separate Placement?');

            if (result) {

                const result = await this.PostAPI('Placement/SplitPlacement', placementSplitObject);

                if (result.status == 1) {
                    alert('Split Placement Successful. Reload the page to view the updated data.');
                }
                else {
                    //console.log(result);
                    alert('Failed. Please try again later.');
                }
            }
        }
    }

    componentIsReadOnly = () => {
        return this.IsReadOnly() || this.workOrderStatusId > 1;
    }

    render() {
        return (
            <div>
                <DataGrid dataSource={this.dataSource} showBorders={true} allowColumnResizing={true} ref={ref => this.dataGrid = ref} onExporting={this.onExporting}>
                    <Export enabled={true} fileName={"placementDetail" + this.placementShortName} />
                    <Selection mode={this.selectionMode} selectAllMode="allPages" showCheckBoxesMode="always" deferred={true} />
                    <Column caption="A#/Subject ID" dataField="comboIDDisplay" />
                    <Column caption="First Name" dataField="firstName" />
                    <Column caption="Middle Name" dataField="middleName" />
                    <Column caption="Last Name" dataField="lastName" />
                    <Column caption="Age" dataField="age" />
                    <Column caption="Gender" dataField="gender" />
                    <Column caption="Nationality" dataField="nationality" />
                </DataGrid>
                <br />
                <div>
                    <Button text="Split Placements" type="normal" onClick={this.splitPlacements} visible={this.selectionMode == 'multiple' && !this.componentIsReadOnly()} />
                </div>
            </div>

        );
    }
}
