import React from 'react';
import { Link } from 'react-router-dom';
import BaseComponent, { withParams } from '../BaseComponent.js'
import DataGrid, { Column, FilterRow, Pager, Paging, Editing, Export } from 'devextreme-react/data-grid';
import Button from 'devextreme-react/button';
import SelectBox from 'devextreme-react/select-box';
import { LoadPanel } from 'devextreme-react/load-panel';
import { alert, confirm } from 'devextreme/ui/dialog';

const dropDownOptions = {
    //height: 50
    width: 700
};

class PlacementMatchmakingContainer extends BaseComponent {
    constructor(props) {
        super(props);

        this.myRef = React.createRef();

        this.matchesRender = this.matchesRender.bind(this);

        this.state = {
            loading: false,
            matchedPlacements: []

            //filter: {
            //    notDispatched: true,
            //    dispatched: false,
            //    addedOnSupport: false,
            //    cancelled: false,
            //    text: ""
            //}
        };
    }

    GetComponentPageName = () => {
        return ("Placement Matchmaking");
    }

    //placementList = new CustomStore({
    //    key: 'id',
    //    onLoading: (loadOptions) => {
    //        console.log('Loading: ' + loadOptions);

    //        loadOptions.userData["notDispatched"] = this.state.filter.notDispatched;
    //        loadOptions.userData["dispatched"] = this.state.filter.dispatched;
    //        loadOptions.userData["addedOnSupport"] = this.state.filter.addedOnSupport;
    //        loadOptions.userData["cancelled"] = this.state.filter.cancelled;
    //        loadOptions.userData["text"] = this.state.filter.text;
    //    },
    //    load: (loadOptions) => {
    //        console.log('Load: ' + loadOptions);

    //        var queryString = ' ';
    //        var queryOptions = Object.keys(loadOptions.userData);

    //        console.log(queryOptions);

    //        for (var i = 0; i < queryOptions.length; i++) {

    //            if (queryString) {
    //                queryString += '&';
    //            }

    //            queryString += queryOptions[i] + '=' + loadOptions.userData[queryOptions[i]];
    //        }
    //        console.log(queryString);

    //        return FetchAPIPromise('Placement', queryString);
    //    }
    //});

    //handleChange = (event) => {
    //    this.setState(
    //        (state) => ({
    //            filter: {
    //                ...state.filter,
    //                [event.element.id]: !state.filter[event.element.id]
    //            }
    //        })
    //    );
    //};

    //GetDropDownData = async () => {
    //    const genderData = await this.FetchAPI('DictionaryGender');

    //    this.setState({
    //        genders: genderData
    //    });
    //}

    //search = () => {
    //    this.dataGrid.instance.refresh();
    //}

    //handleTextChange = async (event) => {
    //    await this.setState(
    //        (prevState) => ({
    //            filter: {
    //                ...prevState.filter,
    //                text: event.value
    //            }
    //        })
    //    );
    //}

    matchmakePlacements = async (e) => {
        this.setState({
            loading: true
        });

        try {
            const matchmakingData = await this.FetchAPI('Placement/MatchmakePlacements');

            this.setState({
                matchedPlacements: matchmakingData
            });

            this.dataGrid.instance.refresh();
        }
        finally {
            this.setState({
                loading: false
            });
        }
    }

    onSelectBoxValueChanged(options) {
        console.log(options);
        console.log(options.value);

        //data.keyFieldName is a key field specified using dxDataGrid.keyExpr or YourDataStore.key option  
        //options.component.getDataSource().store().update(options.data.keyFieldName, { productTitle: e.value });
        options.component.getDataSource().store().update(options.data.placementId, { selectedAirportId: options.value });
        options.component.refresh();
    }

    confirmDestinationMatches = async (e) => {
        if (!this.state.matchedPlacements || this.state.matchedPlacements.length == 0) {
            alert('No Placements found to confirm.');
        }
        else {
            var dialogResult = await confirm('Are you sure you want to accept these Placement destination changes?');

            if (dialogResult) {
                this.setState({
                    loading: true
                });

                try {
                    const result = await this.PostAPI('Placement/UpdatePlacementMatches', this.state.matchedPlacements);

                    if (result.status == 1) {
                        this.props.navigate('/search-placements');
                        this.props.navigate(0);
                    }
                    else {
                        alert(result.message);
                    }
                }
                finally {
                    this.setState({
                        loading: false
                    });
                }
            }
        }
    }

    onValueChanged(cell, e) {
        console.log(cell);
        console.log(e);
        cell.setValue(e.value);
    }

    matchesRender(cell) {
        let onValueChanged = this.onValueChanged.bind(this, cell);

        //console.log(cell);
        //let onSelectBoxValueChanged = this.onSelectBoxValueChanged.bind(this, cell);

        return (<SelectBox dropDownOptions={dropDownOptions} dataSource={cell.row.data.proximityMatches} displayExpr='display' valueExpr='airportId' onValueChanged={onValueChanged} defaultValue={cell.row.data.selectedAirportId} />);
    }

    calculateCellValue(rowData) {
        //console.log('Cell Data', rowData);

        if (rowData.selectedAirportId) {
            var match = rowData.proximityMatches.find(element => element.airportId === rowData.selectedAirportId);

            return match.display;
        }
        else {
            return null;
        }

        //var maxDaysInCare = null;

        //rowData.customerList.map((customer, customerIndex) => {

        //     //TODO: When cut off?
        //     //TODO: N/A when canceled or dispatched?
        //    var localDays = customer.daysInCare;

        //    if (maxDaysInCare == null || localDays < maxDaysInCare) {
        //        console.log('Setting age out to ', localDays);
        //        maxDaysInCare = localDays;
        //    }
        //});

        //console.log('Max Risk', maxAgeOutRisk);

        //if (maxDaysInCare == null || maxDaysInCare == -1) {
        //    return null;
        //}
        //else {
        //    return maxDaysInCare;
        //}
    }

    render() {
        return (
            <div className="container">
                <h1>Placement Matchmaking</h1>
                <div className="row">
                    <div className="ms-auto me-3 form-inline">
                        <Button disabled={this.IsReadOnly()} text="Matchmake Placements" onClick={this.matchmakePlacements} />
                    </div>
                </div>

                <br />

                <DataGrid dataSource={this.state.matchedPlacements}
                    keyExpr="placementId" ref={ref => this.dataGrid = ref} allowColumnResizing={true}
                    showBorders={true} onExporting={this.onExporting}>
                    <Export enabled={true} fileName={"placementMatchmaking"} />
                    <Editing mode='cell' allowUpdating={!this.IsReadOnly()} />
                    <Paging defaultPageSize={10} />
                    <Pager showPageSizeSelector={true}
                        allowedPageSizes={[5, 10, 20]}
                        showInfo={true} />
                    <FilterRow visible={true} />

                    <Column caption="Placement Name" dataField="placementName" width="150" allowEditing={false} />
                    <Column caption="A#s" dataField="customerANumbers" width="200" allowEditing={false} />
                    <Column caption="Sponsor Address" dataField="sponsorAddress" width="350" allowEditing={false} />
                    <Column caption="Assigned Destination" width="*" dataField="selectedAirportId" calculateCellValue={this.calculateCellValue}
                        editCellRender={this.matchesRender} />
                </DataGrid>
                <br />

                <div className="row">
                    <div className="ms-auto me-3 form-inline">
                        <Button text="Confirm Destination Matching" onClick={this.confirmDestinationMatches} />
                    </div>
                </div>

                <LoadPanel visible={this.state.loading} />
                <br />
                <br />
            </div>
        );

        function renderCustomItem(data) {
            return <div>{data}</div>;
        }
    }
}

export default withParams(PlacementMatchmakingContainer);